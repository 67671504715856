import Layout from "../../Components/Layout/Layout";
import InputField from "../../Components/InputField/InputField";
import BackBtn from "../../Components/BackBtn/BackBtn";
import {
  FailedPopup,
  downloadBase64PDF,
  formatDate,
  getMonthsRange,
} from "../../common/Utils/Utils";
import { useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { useLocation } from "react-router-dom";
import { ManageApis } from "../../common/APIS/ManageApis";
import NoData from "../../Components/NoData/NoData";

const dates = getMonthsRange();
function GoldODPassBook() {
  const { state } = useLocation();
  const { account_no, customerName, AvailedAmt, AmtAfterWithdrwal } = state || {};
  const { token } = useContext(ContextAPI);
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const AccDet = [
    { label: "Customer Name :", value: customerName },
    { label: "Gold OD A/C No :", value: account_no },
    { label: "Availed OD amount:", value: AvailedAmt },
    { label: "Amount available for Withdrawal :", value: AmtAfterWithdrwal },
  ]
  // handle startDate

  const handledateChange = (value) => {
    if (value?.length) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  };

  // handle view passbook when stat and end date changes

  useEffect(() => {
    if (startDate && endDate) {
      handleViewPassbook();
    }
  }, [startDate, endDate]);

  // handle view passbook axios call
  const handleViewPassbook = async () => {
    setisLoading(true);
    const reqstBody = {
      account_no: account_no,
      from_date: formatDate(startDate, "DD-MM-YYYY", "YYYY-MM-DD"),
      to_date: formatDate(endDate, "DD-MM-YYYY", "YYYY-MM-DD"),
    };
    let response = await ManageApis(
      "post",
      global.goldODPassBook,
      reqstBody,
      token
    );
    if (response.message == "Success") {
      //console.log("res", response)
      setisLoading(false);
      const { status, data } = response.response_data;
      if (status) {
        setdata(data);
        //console.log(docs);
      }
    } else {
      setisLoading(false);
      //console.log("res", response)
      FailedPopup(response);
    }
  };

  const handleExportPassbookToPdf = async () => {
    //debugger
    const reqstBody = {
      account_no: account_no,
      from_date: formatDate(startDate, "DD-MM-YYYY", "YYYY-MM-DD"),
      to_date: formatDate(endDate, "DD-MM-YYYY", "YYYY-MM-DD"),
    };
    setisLoading(true);
    let response = await ManageApis(
      "post",
      global.goldODPassBookPDf,
      reqstBody,
      token
    );
    if (response.message == "Success") {
      setisLoading(false);
      let fileName = `goldod-passbook`;
      let data_ = response.response_data.data;
      downloadBase64PDF(data_, fileName);
    } else {
      setisLoading(false);
      FailedPopup(response);
    }
  };

  return (
    <Layout loading={isLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <BackBtn title={"View Gold OD Passbook"} />

              <div className="col-12 col-sm-7 col-lg-4 col-xl-auto">
                <div className="d-flex gap-2 p-2 bg-white rounded mt-2">
                  <InputField
                    marginBottom
                    label={"Choose Date"}
                    onchange={handledateChange}
                    type={"date-range"}
                    value={[startDate, endDate]}
                    onClear={() => {
                      setStartDate("");
                      setEndDate("");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" d-flex justify-content-md-end mb-2">
            {data?.length ? (
              <button
                className="btn btn-master text-nowrap flex-fill flex-md-grow-0 font_12 px_btn py-1 rounded"
                onClick={handleExportPassbookToPdf}
              >
                Export as PDF
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="p-2 bg-white w-100 br_10 shadow_sm mb-2">
                <div className="align-items-center row">
                  {AccDet.map((item, index) => (
                    <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0" key={index}>
                      <span className="pe-1 fw_700">{item.label}</span>
                      <span className="fw_500">{item.value}</span>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm ">
            {data?.length ? (
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500" style={{ width: "60px" }}>
                        Sl.No
                      </th>
                      <th className="fw_500" style={{ width: "100px" }}>Date</th>
                      <th className="fw_500">Deposit Amount</th>
                      <th className="fw_500">Withdrawal Amount</th>
                      <th className="fw_500">Total Transfer Amount</th>
                      <th className="fw_500">Availed OD Amount</th>
                      <th className="fw_500">Mode Of Payment</th>
                      <th className="fw_500">Description</th>
                      {/* <th className="fw_500" style={{ width: "90px" }}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr>
                        <td>{1 + index}</td>
                        <td>{item.dateOfTransaction}</td>
                        <td>{item.depositAmount}</td>
                        <td>{item.withdrawalAmount}</td>
                        <td>{item.totalTransferAmount}</td>
                        <td>{item.balanceAfterTransaction}</td>
                        <td>{item.modeOfPayment}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoData title={"No data to display"} />
            )}
          </div>
          <div className="p-3 bg-white br_10 shadow_sm d-none">
            <div className="d-flex">
              <div className="m-auto">
                <div className="text-center d-grid py-5 text_gray7">
                  <i className="far fa-file-alt font_18"></i>
                  <p className="mb-0 mt-2">No Data Found.</p>
                  <p className="mb-0 mt-1 font_12">please From and To date</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default GoldODPassBook;
