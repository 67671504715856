import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import InputField from "../../Components/InputField/InputField";
import { ContextAPI } from "../../common/Context/ContextApi";
import { ManageApis } from "../../common/APIS/ManageApis";
import { downloadBase64PDF, FailedPopup, getMonthsRange, sortByDateDescending } from "../../common/Utils/Utils";
import dayjs from 'dayjs'
import moment from "moment";
import { CustomTable } from "../../Components/CustomTable";

function ViewSugamaPassbook() {
  const dates = getMonthsRange()
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { accountId } = state || {};
  const { token } = useContext(ContextAPI)
  const [dateRange, setDatesRange] = useState({
    startDate: moment(dates.startDate).format("YYYY-MM-DD"), 
    endDate: moment(dates.endDate).format("YYYY-MM-DD"),
  });
  const [isLoading, setisLoading] = useState(false);
  const [transaction, setTransactions] = useState()
  const [Accdetails, setAccdetails] = useState()
  const [Errors, setErrors] = useState({})

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      GetPaymentDetails()
    }
  }, [dateRange])


  const handleBackBtn = () => {
    Navigate("/mySugamaAccountDetails", { state: { accountId: accountId } })
  };


  //Function to get payment Details
  const GetPaymentDetails = async (current) => {
    try {
      if(!accountId)return;
      setisLoading(true)
      const reqstBody = {
        account_no: accountId,
        from_date: moment(dateRange.startDate).format('YYYY-MM-DD'),
        to_date: moment(dateRange.endDate).format('YYYY-MM-DD')

      }
      let response = await ManageApis(
        "post",
        global.getSugamaPassbook,
        reqstBody,
        token
      );
      if (response?.response_data?.status) {

        const { sugama, transactions } = response?.response_data?.data;
        setAccdetails(sugama)
        let sortedData = sortByDateDescending(transactions,'dateofTransaction');
        setTransactions(sortedData);
      } else{
        FailedPopup(response);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setisLoading(false)
    }
  }

    const handleExportPassbookToPdf = async () => {
      const reqstBody = {
        account_no: accountId,
        from_date: moment(dateRange.startDate).format('YYYY-MM-DD'),
        to_date: moment(dateRange.endDate).format('YYYY-MM-DD')
      };
      setisLoading(true);
      let response = await ManageApis(
        "post",
        global.exportSugamaPassbook,
        reqstBody,
        token
      );
      if (response.message == "Success") {
        setisLoading(false);
        let fileName = `sugama-passbook`;
        let data_ = response.response_data.data;
        downloadBase64PDF(data_, fileName);
      } else {
        setisLoading(false);
        FailedPopup(response);
      }
    };

  const handleDateChange = (value) => {
    if (value?.length) {
      setDatesRange((prevState) => ({
        ...prevState,
        startDate: moment(value[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
        endDate: moment(value[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
      }));
    }
  };

  const handleClear = () => {
    setDatesRange((prevState) => ({ ...prevState, startDate: '', endDate: '' }))
  }

  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const TableColumn = [
    { label: "Sl.No", getValue: (item, index) => index + 1 },
    { label: "Date of Transaction", getValue: (item) => item.dateofTransaction || "-" },
    { 
      label: "Deposit Amount", 
      getValue: (item) => 
        ["SUGAMA_PAYMENT", "SUGAMA_STANDINGINSTRUCTION","SUGAMA_CLOSURE_PAYMENT"].includes(item.cusAccountOperationType) 
          ? ""
          : item.actualTransferAmount || "-", 
      style: (item) => ({ color: item.actualTransferAmount > 0 ? "green" : "inherit",textAlign: "right" }),
      headstyle:{textAlign: "right"}
    },
    { 
      label: "Withdrawal Amount", 
      getValue: (item) => 
        ["SUGAMA_INTEREST_TRANSFER", "SUGAMA_COLLECTION","SUGAMA_INTEREST_TRANSFER_CLOSURE"].includes(item.cusAccountOperationType) 
          ? ""
          : item.actualTransferAmount || "-", 
      style: (item) => ({ color: item.actualTransferAmount > 0 ? "red" : "inherit" ,textAlign: "right"}), // Red for withdrawals
      headstyle:{textAlign: "right"}
    },
    { label: "Balance After Transaction", getValue: (item) => item.balanceAfterTransaction || "-" ,style: { textAlign: "right" },headstyle:{textAlign: "right"}},
    { label: "Description", getValue: (item) => item.description || "-" }
  ];


  const ChitDetailArray = [
    { title: "Customer Name", value: Accdetails?.name },
    { title: "Account No", value: Accdetails?.account_no },
    { title: "Address", value: Accdetails?.address },
    { title: "Branch", value: Accdetails?.branch_name },
  ].filter(item => item.value !== "N/A");


  return (
    <Layout loading={isLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <BackBtn
              title={"View Sugama Passbook"}
              handleBackBtn={handleBackBtn}
            />
            <div className="bg-white p-3 br_10">
              <InputField
                marginBottom
                label={"Choose Date"}
                type={"date-range"}
                onchange={handleDateChange}
                onClear={handleClear}
                onselect={() => handleError(null, "from_date")}
                value={[dateRange.startDate, dateRange.endDate]}
                disabledDate={(current) => current.isAfter(dayjs())}
                errorMessage={Errors.from_date}
                seterrorMessage={handleError}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className="btn btn-master flex-fill  flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded" onClick={handleExportPassbookToPdf}>
              Export as PDF
            </button>
          </div>
          <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3 mt-3">
            <div className="row">
              {ChitDetailArray.map((item, index) => (
                <div
                  key={index}
                  className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2"
                >
                  <div className="row">
                    <div className="col-12 col-sm-6 font_13">{item.title}</div>
                    <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                      <div className="d-flex">
                        <span className="pe-1 d-none d-sm-block">:</span>
                        <span className="text-black word_break fw_600 font_13">
                          {item.value}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
       <CustomTable tableData={TableColumn} data={transaction}/>
        </div>
      </div>
    </Layout>
  );
}

export default ViewSugamaPassbook;
