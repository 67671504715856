
import { useCallback, useEffect, useMemo, useState } from "react"
import Logo from "../../assets/images/Logo.png"
import InputField from "../../Components/InputField/InputField"
import { ConfirmSuccessPopup, FailedPopup, FormValidation, JSONParse, JSONStringify, customLogger, formatDate, getSessionStorage, removeSessionStorage, resetNavigation, setSessionStorage, successPopup } from "../../common/Utils/Utils"
import { Link, useNavigate } from "react-router-dom"
import { ManageApis } from "../../common/APIS/ManageApis"
import VerifyOTP from "../VerifyOTPScreen/VerifyOTP"
import LoginRegLayout from "../UserLoginReg/LoginReg"
import PopupModal from "../../Components/PopupModal"
import Captcha from "../../Components/CaptchaScreen/Captcha"
import { defaultTexts } from "../../constant"


function Register() {
	const navigate = useNavigate()
	const [form, setform] = useState({
		first_name: "",
		last_name: "",
		username: "",
		password: "",
		confirmPassword: "",
		email: "",
		mobile: "",
		dob: "",
		guardian_name: "",
		gender: "",
	})
	const [Errors, setErrors] = useState(Object.keys(form).map(item => ({ name: item, message: '' })))
	const [checkingAvailability, setCheckingAvailability] = useState(false)
	const [isUsernameAvailable, setisUsernameAvailable] = useState('')
	const [IsLoading, setIsLoading] = useState(false)
	const [OTPScreen, setOTPScreen] = useState(false)
	const [verificationData, setverificationData] = useState()
	const [showCaptcha,setShowCaptcha]=useState(false);
	const [Otp, setOtp] = useState('')
	let invalid_otp_count=0
	useEffect(() => {
		let regForm = JSONParse(getSessionStorage('registerform'))
		if (regForm) {
			setform(regForm)
		}
	}, [])

	// gender options
	const dropdownList = useMemo(() => [
		{ value: 1, key: "Male" },
		{ value: 2, key: "Female" },
		{ value: 3, key: "Other" },
	], [])
	// onchange

	useEffect(() => {

	
		// Function to set errors
		const message = 'Password and Confirm Password do not match';
		const handleError = (name, message = '') => {
		  setErrors(Errors.map(item => item?.name === name ? { name, message } : item));
		};
	  
		// Check if both fields are non-empty and match
		if (form.password && form.confirmPassword && form.password !== form.confirmPassword) {
		  handleError('confirmPassword', message); // Show the error message
		} else if (form.password && form.confirmPassword && form.password === form.confirmPassword) {
			handleError('confirmPassword'); // Clear the error
		} 
			
	  }, [form]);
	  


	const handleChange = useCallback((value, name) => {
		// Log the value and name
		customLogger({ value, name });
		// Update the form state
		setform(prev => ({ ...prev, [name]: value }));
		

	}, [form, Errors]);


	useEffect(() => {
		//console.log({ form })
	}, [form])

	// looping the form fields
	const formField = useMemo(() => [
		{ type: 'text', label: 'First Name', placeholder: '', required: true, onchange: handleChange, name: 'first_name', disabled: false, },
		{ type: 'text', label: 'Last Name', placeholder: '', required: true, onchange: handleChange, name: 'last_name', disabled: false, },
		{ type: 'username', label: 'Username', placeholder: '', required: true, onchange: handleChange, name: 'username', disabled: false, helperText:defaultTexts?.usernameNote},
		{ type: 'password', label: 'Password', placeholder: '', required: true, onchange: handleChange, name: 'password', disabled: false, helperText:defaultTexts?.passwordNote },
		{ type: 'password', label: 'Confirm Password', placeholder: '', required: true, onchange: handleChange, name: 'confirmPassword', disabled: false, },
		{ type: 'email', label: 'Email (optional)', placeholder: '', required: false, onchange: handleChange, name: 'email', disabled: false, },
		{ type: 'mobile', label: 'Mobile', placeholder: '', required: true, onchange: handleChange, name: 'mobile', disabled: false, },
		{ type: 'date', label: 'Date of birth', placeholder: '', required: true, onchange: handleChange, name: 'dob', disabled: false, },
		{ type: 'text', label: 'Mother’s/Father’s Name', placeholder: '', required: true, onchange: handleChange, name: 'guardian_name', disabled: false, },
		{ type: 'select', label: 'Gender', placeholder: 'Select Gender', required: true, onchange: handleChange, name: 'gender', disabled: false, options: dropdownList },
	], [])

	// const [formField, setformField] = useState(formFields)

	const handleRegister = async () => {
		let valid = validate()
		customLogger(valid)
		if (!valid) {
			const rqstBody = {
				first_name: form.first_name,
				last_name: form.last_name,
				username: form.username,
				dob: formatDate(form.dob,'DD-MM-YYYY', "YYYY-MM-DD"),
				email: form.email,
				mobile: form.mobile,
				password: form.password,
				lead_ref_id: verificationData.lead_ref_id,
				gender: form.gender,
				fathers_or_mothers_name: form.guardian_name
			}
			customLogger({ rqstBody })
			// axios call for verify mobile
			await ManageApis("post", global.userRegister, rqstBody)
				.then((response) => {
					//debugger
					// if axios call success for verify mobile
					if (response.response_data.status) {
						// customLogger("", response);
						// setVissible(true)
						//successPopup(response.response_data.message)
						ConfirmSuccessPopup(response.response_data.message, ()=> {navigate('/login')})
						removeSessionStorage('registerform')
						// signout()
						// setisOpenOTPModal(false)
						// setVissible(true)
						//navigate('/login')
						
						// navigation.navigate("Login", { redirectedFrom: true })
					}
					else {
						FailedPopup(response)
					}
				}).catch((err) => {
					customLogger(err)
					FailedPopup(err)
				})
		} else {
			FailedPopup("Fill required fields")
		}


	}

	// handle mobile verify
	const handleVerify = async () => {
		// call validate fn and is true call api
		let isValid = validate();
		//debugger
		//console.log(formatDate(form.dob,'DD-MM-YYYY', "YYYY-MM-DD"));
		if (!isValid) {
			await checkUsernameAvailability()
				.then((res => {
					if (res) {
						setSessionStorage('registerform', JSONStringify(form))
						setShowCaptcha(true)
					} else {
						return
					}
				}))
				.catch(err => customLogger('error in checking username', err))
		}
		else {
			// setbuttonDisable(true)
			// Alert.alert("Failed!", "Fill all mandatory fields");
		}

	};

	const validate = () => {
		if (form.password.toLowerCase().includes(form.username.toLowerCase())) {
			setErrors(Errors.map(item => item.name === 'password' ? ({ ...item, message: 'password cannot contain the usernamea' }) : item))
			return true
		}
		if (form.password !== form.confirmPassword) {
			setErrors(Errors.map(item => item.name === 'confirmPassword' ? ({ ...item, message: 'Password and Confirm Password does not match.' }) : item))
			return true
		}
		
		else{
		setErrors(Errors.map(item => item.name === 'confirmPassword' ? ({ ...item, message: '' }) : item))
	}
		return Errors.filter(itm => itm.name !== 'confirmPassword').some(item => item?.message)
	}

	const checkUsernameAvailability = async () => {
		return new Promise((resolve, reject) => {

			setCheckingAvailability(true)
			let path = global.checkUserNameAvailablility + form.username
			customLogger('api calling', path)
			ManageApis('get', path)
				.then((res) => {
					customLogger(res)
					setCheckingAvailability(false)
					if (!res.response_data.status) {
						setErrors(Errors.map(item => item.name === 'username' ? { name: 'username', message: res.response_data.message } : item))
					} else {
						setisUsernameAvailable(res.response_data.message)
					}
					resolve(res.response_data.status)
				})
				.catch((err) => {
					setCheckingAvailability(false)
					customLogger(err)
					reject(err)
				}
				)

		})
	}
	const handlesendOTP = async () => {
		setIsLoading(true);
		//  axios call
		let path = global.sendOtpverifyMobile
		let { first_name, last_name, mobile, email } = form
		let body = { first_name, last_name, mobile, email }
		let response = await ManageApis(
			"post",
			path,
			body
		);

		// if axios success
		if (response.message === "Success") {
			let { token_alphabet_part, masked_mobile_number,lead_ref_id } = response.response_data.data
			customLogger(response);
			setIsLoading(false);
			//   setshowCaptcha(false)

			if (token_alphabet_part) {
				//debugger
				setOTPScreen(true)
				setverificationData({ token_alphabet_part, masked_mobile_number,lead_ref_id })
				// resetNavigation('/verifyotp')
				// setverificationData(token_alphabet_part)
				// navigate to otp screen
				// handlesendOTP(form)
				// setisOpenOTPModal(true)
			}

			// navigation.navigate("RegistrationVerify", ({
			//   verificationData: response.response_data,
			//   regData: form
			// }));
		}
		// axios fail
		else {
			setIsLoading(false);
			customLogger(response.response_data);
			FailedPopup(response);
		}
	};

	// 	    // axios call for resend otp
	const handleResendOTP = async () => {
		// debugger
		setOtp('')
		// customLogger("", regData)
		const rqstBody = {
			first_name: form.first_name,
			last_name: form.last_name,
			email: form.email.toLowerCase(),
			mobile: form.mobile
		};
		let response = await ManageApis("post", global.reSendOtp, rqstBody);
		if (response.message == "Success") {
			customLogger(response, response?.response_data?.data?.token_alphabet_part)
			let token_alphabet_part = response.response_data.data
			if (token_alphabet_part) {
				customLogger(token_alphabet_part)
				setverificationData(prev => ({ ...prev, ...token_alphabet_part }))
			}
			successPopup(response.response_data.message);
		} else {
			FailedPopup(response.response_data.message)
			customLogger(response)
		}
	};


	// 	    // handle varify mobile
	const handleOtpVerification = async (Otp) => {
	
	    const rqstBody = {
	      otp: verificationData.token_alphabet_part + Otp,
	      mobile: form.mobile,
	      lead_ref_id: verificationData.lead_ref_id,
	    };
	    let path = global.verifyMobileNumber
	    // axios call for verify mobile
	    let response = await ManageApis("post", path, rqstBody);
	    customLogger({ response })
	    // if axios call success for verify mobile
	    if (response.response_data.status) {
	      customLogger(response);
	      handleRegister()

		} else {
			if (response.response_data.message=="OTP verification failed. Please check the OTP you entered") {
				invalid_otp_count+=1
			}
			FailedPopup(invalid_otp_count>4?"You have exceeded maximum otp verification attempts,please try again later.":response.response_data.message,invalid_otp_count>4?()=>window.location.reload():"");
	    }
	  };





	const handlesubmit = useCallback(() => {
		// feteching all required items
		let requiredItems = formField.filter(item => item.required).map(item => !!item.required && item.name)
		let validationResult = FormValidation(form, requiredItems)
		if (validationResult.validation) {
			// trigger check username api
			handleVerify()
		} else {
			if (validationResult.failedItems.length) {
				let newrequiredItems = formField.map(item => ({ name: item.name, message: validationResult.failedItems.includes(item.name) ? 'Field is required' :  "" }))
				let mergednewrequiredItems = newrequiredItems.map(item=>({
					...item,
					message: item.message || (Errors.find(errorItem =>errorItem.name=== item.name)?.message || '')
				}))
				setErrors(mergednewrequiredItems)
				// if (!Errors.length) {
				// } else {
				// 	setErrors(Errors.map(item => item.name === name ? { name: name, message } : item))

				// }


			}
		}
	}, [form])

	useEffect(() => {
		//console.log({ Errors })
	}, [Errors])

	const handleLogin=()=>{
	 setShowCaptcha(false)
     handlesendOTP();
	}

	const handleErrors = (name, message) => {
		//console.log(name, message)
		setErrors(Errors.map(item => item.name === name ? { name: name, message } : item))

	}
	if (OTPScreen) {
		return (
			<LoginRegLayout loading={IsLoading}>
				<VerifyOTP verificationData={verificationData} handleResendOTP={handleResendOTP}  handleBackBtn={() => setOTPScreen(false)} handleSubmit={handleOtpVerification} />
			</LoginRegLayout>
		)
	} else
		return (
			<LoginRegLayout loading={IsLoading}>
				<div className="d-flex regMob  p-3 p-md-4 overflow-auto scrollbar1">
					<div className="m-auto max_w_350x py-1 w-100">
						<div>
							<div className="text-center">
								<img src={Logo} className="mb-3 logo_prop" />
							</div>
							<h1 className="h3 text-center text_master fw_700">REGISTER</h1>
							{formField.map(item =>
								item.name == 'gender'? 
								<InputField {...item} value={form[item.name]} errorMessage={Errors?.find(itm => itm.name == item.name)?.message} seterrorMessage={handleErrors} onfocus={() => handleErrors("gender", null)} />
								:
								<InputField {...item} value={form[item.name]} errorMessage={Errors?.find(itm => itm.name == item.name)?.message} seterrorMessage={handleErrors} />
							
							)}
							<div className="mb-3">
								<button
									disabled={Errors.some(item => item.message)}
									type="submit"
									className="btn px_btn btn-master px-4 rounded w-100 fw_500"
									onClick={handlesubmit}
								>
									Verify Mobile Number
								</button>
							</div>
							<div className="text-center">
								<span className="px-2 text_master fw_500"> Already have account!</span>
								<Link to={'/login'} className="text_master2_d1 text-decoration-none fw_500 ws_nowrp cursor-pointer">Sign In</Link>
							</div>
						</div>
					</div>
				</div>
				{!!showCaptcha &&
					<PopupModal className='captcheModal' title="Captcha Verification" size="sm" visible={showCaptcha} handleClose={()=>setShowCaptcha(false)} >
						<div className="">
							<Captcha onSubmit={handleLogin} disabledButton={false} />
						</div>
					</PopupModal>
				}
				
			</LoginRegLayout>
		)
}

export default Register