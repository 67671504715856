import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginReg from "./Screens/UserLoginReg/LoginReg";
import ManageProxy from "./Screens/ManageProxyScreen/ManageProxy";
import MychitsHome from "./Screens/MychitsScreen/MychitsHome";
import MyRequest from "./Screens/MyRequestScreen/MyRequest";
import PaymentHistory from "./Screens/PaymentHistoryScreen/PaymentHistory";
import { ContextAPI } from "./common/Context/ContextApi";
import Logout from "./Screens/Logout/Logout";
import ProfileScreen from "./Screens/ProfileScreen/ProfileScreen";
import Register from "./Screens/Register/Register";
import ForgotPassword from "./Screens/ForgotPasswordScreen/ForgotPassword";
import ForgotUsername from "./Screens/ForgotUsernameScreen/ForgotUsername";
import ChangeForgotPassword from "./Screens/ChangeForgotPasswordScreen/ChangeForgotPassword";
import ChangeForgotUsername from "./Screens/ChangeForgotUsernameScreen/ChangeForgotUsername";
import ChitDetails from "./Screens/MychitsScreen/ChitDetailsScreen/ChitDetails";
import NewChits from "./Screens/NewChitScreen/NewChits";
import EMICalculator from "./Screens/EMICalculatorScreen/EMICalculator";
import Feedback from "./Screens/FeedBackScreen/Feedback";
import ChittalDetails from "./Screens/MychitsScreen/ChittalDetailsScreen/ChittalDetails";
import RequestChat from "./Screens/ChatScreen/RequestChat";
import ViewPassBook from "./Screens/ChitPassbookScreen/ViewPassBook";
import PaymentList from "./Screens/PaymentScreen/PaymentList";
import AuctionDetails from "./Screens/AuctionDetailScreen/AuctionDetails";
import QuickPay from "./Screens/QuickPay/QuickPay";
import MyAccount from "./Screens/MyAccountScreen/MyAccount";
import LoanList from "./Screens/MyLoans/LoanList/LoanList";
import LoanDetails from "./Screens/MyLoans/LoandDetails/LoanDetails";
import ViewLoanPassBook from "./Screens/LoanPassbookScreen/ViewLoanPassBook";
import { FailedPopup, clearSessionStorage, getSessionStorage } from "./common/Utils/Utils";
import { ManageApis } from "./common/APIS/ManageApis";
import { useIdleTimer } from "react-idle-timer";
import { Modal } from "@arco-design/web-react";
import { Button } from "react-bootstrap";
import PopupModal from "./Components/PopupModal";
import NetworkUnavailable from "./Screens/NetworkUnavailableScreen/NetworkUnavailable";
import GoldOdList from "./Screens/GoldOdScreen/GoldOdList/GoldOdList";
import GoldOdDetails from "./Screens/GoldOdScreen/GoldOdDetail/GoldOdDetails";
import GoldODPassBook from "./Screens/GoldODPassBookScreen/GoldODPassBook";
import TagManager from "react-gtm-module";
import GoldODWithdrawalHistory from "./Screens/GoldODWithdrawalHistoryScreen/GoldODWithdrawalHistory";
import MySugamaAccount from "./Screens/MySugamaAccount/MySugamaAccount";
import MySugamaAccountDetails from "./Screens/MySugamaAccountDetails/MySugamaAccountDetails";
import ViewStandingInstructions from "./Screens/ViewStandingInstructionsScreen/ViewStandingInstructions";
import ViewSugamaPassbook from "./Screens/ViewSugamaPassbook/ViewSugamaPassbook";
import SugamaPaymentList from "./Screens/SugamaPaymentListScreen/SugamaPaymentList";
import { handleTempPasswordRedirect } from "./Screens/ProfileScreen/handleTempPasswordRedirect";

const registerComponent = [
  { url: "/login", component: <Login /> },
  { url: "/register", component: <Register /> },
  { url: "/register/verifyotp", component: <Register /> },
  { url: "/forgotusername", component: <ForgotUsername /> },
  { url: "/forgotusername/verifyotp", component: <ForgotUsername /> },
  { url: "/forgotpassword", component: <ForgotPassword /> },
  { url: "/forgotpassword/verifyotp", component: <ForgotPassword /> },
  { url: "/changeforgotpassword", component: <ChangeForgotPassword /> },
  { url: "/changeforgotusername", component: <ChangeForgotUsername /> },
  { url: "/cart/:userdetails/:auth/:redirect_url", component: <PaymentList /> },
];
const dashboardComponent = ["/settings"].map((item) => ({
  url: item,
  component: <Home />,
}));
const otherLoggedinScreens = [
  { url: "/", component: <Home /> },
  { url: "/manageproxy", component: <ManageProxy /> },
  { url: "/mychits", component: <MychitsHome /> },
  { url: "/myloans", component: <LoanList /> },
  { url: "/loandetails", component: <LoanDetails /> },
  { url: "/chitdetails", component: <ChitDetails /> },
  { url: "/chittaldetails", component: <ChittalDetails /> },
  { url: "/passbook", component: <ViewPassBook /> },
  { url: "/loanpassbook", component: <ViewLoanPassBook /> },
  { url: "/newchits", component: <NewChits /> },
  { url: "/myrequest", component: <MyRequest /> },
  { url: "/emicalculator", component: <EMICalculator /> },
  { url: "/feedback-complaint", component: <Feedback /> },
  { url: "/paymenthistory", component: <PaymentHistory /> },
  { url: "/profile", component: <ProfileScreen /> },
  { url: "/manage-proxy", component: <ManageProxy /> },
  { url: "/logout", component: <Logout /> },
  { url: "/chat", component: <RequestChat /> },
  { url: "/cart", component: <PaymentList /> },
  { url: "/auctiondetails", component: <AuctionDetails /> },
  { url: "/quickpay", component: <QuickPay /> },
  { url: "/myaccount", component: <MyAccount /> },
  { url: "/mySugamaAccount", component: <MySugamaAccount /> },
  { url: "/mySugamaAccountDetails", component: <MySugamaAccountDetails /> },
  { url: "/viewStandingInstructions", component: <ViewStandingInstructions /> },
  { url: "/viewSugamaPassbook", component: <ViewSugamaPassbook /> },
  { url: "/sugamaPaymentList", component: <SugamaPaymentList /> },
];

const timeout = 1200000;
const promptBeforeIdle = 30000;
const invalidBrowserPopUpInitialValue = {
  show: false,
  message: "",
  navigateRequired: false,
};

const Router = () => {
  const { settoken, token, setkycModal, messageShown, setMessageShown } =
    useContext(ContextAPI);
  const { pathname } = useLocation();
  const { auth, userdetails, redirect_url } = useParams();
  const navigate = useNavigate();
  const [loggedIn, setloggedIn] = useState(token ? true : false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [invalidBrowserPopUp, setInvalidBrowserPopUp] = useState(
    invalidBrowserPopUpInitialValue
  );
  const [remaining, setRemaining] = useState(timeout);
  const [NetworkStatus, setNetworkStatus] = useState(true);

  const IsNotLoginScreen = useMemo(
    () => !pathname.includes("/login"),
    [pathname]
  );

  useEffect(()=>{
    handleTempPasswordRedirect(navigate)
  },[])

// useEffect(()=>{
  // debugger
  if (global.isGoldODEnabled) {
    console.log("gold od is enabled", global.isGoldODEnabled);
    otherLoggedinScreens.push(
      { url: "/goldod", component: <GoldOdList /> },
      { url: "/goldoddetails", component: <GoldOdDetails /> },
      { url: "/goldodpassbook", component: <GoldODPassBook /> },
      {
        url: "/goldodwithdrawalhistory",
        component: <GoldODWithdrawalHistory />,
      }
    );
    console.log({ otherLoggedinScreens });
  }

  // },[global.isGoldODEnabled])

  useEffect(() => {
    navigator.onLine ? setNetworkStatus(true) : setNetworkStatus(false);
  });
  useEffect(() => {
    sendPageviewToGTM(pathname);
    const splitPathname = pathname.split("/");
    const is_redirecturl = splitPathname?.some((item) =>
      item.includes("my-ksfe")
    );
    if (token) {
      setloggedIn(true);
    } else {
      if (!is_redirecturl) {
        if (!registerComponent.find((itm) => itm.url === pathname)) {
          navigate("/login", { replace: true });
        }
      } else {
      }
    }
  }, [pathname, token]);

  const sendPageviewToGTM = (pathname) => {
    try {
      const tagManagerArgs = {
        dataLayer: {
          event: "pageview",
          page: pathname, // Use the passed pathname
        },
      };

      // Send data to GTM
      const res = TagManager.dataLayer(tagManagerArgs);
      console.log("reste --->", res);
    } catch (error) {
      console.log("error at tagmanager --->", error);
    }
  };

  const handleClearAuth = async () => {
    await clearSessionStorage();
    settoken("");
    setkycModal(false);
    Modal.destroyAll();
    return navigate("/login", { replace: true });
  };
  const handleLogout = async () => {
    if (token) {
      let response = await ManageApis("get", global.logOut, "", token);
      if (response.message == "Success") {
        handleClearAuth();
      } else {
        if (!response.message.includes("Invalid token provided")) {
          FailedPopup(response);
        }
        handleClearAuth();
      }
    } else {
      handleClearAuth();
    }
  };
  const onIdle = () => {
    if (IsNotLoginScreen) {
      if (logoutConfirmation) {
        handleLogout();
      }
    }
  };

  const onPrompt = () => {
    if (IsNotLoginScreen) {
      setLogoutConfirmation(true);
    }
  };
  const onActive = () => {
    setLogoutConfirmation(false);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout,
    promptBeforeIdle,
    // timeout: 300_000,  // set as 5min
    throttle: 500,
  });

  useEffect(() => {
    if (IsNotLoginScreen) {
      if (!logoutConfirmation) {
        activate(); // Restart idle timer if confirmation is not shown
      }

      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [logoutConfirmation, getRemainingTime, pathname]);
  useEffect(() => {
    if (remaining === 0) {
      {
        handleConfirmation(true);
      }
    }
  }, [remaining]);

  const handleConfirmation = (confirm) => {
    if (confirm) {
      handleLogout();
    }
    setLogoutConfirmation(false);
  };

  const navigations = [
    ...registerComponent,
    ...dashboardComponent,
    ...otherLoggedinScreens,
  ];

  const authMangedNavigation = useMemo(() => {
    if (loggedIn) return navigations;
    else return registerComponent;
  }, [loggedIn]);

  const handleBrowserMessageClose = (navigateRequired) => {
    // if(navigateRequired) navigate("/login", { replace: true }); //not needed as of now
    setInvalidBrowserPopUp(invalidBrowserPopUpInitialValue);
    setMessageShown(true);
  };

  return (
    <>
      {logoutConfirmation && (
        <PopupModal
          visible={logoutConfirmation}
          handleClose={() => setLogoutConfirmation(false)}
          title={"Are you still there?"}
          message={`Logging out in ${remaining} seconds`}
          buttons={[{ text: "Yes", onPress: () => handleConfirmation(false) }]}
        />
      )}
      {NetworkStatus ? (
        <Routes>
          {authMangedNavigation.map((item) => (
            <Route element={item.component} path={item.url} />
          ))}
        </Routes>
      ) : (
        <NetworkUnavailable />
      )}
    </>
  );
};

export default Router;
