import { NewspaperOutline } from "react-ionicons"
import PopupModal from "../../Components/PopupModal"
import Layout from "../../Components/Layout/Layout"
import BackBtn from "../../Components/BackBtn/BackBtn"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { ManageApis } from "../../common/APIS/ManageApis"
import { FailedPopup, customLogger, objectToString, sanitizedText, successPopup } from "../../common/Utils/Utils"
import { ContextAPI } from "../../common/Context/ContextApi"
import NodataScreen from "../../Components/NodataScreen/NodataScreen"
import InputField from "../../Components/InputField/InputField"
import parse from 'html-react-parser';
import GeneralButton from "../../Components/Buttons/GeneralButton"
import PopConfirm from "../../Components/PopupConfirm/PopConfirm"
import moment from "moment"

function ManageProxy() {
  const { state } = useLocation()
  const [modalOpen, setmodalOpen] = useState(false)
  const [IsExisting, setIsExisting]=useState(false)

  // fetch proxy details from state
  //debugger
  const { chittal_id, chittal, chit_id, nextAuctionDay } = state || {}

  const { token } = useContext(ContextAPI)
  const [visible, setVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [proxies, setproxies] = useState([])
  const [showModal, setshowModal] = useState(true)
  const [selectedProxy, setselectedProxy] = useState({})
  const [InitialModalForMessage, setInitialModalForMessage] = useState(true)
  const [errors, setErrors] = useState({});
  const [proxyDisclaimerModal, setproxyDisclaimerModal] = useState(false)
  const [proxyDisclaimerMessage, setproxyDisclaimerMessage] = useState('')
  const [proxyData, setProxyData] = useState({
    chittal_id: "",
    installment_from: "",
    installment_to: "",
    amount: "",
    ipAddress: "", //required
    deviceId: "", //required pass user agent details for web 
    brand: "",
    buildNumber: "",
    bundleId: "",
    deviceType: "",
    modelNo: ""
  });
  const [validatedData, setvalidatedData] = useState({})
  const [isFormValidate, setisFormValidate] = useState(0)

  const [proxySubmitionBlock, SetproxySubmitionBlock] = useState(false)
  const [proxySubmitionBlockMsg, SetproxySubmitionBlockMsg] = useState('')

  useEffect(() => {
    if (chittal_id) {
      getProxies()
    }
    if (chittal) {
      getValidateProxy()
    }
    return (() => {
      setproxies([])
    })
    

  }, [chittal_id, chittal])

  const getValidateProxy = useCallback(async () => {
    let body = {
      chittal_id: chittal._id
    }
    setIsLoading(true)
    await ManageApis('post', global.validateproxy, body, token)
      .then((res) => {
        //console('validate proxy resp', res)
        const { status, data, message } = res.response_data
        if (status) {
          setIsLoading(false)
          // checking the min amount and max amount greater than zero, if the condition failed there is a pending installments to clear 
          if (data.minProxyAmt && data.maxProxyAmt) {
            // checking the next auction date 
            if (isCurrentDateTwoDaysBeforeBackendDate(data.nextAuctionDate)) {
                FailedPopup('The proxy should be submitted before 48 hours to the next auction date. please contact branch for direct proxy submission')
                SetproxySubmitionBlock(true)
                SetproxySubmitionBlockMsg('The proxy should be submitted before 48 hours to the next auction date. please contact branch for direct proxy submission')
            } else {
              SetproxySubmitionBlock(false)
              SetproxySubmitionBlockMsg('')
              setvalidatedData(data)
              let obj = objectToString(data)
              setProxyData({ chittal_id: obj.chittalNo, amount: obj.minProxyAmt, installment_from: obj.fromInstallment, installment_to: obj.toInstallment })
            }
          } else {
            setvalidatedData(data)
            FailedPopup('Please clear your pending installments before creating a new proxy')
            SetproxySubmitionBlock(true)
            SetproxySubmitionBlockMsg('Please clear your pending installments before creating a new proxy')
            setmodalOpen(false)
          }

        } else {
          FailedPopup(res)
          SetproxySubmitionBlock(true)
          SetproxySubmitionBlockMsg(message)
          setvalidatedData({})
          setIsLoading(false)
        }
      })
      .catch((err => {
        //console(err)
        FailedPopup('Something went wrong, please try later')
        setIsLoading(false)
      }))
  }, [chittal])
  //console(chittal_id)
  // handle cancel
  const handleCancel = (data) => {
    setselectedProxy(data)
    setVisible(true);
  };
  const handleConfirm = async () => {
    setIsLoading(true)
    const reqstBody = {
      chittal_no: selectedProxy.chittal_no,
      chit_id: chit_id,
      branch_id: selectedProxy.branch_id
    }
    await ManageApis("post", global.cancelProxy, reqstBody, token).then((res) => {

      const { status, data, message } = res.response_data || {}
      if (status) {
        //console("res", reqstBody)
        setVisible(false)
        setIsLoading(false)
        successPopup(message)
        getProxies()
      } else {
        //console("err", res)
        setVisible(false)
        setIsLoading(false)
        FailedPopup(res)
      }
    })
      .catch((err) => {
        customLogger("err", err)
        setVisible(false)
        setIsLoading(false)
        // FailedPopup(err)
      })
  }
  const Buttonprops = [
    { text: 'NO', onPress: () => setVisible(false),variant:'master-line' },
    { text: 'YES', onPress: handleConfirm,variant:'master' },
]

  const getProxies = useCallback(() => {
    setIsLoading(true)
    let body = {
      chittal_id: chittal_id
    }
    // debugger

    ManageApis('post', global.getProxyList, body, token)
      .then((res) => {
        setIsLoading(false)
        customLogger('get proxy resp', res)
        const { status, data } = res.response_data || {}
        if (status && data) {
          setproxies(data)
        } else {
          setproxies([])
        }
      })
      .catch((err) => {
        setIsLoading(false)
        //console(err)
        setproxies([])
      })
  }, [chittal_id, token])

  // function return a color for the proxy status chip
  const badgeForDiffStatus = useCallback((status) => {
    switch (status) {
      case 'LIVE':
        return 'badge-success badge_success_soft'
        break;
      case 'SUBMITTED':
        return 'badge-warning badge_warning_soft'
        break;
      case 'CANCELLED':
        return 'badge-danger badge_danger_soft'
        break;

      default:
        return 'badge-gray badge_gray_soft'
        break;
    }
  }, [proxies.length])

  // handle change  set all fields value to object state
  const handleOnchange = useCallback((text, type) => {
    // validate(type, text)
    const currentValue = Number(text)
    let installment_to = Number(proxyData.installment_to)
    let installment_from = Number(proxyData.installment_from)
    let fromInstallment = Number(validatedData.fromInstallment)
    let totalInstallment = Number(validatedData.totalInstallment)

    // //console('------------------------------------>>>>>>>>>>>>>>>>.', type, value)
    switch (type) {
      case "chittal_id":
        setProxyData((prevState) => ({ ...prevState, [type]: currentValue }));
        if (!currentValue) {
          handleError("Field is required", "chittal_id");
          return false;
        }
        handleError(``, "chittal_id")

        break;

      case "installment_from":
        setProxyData((prevState) => ({ ...prevState, [type]: sanitizedText(text) }));

        if (currentValue == '') {
          handleError("Field is required", "installment_from");
          return false;
        }
        else if (currentValue < fromInstallment) {
          // debugger
          handleError(`The minimum permissible value is ${validatedData.fromInstallment}`, "installment_from");
          return false;
        } else {
          handleError('', 'installment_from')
        } if (currentValue > totalInstallment) {
          handleError(`The maximum permissible value is ${validatedData.totalInstallment}`, "installment_from");
          return false;
        }

        handleError(``, "installment_from")
        if (currentValue + 11 < totalInstallment) {
          setProxyData(prev => ({ ...prev, installment_to: `${currentValue + 11}` }))
        } else {
          setProxyData(prev => ({ ...prev, installment_to: `${totalInstallment}` }))
        }
        break;


      case "installment_to":
        setProxyData((prevState) => ({ ...prevState, [type]: sanitizedText(text) }));

        if (currentValue == '') {
          handleError("Field is required", "installment_to");
          return false;
        }
        else if (currentValue < installment_from) {
          handleError(`The minimum permissible value is ${installment_from}`, "installment_to");
          return false;
        } else {
          handleError('', 'installment_to')
        } if (currentValue > totalInstallment) {
          handleError(`The maximum permissible value is ${validatedData.totalInstallment}`, "installment_to");
          return false;
        }

        if (currentValue > installment_from + 11) {
          handleError(`The maximum permissible value is ${installment_from + 11}`, "installment_to");
          return false;
        }



        // debugger
        handleError(``, "installment_to")
        // debugger
        // validate('installment_from', proxyData.installment_from)
        break;
      case "amount":
        setProxyData((prevState) => ({ ...prevState, [type]: sanitizedText(text) }));

        if (!currentValue) {
          handleError("Field is required", "amount");
          return false;

        }
        else if (!(currentValue >= Number(validatedData.minProxyAmt) && currentValue <= Number(validatedData.maxProxyAmt))) {
          //debugger
          handleError(`Please enter the amount between ${validatedData.minProxyAmt} - ${validatedData.maxProxyAmt} `, "amount");
          return false;
        }
        handleError('', 'amount')

        break;

      // debugger
      default:
        return
        break;
    }
    //console("first", proxyData);
  }, [proxyData, validatedData]);


  useEffect(() => {
    //console.log({ validatedData })
  }, [validatedData])


  // validate field and set error to state
  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const errorArray = useMemo(() => Object.values(errors).filter(item => !!item?.length && item), [errors])

  useEffect(() => {
    //console.log({ errorArray })
  }, [errorArray])

  // create proxy api , this api call after user agree the disclaimer
  const handleCreateProxy = async () => {
    // copying error message into an array for validation
    if (!errorArray.length) {
      setIsLoading(true)
      const reqstBody = {
        chittal_id: chittal._id,
        installment_from: Number(proxyData.installment_from),
        installment_to: Number(proxyData.installment_to),
        amount: proxyData.amount,
        deviceId: navigator.userAgent,
        deviceType: 'Web Portal',
      };

      await ManageApis("post", global.createProxy, reqstBody, token)
        .then(response => {
          const { status, data, message } = response.response_data || {}
          if (status) {
            //console("res", response);
            setIsLoading(false)
            successPopup(message)
            setproxyDisclaimerModal(false)
            setmodalOpen(false)
            getProxies()
          } else {
            //console("res", response);
            setIsLoading(false)
            setproxyDisclaimerModal(false)
            
            FailedPopup(response)
          }
        })
        .catch((err) => {
          //console(err)
          setIsLoading(false)
          FailedPopup('Failed')
        })


    }
  }


  // proxy disclaimer api , this api calls on when user click create proxy button 
  const handleCallProxyDisclaimer = async () => {
    if (isFormValidate) {
      return
    }
    // debugger
    setIsLoading(true)
    // let deviceInfo = await getDeviceDetails()
    const reqstBody = {
      chittal_id: chittal._id,
      installment_from: proxyData.installment_from,
      installment_to: proxyData.installment_to,
      amount: proxyData.amount,
      deviceId: navigator.userAgent,
      deviceType: 'Web Portal',
      //ipAddress: '119.20.6.203'
      // ...deviceInfo
    };
    await ManageApis('post', global.getProxyDisclaimer, reqstBody, token)
      .then((res) => {
        setIsLoading(false)
        const { status, data, message } = res.response_data || {}
        if (status) {
          setmodalOpen(prev => false)
          setproxyDisclaimerMessage(data.disclaimer)
          setproxyDisclaimerModal(prev => true)
        } else {
          FailedPopup(res)
          setproxyDisclaimerMessage('')
        }
      })
      .catch((err) => {
        setIsLoading(false)

        //console(err)
      })

  }


  const formField = useMemo(() => [
    { type: 'text', label: 'CHITTAL NO', placeholder: '', required: true, name: 'chittal_id', disabled: true, },
    { type: 'number', label: 'PROXY START INSTALLMENT NO', placeholder: '', required: true, onchange: handleOnchange, name: 'installment_from', disabled: errors?.installment_to?.length, },
    { type: 'number', label: 'PROXY END INSTALLMENT NO', placeholder: '', required: true, onchange: handleOnchange, name: 'installment_to', disabled: errors?.installment_from?.length, },
    { type: 'number', label: 'PROXY AMOUNT', placeholder: '', required: true, onchange: handleOnchange, name: 'amount', disabled: false, helperText:`The amount should be between ${validatedData.minProxyAmt}-${validatedData.maxProxyAmt}` },
    ,
  ], [errors, proxyData])

  // create proxy button
  const handleClick = () => {
    if(proxySubmitionBlock){
      FailedPopup(proxySubmitionBlockMsg)
      return
    }
    // if (isCurrentDateTwoDaysBeforeBackendDate(nextAuctionDay)) {
    //   FailedPopup('The proxy should be submitted before 48 hours to the next auction date. please contact branch for direct proxy submission')
    //   return
    // }
    // when proxy is empty 
    if (!proxies.length) {
      setmodalOpen(true)
    }
    // when the proxy is live status and above 48hrs from next auction date 
    //else if (!proxies?.some(item => ['SUBMITTED'].includes(item.status)) && !isCurrentDateTwoDaysBeforeBackendDate(nextAuctionDay)) {
    else if (!proxies?.some(item => ['SUBMITTED'].includes(item.status))) {
      setmodalOpen(true)
      setIsExisting(proxies?.some(item => ["LIVE"].includes(item.status)))
      // showToastWithDuration(`You can't create a proxy at this moment`)
    } else {
      FailedPopup("Please submit a proxy request after a pending proxy request")
      return
    }
    
  }

  return (
    <Layout loading={isLoading}>
      <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
        <div className="mb-3">
          <div className='align-items-center d-flex justify-content-between flex-wrap'>
            <BackBtn title={'Manage Proxy'} />
            <div className="col-12 col-sm-auto pt-3 pt-sm-2 pe-0 pe-sm-4">
              <span className="pe-1 fw_700">Chit No :</span>
              <span className="fw_500">{chit_id}</span>
            </div>
            <div className="col-12 col-sm-auto pt-3 pt-sm-2">
              {
                proxies?.some(item => ["LIVE"].includes(item.status))?
                
                <PopConfirm
                  title={"Are you sure you want to continue? This will cancel the existing proxy and create a new one."}
                  onOk={handleClick}
                  onCancel={() => { return }}
                >
                  <GeneralButton 
                    disabled={proxies?.some(item => ["SUBMITTED"].includes(item.status))}
                    disabledMessage={ `You have already a proxy in submitted status, further proxy creation is restricted through the branch or in the app for the same day`}
                    title={"Create Proxy Request"} 
                    //onClick={handleClick}
                  />
                </PopConfirm>
                :

                proxies?.some(item => ["SUBMITTED"].includes(item.status)) ?
                <GeneralButton 
                  disabled={proxies?.some(item => ["SUBMITTED"].includes(item.status))}
                  disabledMessage={ `You have already a proxy in submitted status, further proxy creation is restricted through the branch or in the app for the same day`}
                  title={"Create Proxy Request"} 
                  onClick={handleClick}
                />
                :
                <GeneralButton 
                  disabled={proxySubmitionBlock}
                  disabledMessage={proxySubmitionBlockMsg}
                  title={"Create Proxy Request"} 
                  onClick={handleClick}
                />
              }
              
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="p-3 bg-white br_10 shadow_sm">
            {proxies.length ?
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500">Chittal No</th>
                      <th className="fw_500">Installment From</th>
                      <th className="fw_500">Installment To</th>
                    
                      <th className="fw_500" style={{minWidth:100}}>Proxy Created Date</th>
                      {proxies.some(item => item.expiry_at !== item.updated_at) && (
                      <th className="fw_500" style={{ minWidth:100}}>Proxy Expiry At</th>
                       )}
                      {proxies.some(item => item.item?.deleted_at) && (
                        <th className="fw_500" style={{ minWidth: 100 }}>Proxy cancelled on</th>
                      )}
                      <th className="fw_500">Status</th>
                      <th className="fw_500 text-end">Proxy Amount</th>
                      <th className="fw_500" style={{ width: "90px" }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {proxies.map(item =>
                      <tr>
                        <td>{item.chittal_no}</td>
                        <td>{item.installment_from}</td>
                        <td>{item.installment_to}</td>
                        <td>{item.updated_at}</td>
                        {item.expiry_at !== item.updated_at && (
                        <td>{ item.status == "CANCELLED" ? "-" :item?.expiry_at}</td>
                        )}
                        {item.item?.deleted_at ?
                        <td>{item.item?.deleted_at}</td> :
                          <></>
                        }
                        <td>
                          <span className={`badge ${badgeForDiffStatus(item.status)}  font_12 fw_500 rounded-pill`}>{item.status == "CANCELLED" ? `${item.status} ON ${item?.deleted_at}` : item.status}</span>
                        </td>
                        <td className="text-end">{item.amount}</td>
                        <td>
                          {/* // here we disabled the button in two case
                          //  1.the status of the proxy not live 
                          //  2.the next auction day within 48hr */}
                          <button
                            disabled={!["LIVE"].includes(item.status) || isCurrentDateTwoDaysBeforeBackendDate(nextAuctionDay)}
                            onClick={() => handleCancel(item)}
                            className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100">Cancel</button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <NodataScreen title="No Proxy found" desc="Do you want to add proxy , just click on the create proxy" />
            }
          </div>
        </div>
      </div>
      {modalOpen &&
        <PopupModal title="Create Proxy Request" size="md" visible={modalOpen} handleClose={() => setmodalOpen(false)}>
          <p>Note : After creating a proxy through the app, further proxy creation is restricted through the branch or in the App for the same day.</p>
          <div>
            {formField.map(item => (
              <InputField {...item} value={proxyData[item.name]} errorMessage={errors[item.name]} seterrorMessage={(...value) => console.log(value)} />
            ))}

            <div>
            {/* {IsExisting?
              <PopConfirm
                title={"Are you sure you want to continue? This will cancel the existing proxy and create a new one."}
                onOk={handleCallProxyDisclaimer}
                onCancel={() => { return }}
              >
                <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" 
                disabled={errorArray.length >= 1}>Create Proxy Request</button>
              </PopConfirm>
              
              :
                ""
            } */}
              <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" 
                disabled={errorArray.length >= 1} 
                onClick={(errorArray.length >= 1)? ()=>{return} : handleCallProxyDisclaimer}
                >Create Proxy Request</button>
              
            </div>
          </div>
        </PopupModal>
      }

      {proxyDisclaimerModal &&
        <PopupModal title="Proxy Disclaimer" size="xl" visible={proxyDisclaimerModal} handleClose={() => setproxyDisclaimerModal(false)}>
          <div className="poxyModal">
            <div className="height_t1 overflow-auto custom_scroll1 proxydoc">
              {parse(proxyDisclaimerMessage)}
            </div>
          </div>
            <div className="pt-2">
              <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" onClick={handleCreateProxy}>Agree</button>
            </div>
        </PopupModal>
      }

      {InitialModalForMessage &&
        <PopupModal title="Create Proxy Request" size="md" visible={InitialModalForMessage} handleClose={() => setInitialModalForMessage(false)}>
          <div>
            <div className="text_justify">
              CHITTY PROXY SUBMISSION The User can submit online proxy authorization for non-prized and updated chitties, authorizing the Branch Manager concerned to participate in the chit auction on behalf of the user. Such online proxy submission should be made at least 2 days before the installment due date from which the proxy is to be effected. If the User wishes to cancel an existing Proxy Authorisation, either submitted online or offline, the same should also be done 2 days prior to the chitty due date.
            </div>
            <div className="pt-2">
              <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" onClick={() => setInitialModalForMessage(false)}>OK</button>
            </div>
          </div>
        </PopupModal>
      }

      {visible &&
        <PopupModal 
          visible={visible} 
          handleClose={() => setVisible(false)} 
          size={'md'} 
          title={''} 
          message={'You have selected an active proxy for cancellation. New proxy can be added only after processing this request which may take up to 48hrs. Are you sure you want to continue?'} 
          buttons={Buttonprops} 
        />
      }
    </Layout>
  )
}

export default ManageProxy

function isCurrentDateTwoDaysBeforeBackendDate(backendDate) {
  // Parse the day received from the backend and convert it into a valid date format
  const backendDateParts = backendDate.match(/\d+/g);
  if (!backendDateParts) {
    throw new Error("Invalid backend date format. Expected format: '24 of every month'");
  }

  const dayOfMonth = parseInt(backendDateParts[0]);
  const currentDate = new Date();

  // Check if the backend day is in the current or next month
  if (dayOfMonth >= parseInt(currentDate.getDate())) {
    let differenceInDays = dayOfMonth - currentDate.getDate();
    return differenceInDays <= 2;
  } else {
    return false

  }
}
