import { ChevronBackOutline } from "react-ionicons"
import Logo from "../../assets/images/Logo.png"
import { useEffect, useState } from "react";
import Item from "@arco-design/web-react/es/Breadcrumb/item";
import { useLocation } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { FailedPopup, customLogger, preventNumSpecialChars, sanitizePhoneNumber } from "../../common/Utils/Utils";
import PopupModal from "../../Components/PopupModal";
import Captcha from "../../Components/CaptchaScreen/Captcha";


function VerifyOTP({ verificationData, handleResendOTP, handleBackBtn, handleSubmit, value, disabled=false,showCaptcha,setshowCaptcha,isCaptcha}) {
	const { state } = useLocation()
	const { token_alphabet_part, masked_mobile_number } = verificationData || {}
	const [minutes, setMinutes] = useState(1);
	const [seconds, setSeconds] = useState(59);
	const [disableBtn, setdisableBtn] = useState(disabled);
	const [otpAlphabet, setotpAlphabet] = useState();
	const [Otp, setOtp] = useState(["", "", "", ""])
	// customLogger({ state })

	useEffect(() => {
		token_alphabet_part &&
		setotpAlphabet(token_alphabet_part.replace(/-/g, ""))
		// console.log(otpAlphabet);
	})



	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
			// setOtp([])
		};
	}, [seconds]);


	  // Function to check if OTP fields are empty
	  const isOtpEmpty = () => {
		return Otp.some(field => !field);
	  };

	  const handleCaptcha=()=>{
		setTimeout(async () => {
			try {
			  let response = await handleSubmit(Otp.toString().replaceAll(',',''))
				if (response?.includes("Success")) handleClear()
				else setOtp(["","","",""])
			//  handleClear()
			} catch (err) {
			  //console.log({ err })
			}
		  }, 100);
	  }
	  
	const handlechange = (e, index) => {
		//console.log("test",e);
		customLogger(e);
		let { value } = e.target;
		// value = preventNumSpecialChars(value)
		if (value.length > 0) {
			const next = e.target.nextElementSibling;
			let newOTP = [...Otp];
			newOTP[index] = value;
			setOtp(newOTP);
			if (next) {
				next.focus();
			}
		} else {
			const newOTP = [...Otp];
			newOTP[index] = "";
			// alert(index)
			setOtp(newOTP);
		}
	};
	const handleKeydown = (e, index) => {
		const { key, target } = e
		if (key == '+' || key == '*' || key == '/' || key == '-' || key == '.') {
			e.preventDefault();
		}
		// customLogger(e.target.previousElementSibling);
		if (key === "Backspace" && target.value == "") {
			const prev = target.previousElementSibling;
			const newOTP = [...Otp];
			newOTP[index] = "";
			setOtp(newOTP);
			if (prev) {
				document.getElementById(target.id);
				prev.focus();
			}
		}
	};

	const resendOTP = () => {
		if (seconds === 0 && minutes === 0) {
			setMinutes(1);
			setSeconds(59);
			setOtp(["","","",""])
			handleResendOTP()
		} else return

	};


	const handleClear = () => {
		setMinutes(1);
		setSeconds(59);
		setOtp(["","","",""])
	}

	const OnSubmit = () => {
		let btnStatus  = disableBtn
		setdisableBtn(true)
		setTimeout(() => {
            setdisableBtn(btnStatus)
        }, 500);
		

		if(!isOtpEmpty()){
		if(isCaptcha){
			setshowCaptcha(true)
		}else{
			handleCaptcha()
		}
		}else{
			FailedPopup("Please fill all OTP fields")
		}
	  }

	  
	return (

		<div className="d-flex flex-column h-100 p-3 p-md-5 overflow-auto scrollbar1">
		 {!!showCaptcha &&
			<PopupModal className='captcheModal' title="Captcha Verification" size="sm" visible={showCaptcha} handleClose={()=>setshowCaptcha(false)} >
				<div className="">
					<Captcha onSubmit={handleCaptcha} disabledButton={false} />
				</div>
			</PopupModal>
		 }
			<div className="">
				<BackBtn handleBackBtn={handleBackBtn} />
			</div>
			<div className="m-auto max_w_350x py-3 w-100">
				<div>
					<div className="text-center">
						<img src={Logo} className="mb-4 logo_prop" />
					</div>
					<h1 className="h4 text-center text_master fw_700 pb-3">OTP Verification</h1>

					<div className="">
						<div className="text-center">
							<div>
								<div className="form_bx_stack mb-3">
									<label className="mb-2 pb_2x text_master fw_500 font_14">{`Enter the OTP sent to ${masked_mobile_number ? masked_mobile_number : verificationData?.masked_email_id ? verificationData?.masked_email_id : ''}`}</label>
									<div className="data d-flex justify-content-center align-items-center gap-1 gap-sm-3 otp_custom_fields">
										{otpAlphabet &&
											<>
												<input type="text" className="form-control hide_arrows text-center  font_16 px-1" value={otpAlphabet} disabled />
												<span>-</span>
											</>
										}
										{[1, 2, 3, 4].map((Item, idx) =>
											<input type="tel" maxLength={1} value={Otp[idx]} onKeyDown={(e) => handleKeydown(e, idx)} onChange={(e) => handlechange(e, idx)} onInput={sanitizePhoneNumber}
												onPaste={(e) => e.preventDefault()}
												className="form-control hide_arrows text-center  font_16 px-1" />
										)}
									</div>
								</div>
								<div>
									<p className="text_m2 fw_500 mb-2">{minutes < 10 ? `0${minutes}` : minutes}: {seconds < 10 ? `0${seconds}` : seconds}</p>
								</div>
								<div className="mb-2">
									<span className="text_m1">Didn't receive the code?</span> 
											{
												(seconds === 0 && minutes === 0 )?
												<a onClick={resendOTP} 
													className="cursor-pointer text-decoration-none text_master2 hover fw_500 ws_nowrp">Resend OTP</a>
										:
											<a className="disable_div cursor-pointer text-decoration-none text_master2 hover fw_500 ws_nowrp">Resend OTP</a>
											}
									
								</div>
								<button
									type="submit"
									className="btn px_btn btn-master px-4 rounded w-100 fw_500 mt-2"
									onClick={OnSubmit}
									disabled={disableBtn}
								>
									Proceed
								</button>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	)
}

export default VerifyOTP





