export const Constant = {
    paymentsussessMsg: "Payment successful and it will be reflected in the respective accounts within 24 hours",
    paymentFailMsg:"Your payment has failed. If your account got debited, check payment history after 1 hour for the latest payment status before re-attempting payment."
    
}

export const GoldOdConstants = {
    AmtWithdrawal: "Available amount for withdrawal",
    AmtWithdrawalFieldLabel: 'Enter the amount you wish to withdraw',
    AmtDue: "Total amount due",
    AmtDueFieldLabel: 'Enter the amount you wish to pay',
    CheckBoxConfirmationLabel:"I hereby declare that the above mentioned bank details has been verified",
    BankDetailsConfirmationLabel:"Please verify your bank details before submitting the withdrawal request",
    AlreadyRejected:"Beneficiary details is already rejected by customer"
}
export const defaultTexts={
    passwordNote:'Note: Password must be between 8 and 12 characters long and contain a combination of uppercase and lowercase letters, numbers, and symbols',
    usernameNote:"Note: Username must be between 6 and 15 characters long and should only contain alphanumeric characters.",
    paymentFailedMsg:"Your payment has failed. If your account got debited, check payment history after 1 hour for the latest payment status before re-attempting payment.",
    paymentInProcessMsg:"Your payment is currently being processed. If your account got debited, check payment history after 1 hour for the latest payment status before re-attempting payment.",

}