import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import DataTable from "../../Components/DataTable/DataTable";
import { Skeleton } from "@arco-design/web-react";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import InputField from "../../Components/InputField/InputField";
import ViewNomineeDetailsModal from "../ViewNomineeDetails/ViewNomineeDetailsModal";

function SugamaPaymentList() {
  const Navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const handleBackBtn = () => {
    Navigate("/mySugamaAccount");
  };
  const dummyPaymentDetails = [
    {
      items: [
        { key: "Sl.No", value: "1" },
        {
          key: "Scheme Name",
          value: "Loan 123456",
        },
        { key: "Scheme Details (Chittal No.)", value: "142" },
        { key: "Amount", value: "8787" },
      ],
    },
  ];
  const dummySugumaPaymentDetails = [
    {
      items: [
        { key: "Sl.No", value: "1" },
        {
          key: "Scheme Name",
          value: "Loan 123456",
        },
        { key: "Scheme Details (Chittal No.)", value: "142" },
        { key: "discription", value: "suguma account" },
        { key: "Amount", value: "8787" },
      ],
    },
  ];
  const handleButtonClick = (index) => {
    alert(`Button clicked for row ${index}`);
  };
  return (
    <Layout loading={isLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-lg-center d-flex flex-column flex-lg-row justify-content-between flex-wrap gap-2">
            <BackBtn title={"Payment List"} handleBackBtn={handleBackBtn} />
            <button
              //   onClick={() => Navigate("/viewStandingInstructions")}
              className="btn btn-master flex-fill  flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded"
            >
              Add More
            </button>
          </div>
          <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3 mt-3">
            <h3 className="fw_600 font_18 text_master ms-2 mb-3">Chitty</h3>
            <div className=" pb-1">
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500">Sl.No</th>
                      <th className="fw_500" style={{ minWidth: "159px" }}>
                        Scheme Name
                      </th>
                      <th className="fw_500">Scheme Details (Chittal No.)</th>
                      <th className="fw_500">Amount</th>
                      <th className="fw_500 text-center"></th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {dummyPaymentDetails.map((itm, rowIndex) => (
                      <tr key={rowIndex}>
                        {itm.items.map((item, idx) => (
                          <td
                            key={idx}
                            className={item.key === "Amount" ? "text-end" : ""}
                          >
                            {item.value}
                          </td>
                        ))}
                        <td className="text-center">
                          <button
                            className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp"
                            onClick={() => handleButtonClick(rowIndex)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <h3 className="fw_600 font_18 text_master ms-2 mb-3 mt-2">
              My Suguma Account
            </h3>
            <div className=" pb-1">
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500">Sl.No</th>
                      <th className="fw_500" style={{ minWidth: "159px" }}>
                        Suguma Account Id
                      </th>
                      <th className="fw_500">Deposit Amount</th>
                      <th className="fw_500">Description</th>
                      <th className="fw_500">Amount</th>
                      <th className="fw_500 text-center"></th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {dummySugumaPaymentDetails.map((itm, rowIndex) => (
                      <tr key={rowIndex}>
                        {itm.items.map((item, idx) => (
                          <td
                            key={idx}
                            className={item.key === "Amount" ? "text-end" : ""}
                          >
                            {item.value}
                          </td>
                        ))}
                        <td className="text-center">
                          <button
                            className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp"
                            onClick={() => handleButtonClick(rowIndex)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3 mt-3">
            <div className="row align-items-center justify-content-end">
              <div className="col-auto font_13">NET AMOUNT :</div>
              <div className="col-auto pt-1 pt-sm-0 mb-3 mb-sm-0">
                <div className="d-flex align-items-center">
                  {/* <span className="pe-1 d-none d-sm-block">:</span> */}
                  <span className="text-black word_break fw_600 font_13">
                    3000
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <button
                //   onClick={() => Navigate("/viewStandingInstructions")}
                className="btn btn-master flex-fill  flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded"
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SugamaPaymentList;
