import { Heart } from "react-ionicons";
import { ManageApis } from "../../../common/APIS/ManageApis";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { IconHeart, IconHeartFill } from "@arco-design/web-react/icon";
import PopupModal from "../../../Components/PopupModal";
import GeneralButton from "../../../Components/Buttons/GeneralButton";
import { FailedPopup, successPopup } from "../../../common/Utils/Utils";
const InitialValue = { loanList: [], chittal_list: [] };
export const Addtofavorites = ({
  loanee_id,
  chittal_id,
  type,
  setloader,
  alreadyAdded,
  favorite_id,
}) => {
  const { token, getFavourite } = useContext(ContextAPI);

  const [alreadyExist, setAlreadyExist] = useState(alreadyAdded);

  const handleAddToFavourite = async () => {
    try {
      setloader(true);
      const path = global.saveUserFavorite;
      let body;

      if (type === "loan") {
        body = {
          loanee_id,
          type,
        };
      } else {
        body = {
          chittal_id,
          type,
        };
      }

      const result = await ManageApis("post", path, body, token);
      const { status, message, data } = result.response_data || {};
      console.log(result);
      if (status) {
        console.log({ data });
        setAlreadyExist((prev) => true);
        successPopup(message);
      } else {
        if (message.includes("already added")) {
          setAlreadyExist((prev) => true);
        }
        FailedPopup(message);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setloader(false);
    }
  };
  const handleRemoveFromFavourite = async () => {
    try {
      setloader(true);
      const path = global.deleteUserFavorite;
      // console.log({favorite_id})
      // if (favorite_id === undefined) {
      const res = await getFavourite();
      if (res) {
        let result = [];
        if (loanee_id) {
          result = res.loanList;
        } else if (chittal_id) {
          result = res.chittal_list;
        }

        favorite_id = result?.find(
          (item) =>
            item._id === chittal_id ||
            item.account_id === chittal_id ||
            item._id === loanee_id
        ).favourite_id;
      }
      // }
      // if (favorite_id) {
      const body = {
        favorite_id,
      };

      const result = await ManageApis("post", path, body, token);
      const { status, message, data } = result.response_data || {};
      console.log(result);

      if (status) {
        console.log({ data });
        setAlreadyExist((prev) => false);
        successPopup(message);
      } else {
        FailedPopup(message);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setloader(false);
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {alreadyExist ? (
        <GeneralButton
          title={"Remove from Favourites"}
          onClick={() => handleRemoveFromFavourite(chittal_id || loanee_id)}
          type="btn-master-line"
          icon={
            <IconHeart
              style={{ color: 'var(--mastercolor)', marginRight: "8px" }}
              fontSize={16}
            />
          }
        />
      ) : (
        <GeneralButton
          title={"Add to Favourites"}
          onClick={handleAddToFavourite}
          type="btn-master"
          icon={
            <IconHeartFill
              style={{ color: "white", marginRight: "8px" }}
              fontSize={16}
            />
          }
        />
      )}
    </div>
  );
};
