import {
  CheckmarkCircleOutline,
  CloseCircleOutline,
  NewspaperOutline,
} from "react-ionicons";
import PopupModal from "../../Components/PopupModal";
import { Button, Col, Modal, Row } from "react-bootstrap";
import bankLong from "../../assets/images/axisbank.png";
import Captcha from "../../Components/Captcha";
import Layout from "../../Components/Layout/Layout";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { ManageApis } from "../../common/APIS/ManageApis";
import { useCallback, useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import {
  ConfirmSuccessPopup,
  FailedPopup,
  JSONParse,
  customLogger,
  getSessionStorage,
  loadScript,
  setSessionStorage,
  successPopup,
} from "../../common/Utils/Utils";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NodataScreen from "../../Components/NodataScreen/NodataScreen";
import PopConfirm from "../../Components/PopupConfirm/PopConfirm";
import { Modal as Modals } from "@arco-design/web-react";
import { Constant, defaultTexts } from "../../constant";
import { fedBankPG } from "./fedBankPG";
import { load } from "@cashfreepayments/cashfree-js"
import { cashFreePG } from "./cashFreePG";

const invalidBrowserPopUpInitialValue = { show:false, message:"", navigateRequired:false }

function PaymentList() {
  const navigate = useNavigate();
  const { auth, userdetails, redirect_url } = useParams();
  const { token, settoken, setnotificationsDetails, getNotification, messageShown,setMessageShown } =
    useContext(ContextAPI);
  const [paymentModalVissible, setPaymentModalVissible] = useState(false);
  const [data, setdata] = useState({});
  const [cartItems, setcartItems] = useState([]);
  const [cartItemsLoan, setcartItemsLoan] = useState([]);
  const [cartItemsChitty, setcartItemsChitty] = useState([]);
  const [cartItemsGoldod, setcartItemGoldod] = useState([]);
  const [cartItemssuguma, setcartItemSuguama] = useState([]);
  const [PaymentGateWays, setPaymentGateWays] = useState([]);
  const [loader, setloader] = useState(false);
  const [chromeVersionCheckLoader, setChromeVersionCheckLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [OpenPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
  const [paymentSuccess, setpaymentSuccess] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [responseData, setResponseData] = useState(null);
  // const [paymentStatusSuccessMsg, setpaymentStatusSuccessMsg] = useState('');
  // const [paymentStatusFailedMsg, setpaymentStatusFailedMsg] = useState('');
  const [paymentStatusMsg, setpaymentStatusMsg] = useState("");
  const [invalidBrowserPopUp, setInvalidBrowserPopUp] = useState(invalidBrowserPopUpInitialValue)

  const sessionData = getSessionStorage("userdetails");

  const handleDisableButton = () => setbuttonDisable((prev) => true);
  const handleEnableButton = () => setbuttonDisable((prev) => false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    if (status) {
      window.history.pushState(null, "",  window.location.pathname);
      window.onpopstate = function () {
        window.history.pushState(null, "",  window.location.pathname);
      };
    }
  }, [status])

  useEffect(() => {
    if (auth && userdetails) {
      (async () => {
        localStorage.setItem("auth", auth);
        settoken((prev) => auth);
        // console.log({ userdetails, redirect_from: redirect_url });

        let userBasicDetails = {
          ...JSONParse(userdetails),
          redirect_from: redirect_url,
        };
        localStorage.setItem("userdetails", JSON.stringify(userBasicDetails));

        handleMobileWebViewRequest();
      })();
    }
    return () => {
      if (redirect_url) {
        settoken("");
      }
    };
  }, [auth, userdetails]);
  useEffect(() => {
    if (status) {
      const accessToken = token | auth;
      if (accessToken) {
        getNotification(token);
      }
      setOpenPaymentStatusModal(true);
      setpaymentStatusMsg(
        status == "success"
          ? Constant.paymentsussessMsg
          : Constant.paymentFailMsg
      );

      setpaymentSuccess(status === "success");
    }
    // else if (status && sessionData.redirect_from) {
    //   const redirectURL = `${sessionData.redirect_from}?payment_result=${status === "failed" ? 0 : 1
    //     }${status === "failed" ? "&error=Payment Failed" : ""}`;
    //   window.location.href = redirectURL;
    // }
  }, [status]);

  useEffect(() => {
    if (token) {
      getCartDetails(auth);
    }

    return () => {
      clearcart()
      setPaymentGateWays([]);
    };
  }, [token]);

  const clearcart =()=>{
    setdata([]);
    setcartItems([]);
    setcartItemsLoan([]);
    setcartItemsChitty([]);
    setcartItemGoldod([])
    setcartItemSuguama([])
  }

  const handleRedirectToApp = (type, error, message) => {
    if (redirect_url) {
      let baseurl = sessionData?.redirect_from || redirect_url
      if (type != undefined) {
        baseurl += `?payment_result=${type}`
        setOpenPaymentStatusModal(true);
        //in this case 'type' is 0 that why here payment status always false, if passing a not 0 value kindly manage here
        setpaymentSuccess(false);
        setpaymentStatusMsg(message);
      } else {
        baseurl += type != undefined ? '&' : '?' + `error=${error}`
      }
      console.log('redirection url', { baseurl })
      // window.location.href = baseurl
    } return
  }

  const handleMobileWebViewRequest = useCallback(async () => {
    setloader((prev) => true);
    const cartItems = await getCartDetails(auth);
    setloader(true)
    if(!messageShown)
      handleBrowserCheck()
    setloader(false)
  }, [auth]);

  const handleBrowserCheck = async() => {
  
    const userAgent = window.navigator.userAgent;

    // Check if it's Chrome on mobile
      const isMobileChrome = /Chrome\/[0-9.]+ Mobile/.test(userAgent) && !/SamsungBrowser/.test(userAgent);

      if (!isMobileChrome) {
        setInvalidBrowserPopUp({ show:true, message: "",navigateRequired:true })
        return;
      }

    const chromeVersionMatch = userAgent.match(/Chrome\/([0-9]+)/);
    const majorVersion = chromeVersionMatch ? parseInt(chromeVersionMatch[1], 10) : null;

    const fetchLatestMobileChromeVersion = async () => {
      try {
        setChromeVersionCheckLoader(true)
        const response = await fetch('https://versionhistory.googleapis.com/v1/chrome/platforms/android/channels/stable/versions');
        const data = await response.json();
        const latestVersion = data.versions[0].version;
        
        if(!latestVersion.includes(majorVersion)){
          // navigate("/login", { replace: true });
          setInvalidBrowserPopUp({ show:true, message: "" })
        }else{
          handleBrowserMessageClose()
        }
        setChromeVersionCheckLoader(false)
      } catch (error) {
        setChromeVersionCheckLoader(false)
        console.error('Error fetching the latest Chrome version:', error);
      }
    };

    await fetchLatestMobileChromeVersion();
    
  }

  // redirect to payment gateway page
  const handleGateWay = async (
    PaymentGateWay,
    secret_key,
    paramData = data,
    authToken = token
  ) => {
    // the details are required for payment
    if ((paramData.total_amount && PaymentGateWay) || secret_key) {
      handleDisableButton();
      // setVisible(prev => false)
      let userData = {}; //initially decalare a variable for storing the userdata
      let orderDetails = {}; //initially decalare a variable for storing the api response
      //when the user selected payment gateway not axis bank the function will end here
      if (!["AXIS", "IDBI", "SIB", "HDFC", "FED"].includes(PaymentGateWay)) {
        handleEnableButton();
        return FailedPopup(
          "The Service currently not available. Please try later"
        );
      }
      //fetching async data

      let sessiondata = getSessionStorage("userdetails");
      const { email, mobile, first_name, last_name } = sessiondata || {};
      userData = { email, mobile, first_name, last_name };
      // return //console.log(UserData)
      let body = {
        amount: paramData.total_amount,
        gateway: PaymentGateWay,
        source: redirect_url ? "mobile" : "web",
      };

      if (body.amount && body.gateway && authToken) {
        // creating a new order
        ManageApis("post", global.createtransaction, body, authToken)
          .then((res) => {
            customLogger("order create response", res);
            const { status, message, data } = res.response_data;
            if (status === true) {
              const {
                pg_order_id,
                amount,
                transaction_id,
                currency,
                gateway_details,
                payment_session_id,
                environment
              } = data;
              orderDetails = {
                pg_order_id,
                amount,
                transaction_id,
                currency,
                gateway_details,
                payment_session_id,
                environment,
                ...gateway_details
              };
              
              setSessionStorage('paymentDetails', JSON.stringify({...data,PaymentGateWay})) 
              return PGSection(secret_key);
            } else {
              FailedPopup(res);
              handleEnableButton();
              return;
            }
          })
          .catch(async (err) => {
            console.log("order create error response", err);
            handleEnableButton();
            await getCartDetails();
          });
      }
      // call after succeful order creation
      async function PGSection(secret_key) {
        !redirect_url && handleClosePaymentModal();
        await getCartDetails();
        switch (PaymentGateWay) {
          case "AXIS":
            RazorpayPayment(secret_key);
            break;
          case "SIB":
            RazorpayPayment(secret_key);
            break;
          case "IDBI":
            IDBIPayment(secret_key);
            break;
          case "HDFC":
            HDFCPayment(secret_key);
            break;
          case "FED":
            FEDBankPayment(orderDetails.gateway_details);
            break;
          // case "AXIS_CF":
          //   CashFreePayment()
          //   break;
          default:
            break;
        }
      }
      // razorpay payment gateway
      async function RazorpayPayment(secret_key) {
        setloader(true)
        // async function displayRazorpay() {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
          const errorMessage = "Razorpay SDK failed to load. Are you online?"
          FailedPopup(errorMessage);
          handleRedirectToApp(errorMessage)
          setloader(false)
          return;
        }

        const { email, mobile, first_name, last_name } = userData;
        let options = {
          // description: 'Credits towards consultation',
          image: PaymentGateWays.gateway_logo,
          currency: orderDetails.currency,
          key: secret_key,
          amount: orderDetails.amount,
          name: "KSFE Ltd",
          order_id: orderDetails.pg_order_id, //Replace this with an order_id created using Orders API.
          prefill: {
            email: email || "",
            contact: mobile || "",
            name: `${first_name} ${last_name}` || "",
          },
          handler: async (data) => {
            navigate(-1)
            customLogger(`Payment Success`, data);
            if (data.razorpay_payment_id) {
              // handle success
              const {
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
              } = data;
              await UpdatePaymentResponse(
                true,
                orderDetails.transaction_id,
                PaymentGateWay,
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
                "",
                auth
              );
              setpaymentStatusMsg(Constant.paymentsussessMsg);

              // setVisible(true)
              // setnotificationsDetails(prev => ({ ...prev, cart_count: 0 }))
              clearcart()
              setdata({});
              setVisible(false);
              Modals.destroyAll();
              setOpenPaymentStatusModal(true);

              setpaymentSuccess(true);
            } else {
              // .then((data) => {

              //console.log(`Payment Failed:`, error)
              UpdatePaymentResponse(
                false,
                orderDetails.transaction_id,
                PaymentGateWay,
                orderDetails.pg_order_id,
                "",
                "",
                "",
                auth
              );

              setpaymentStatusMsg(data.error.reason.replace("_", " ") | data.error.description
              );

              //setnotificationsDetails(prev => ({ ...prev, cart_count: prev.cart_count - 1 }))
              // setnotificationsDetails(prev => ({ ...prev, cart_count: 0 }))
              clearcart()

              Modals.destroyAll();
              setOpenPaymentStatusModal(true);
              setpaymentSuccess(false);
            }
          },
          modal: {
            ondismiss: function () {
              navigate(-1)
              // setnotificationsDetails((prev) => ({ ...prev, cart_count: 0 }));
              clearcart()
              setVisible(false);
              UpdatePaymentResponse(
                false,
                orderDetails.transaction_id,
                PaymentGateWay,
                orderDetails.pg_order_id,
                "",
                "",
                "",
                auth
              );
              const errorMessage = `Oops! It seems there was an issue processing your payment. Please double-check your payment details and try again`
              handleRedirectToApp(0, errorMessage, errorMessage)

            },
          },
          // theme: { color: themedColors.PrimaryBgColor }
        };

        const RazorpayCheckout = new window.Razorpay(options);
        // paymentObject.open();
        RazorpayCheckout.on("payment.failed", function (response) {
          //navigate(-1)
          UpdatePaymentResponse(
            false,
            orderDetails.transaction_id,
            PaymentGateWay,
            orderDetails.pg_order_id,
            "",
            "",
            "",
            auth
          );

          // setnotificationsDetails(prev => ({ ...prev, cart_count: 0 }))
          clearcart()
          setVisible(false);
          Modals.destroyAll();
          RazorpayCheckout.close()
          // Remove the backdrop manually
          document.querySelector('.razorpay-container').remove();
          setOpenPaymentStatusModal(true);
          setpaymentSuccess(false);
          setpaymentStatusMsg(response.error.description);
        });
        RazorpayCheckout.open();
        handleEnableButton();
      }
      async function IDBIPayment() {
        setloader(true)
        try {
          let container = document.querySelector("#payment-gateways-list");
          const {
            transactionUrl,
            terminalId,
            merchantId,
            encData,
            bankId,
            transaction_id,
          } = orderDetails.gateway_details;
          setResponseData((prev) => ({
            ...prev,
            PaymentGateWay,
            transaction_id,
          }));
          let htmlContent = `<form action=${transactionUrl} method="post" name="server_request" target="_top" id = "form_id" >
              <input type="hidden" name="EncData" id="EncData" value=${encData} />
              <input type="hidden" name="MerchantId" id="MerchantId" value=${merchantId} />
              <input type="hidden" name="TerminalId" id="TerminalId" value=${terminalId} />
              <input type="hidden" name="Version" id="Version" value="1" />
              <button type="submit" class="d-none" id="submit-btn"></button>
             </form>`;
          // Check if the container exists

          if (!container) {
            // If container doesn't exist, create one
            container = document.createElement("div");
            container.id = "container"; // Give it an ID
            document.body.appendChild(container); // Append it to the body
          }
          container.innerHTML += htmlContent;
          // Click the button after adding it to the DOM
          document.querySelector("#submit-btn").click();
        } catch (err) {
          console.log(err);
        }
      }
      async function HDFCPayment() {
        try {
          if (orderDetails.gateway_details?.payment_links) {
            window.location.href =
              orderDetails.gateway_details?.payment_links?.web;
          } else {
            const errorMessage = JSON.parse(
              orderDetails.gateway_details.message
            );
            handleEnableButton();
           
            if (redirect_url) {
              let redirect_to_app = `${redirect_url}?error=You're back in the mobile app. If your payment isn't finished, please make sure to complete it. Need help? Just reach out to support.`;
              FailedPopup(errorMessage?.error_info?.developer_message, () => window.location.href = redirect_to_app);
            } else {
              FailedPopup(errorMessage?.error_info?.developer_message);
            }
          }
        } catch (error) {
          console.error("Error", error);
        }
      }
      async function FEDBankPayment(gateway_details) {

        fedBankPG(gateway_details,callBack)
     
        function callBack(res) {
         
          console.log(res)
        }
      }

      async function CashFreePayment() {
        const cashfree = await load({
          mode: orderDetails?.environment //or environmentproduction
        });
       let checkoutOptions = {
          paymentSessionId: orderDetails.payment_session_id,
          redirectTarget: "_modal",
          // returnUrl: gateway.return_url
        };
      
        cashfree.checkout(checkoutOptions).then(async(result) => {
          if (result.error) {
            const data= getSessionStorage('paymentDetails')
            await UpdatePaymentResponse(
              true,
              data.transaction_id,
              data.PaymentGateWay,
              data.pg_order_id,
              data.payment_session_id,
            );
          }
          if (result.redirect) {
            const data= getSessionStorage('paymentDetails')
            console.log(data);
            await UpdatePaymentResponse(
              true,
              data.transaction_id,
              data.PaymentGateWay,
              data.pg_order_id,
              data.payment_session_id,
            );
          }
          if (result.paymentDetails) {
           const data= getSessionStorage('paymentDetails')
           await UpdatePaymentResponse(
            true,
            data.transaction_id,
            data.PaymentGateWay,
            data.pg_order_id,
            data.payment_session_id,
          );
          }
        }).catch((error)=>{
              console.log('cashfree catch error', error)
        });
      }
    }
  };

  const handleClosePaymentModal = () => {
    handleEnableButton();
    setVisible((prev) => false);
    if (redirect_url) {
      window.location.href = `${redirect_url}?error=You're back in the mobile app. If your payment isn't finished, please make sure to complete it. Need help? Just reach out to support.`;
    }
  };

  // const handleCloseSuccesModal = () => {
  //   setVisible(false)
  //   navigate('/PaymentHistory')
  // }

  // const handleCloseSuccesModal = () => {
  //   setVisible(false)
  //   navigate('/PaymentHistory')
  // }
  const UpdatePaymentResponse = useCallback(
    (
      status,
      transaction_id,
      gateway,
      order_id,
      payment_id,
      signature,
      payment_response,
      accessToken = token
    ) => {
      setloader(true);
      let path = global.processpgresponse;
      let body = {};
      if(gateway==="AXIS_CF"){
        body = {
          transaction_id: transaction_id,
          gateway: gateway,
          payment_response: {
            orderId: transaction_id,
          },
        };
      } else if (status) {
          // checking the response status if true it will return the signature id payments id
          body = {
            transaction_id: transaction_id,
            gateway: gateway,
            payment_response: {
              razorpay_order_id: order_id,
              razorpay_payment_id: payment_id,
              razorpay_signature: signature,
            },
          };
        } else {
          // is it false it will pass the basic details only
          body = {
            transaction_id: transaction_id,
            gateway: gateway,
            payment_response: {
              razorpay_order_id: order_id,
            },
          };
        }
      
      ManageApis("post", path, body, accessToken)
        .then((res) => {
          setloader(false);
          customLogger("payment reponse updated success", res);
          if(gateway==="AXIS_CF"){
            const orderStatus=String(res?.response_data?.data?.order_status)
            switch(orderStatus){
              case "1":
                Modals.destroyAll();
                FailedPopup(defaultTexts?.paymentInProcessMsg,'','Pending')
                clearcart()
  
                setpaymentSuccess(false);
                break
              case "2":
                // handleOpenPaymentResponse()
                setpaymentStatusMsg(Constant.paymentsussessMsg);

                // setVisible(true)
                // setnotificationsDetails(prev => ({ ...prev, cart_count: 0 }))
                clearcart();
                setVisible(false);
                Modals.destroyAll();
                setOpenPaymentStatusModal(true);
  
                setpaymentSuccess(true);
                break
              case "3":
                FailedPopup(defaultTexts?.paymentFailedMsg)
                clearcart();
  
                Modals.destroyAll();
                setOpenPaymentStatusModal(true);
                setpaymentSuccess(false);
                break
                default:
                  console.log("unhandled conditon");//#FIXME 
                  FailedPopup("Something Went Wrong")
                  break
            }
          }
          getNotification(accessToken);
        })
        .catch((err) => {
          setloader(false);
          //console.log('error in payment reponse updated ', res)
        });
    },
    []
  );

  // get cart details
  const getCartDetails = async (authToken = token) => {
    setloader(true);
    setdata({});
    try {
      let response = await ManageApis("get", global.cartDetails, "", authToken);
      ////console.log(response)
      if (response.response_data.status) {
        setloader(false);
        if (response.response_data.data) {
          setdata(response.response_data.data);
          let resData = response.response_data.data.cart_items;
          setcartItems(resData);
          setcartItemsLoan(resData.filter((item) => item.type === "loan"));
          setcartItemsChitty(resData.filter((item) => item.type === "chitty"));
          setcartItemGoldod(resData.filter((item) => item.type === "goldod"));
          setcartItemSuguama(resData.filter((item) => item.type === "sugama"));
          return response.response_data.data;
        }

        ////console.log("data GETCART", response.response_data.data);
      } else {
        setloader(false);
        setnotificationsDetails((prev) => ({ ...prev, cart_count: 0 }));
        clearcart();
        FailedPopup(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBrowserMessageClose = async() =>{
    setInvalidBrowserPopUp(invalidBrowserPopUpInitialValue)
    setMessageShown(true) 
    if (cartItems) {
      await getPaymentgateways(auth);
    } else {
      setOpenPaymentStatusModal(true);
      setpaymentSuccess(false);
      setpaymentStatusMsg(Constant.paymentFailMsg);
    }
  }

  const getPaymentgateways = async (authToken = token) => {
    setloader((prev) => true);
    try {
      const res = await ManageApis("get", global.gatewaylist, "", authToken);
      setloader((prev) => false);
      if (res.response_data.status) {
        if (res.response_data.data.gateways) {
          const activeGateways = res.response_data.data.gateways.filter(gateway => gateway.active == 1 && gateway.display == 1);
          setPaymentGateWays(activeGateways);
          setVisible((prev) => true);
        } else {
          setPaymentGateWays([]);
          setVisible((prev) => false);
          FailedPopup(
            "Due to maintenance activities payment is disabled, Please try after sometime"
          );
        }
      } else {
        FailedPopup(res.response_data.message);
      }
    } catch (err) {
      setloader((prev) => false);
      FailedPopup(`something went Wrong`);
    }
  };

  // remove cart item
  const handleRemoveCart = useCallback((id) => {
    return new Promise((resolve, reject) => {
      setloader(true);
      let path = global.removeCartItem + `?cart_item_id=${id}`;
      ManageApis("delete", path, "", token)
        .then((res) => {
          if (res.response_data.status) {
            setnotificationsDetails((prev) => ({
              ...prev,
              cart_count: prev.cart_count - 1,
            }));
            setloader(false);
            //console.log(res.response_data.message)
            // FailedPopup(res)
            successPopup(res);
            setcartItems(cartItems.filter((itm) => itm._id != id));
            setcartItemsLoan(cartItemsLoan.filter((itm) => itm._id != id));
            setcartItemsChitty(cartItemsChitty.filter((itm) => itm._id != id));
            setcartItemGoldod(cartItemsGoldod.filter((itm) => itm._id != id));
            setcartItemSuguama(cartItemssuguma.filter((itm) => itm._id != id));

            getCartDetails();
            // setdata({})
            //console.log(data)
          } else {
            setloader(false);
            FailedPopup(res);
          }
          resolve();
        })
        .catch((err) => {
          customLogger("error in delete a cart item", err);
          reject();
        });
    });
  });

  const PaymentBackbtn = () => {
    setOpenPaymentStatusModal((prev) => false);
    if (status) {
      window.history.pushState(null, document.title, window.location.pathname);
    } else {
      navigate("/cart");
    }
  };
  // filtering PG by available channel , eg:channel ='web,mobile'
  const handleFilterByChannel = (item) => {

    if (item.channel) {
      // if its webview only return mobile supported channels
      if (redirect_url) {

        return item.channel.split(',').includes('mobile')

      } else {

        return item.channel.split(',').includes('web')

      }

    }
  }
  return (
    <Layout loading={loader || chromeVersionCheckLoader}>
      <PopupModal
        visible={visible}
        handleClose={handleClosePaymentModal}
        title="Select Payment Gateway"
        size="md"
        Backdrop={"static"}
        closeButton={!buttonDisable}
      >
        <div id="payment-gateways-list">
          <Row className="g-3">
            {PaymentGateWays.filter(handleFilterByChannel).map(
              (item) =>
                customLogger("gatewasy list", item) || (
                  <Col xs={4}>
                    <button
                      className="btn bg-white p-2 d-grid shadow_sm1 rounded cursor_pointer w-100 h-100"
                      disabled={buttonDisable}
                      onClick={() =>
                        handleGateWay(item.gatewayId, item.merchantKey)
                      }
                    >
                      <div>
                        <img
                          src={item.gateway_logo}
                          className="h_80x object_fit_contain w-100"
                        />
                      </div>
                      <h6 className="mb-0 pt-2 text-center font_14 fw_600">
                        {item.details}
                      </h6>
                    </button>
                  </Col>
                )
            )}
          </Row>
        </div>
      </PopupModal>

      {OpenPaymentStatusModal && (
        <Modal show={OpenPaymentStatusModal} centered>
          <Modal.Body>
            {paymentSuccess ? (
              <div className="text-center">
                <div>
                  <CheckmarkCircleOutline
                    color={"#00000"}
                    height="50px"
                    width="50px"
                    className="text_master2"
                  />
                </div>
                <div className="font_24 fw_700 mb-2 text_master2">Success</div>
                <div className="font_16 mb-3">
                  {paymentStatusMsg ||Constant.paymentsussessMsg}
                </div>

                <div className="align-items-center d-flex gap-3 justify-content-center">
                  {!sessionData?.redirect_from && (
                    <button
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                      onClick={PaymentBackbtn}
                    >
                      Close
                    </button>
                  )}
                  {sessionData?.redirect_from ? (
                    <a
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                      onClick={() =>
                      (window.location.href = `${sessionData?.redirect_from || redirect_url
                        }?payment_result=1`)
                      }
                    >
                      Go to payment history
                    </a>
                  ) : (
                    <Link
                      to={"/paymenthistory"}
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                    >
                      Go to payment history
                    </Link>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-center">
                <div>
                  <CloseCircleOutline
                    color={"#00000"}
                    height="50px"
                    width="50px"
                    className="text-danger"
                  />
                </div>
                <div className="font_24 fw_700 mb-2 text-danger">Sorry!</div>
                <div className="font_16 mb-3">
                  {Constant.paymentFailMsg}
                </div>
                <div className="align-items-center d-flex gap-3 justify-content-center">
                  {!sessionData?.redirect_from && (
                    <button
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                      onClick={PaymentBackbtn}
                    >
                      Close
                    </button>
                  )}
                  {sessionData?.redirect_from ? (
                    <a
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                      onClick={() =>
                      (window.location.href = `${sessionData?.redirect_from || redirect_url
                        }?payment_result=0&error=${paymentStatusMsg}`)
                      }
                    >
                      Go to payment history
                    </a>
                  ) : (
                    <Link
                      to={"/paymenthistory"}
                      className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                    >
                      Go to payment history
                    </Link>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}

      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <BackBtn title={`Payment List`} />
              <div className="col-12 col-sm-auto pt-3 pt-sm-2">
                <div className="d-flex flex-column flex-sm-row gap-2 gap-sm-3">
                  <Link
                    to={"/"}
                    className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp min_w_unset"
                  >
                    {/* {cartItemsChitty?.length > 0
                      ? `Add More Chits`
                      : `Add Chits`} */}
                      Add More
                  </Link>
                  {/* <Link
                    to={"/myloans"}
                    className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp min_w_unset"
                  >
                    {cartItemsLoan?.length > 0 ? `Add More Loans` : `Add Loans`}
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          {/* only visible is there any data available on cart api */}
          {data?.count ? (
            <div className="p-3 bg-white br_10 shadow_sm">
              {cartItemsChitty.length > 0 && (
                <>
                  <h5 className="font_14 fw_600 mb-0 pb-2 text_master">
                    Chitty
                  </h5>
                  <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle mb-3">
                    <thead className="bg_master text-white">
                      <tr>
                        <th className="fw_500" style={{ width: "60px" }}>
                          Sl.No
                        </th>
                        <th className="fw_500">Scheme Name</th>
                        <th className="fw_500" style={{ width: "250px" }}>
                          Scheme Details (Chittal No.)
                        </th>
                        <th
                          className="fw_500 text-end"
                          style={{ width: "120px" }}
                        >
                          Amount
                        </th>
                        <th className="fw_500" style={{ width: "90px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItemsChitty?.map((item, idx) => (
                        <tr>
                          <td>{idx + 1}</td>
                          <td>{item.cartItemDetails.chit_no}</td>
                          <td>{item.cartItemDetails.chittal_no}</td>
                          <td className="text-end">{item.amount}</td>
                          <td>
                            <PopConfirm onOk={() => handleRemoveCart(item._id)}>
                              <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100">
                                Delete
                              </button>
                            </PopConfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {cartItemsLoan.length > 0 && (
                <>
                  <h5 className="font_14 fw_600 mb-0 pb-2 text_master">Loan</h5>
                  <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle mb-3">
                    <thead className="bg_master text-white">
                      <tr>
                        <th className="fw_500" style={{ width: "60px" }}>
                          Sl.No
                        </th>
                        <th className="fw_500">Loan Id</th>
                        <th className="fw_500" style={{ width: "250px" }}>
                          Type
                        </th>
                        <th
                          className="fw_500 text-end"
                          style={{ width: "120px" }}
                        >
                          Amount
                        </th>
                        <th className="fw_500" style={{ width: "90px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItemsLoan?.map((item, idx) => (
                        <tr>
                          <td>{idx + 1}</td>
                          <td>{item.cartItemDetails.loan_id}</td>
                          <td>
                            {item.cartItemDetails.loan_description || "Loan"}
                          </td>
                          <td className="text-end">{item.amount}</td>
                          <td>
                            <PopConfirm onOk={() => handleRemoveCart(item._id)}>
                              <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100">
                                Delete
                              </button>
                            </PopConfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {cartItemsGoldod.length > 0 && (
                <>
                  <h5 className="font_14 fw_600 mb-0 pb-2 text_master">Gold OD</h5>
                  <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                    <thead className="bg_master text-white">
                      <tr>
                        <th className="fw_500" style={{ width: "60px" }}>
                          Sl.No
                        </th>
                        <th className="fw_500">Gold OD Id</th>
                        <th className="fw_500">Branch ID and Name</th>
                        <th className="fw_500" style={{ width: "250px" }}>
                          Description
                        </th>
                        <th
                          className="fw_500 text-end"
                          style={{ width: "120px" }}
                        >
                          Amount
                        </th>
                        <th className="fw_500" style={{ width: "90px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      {cartItemsGoldod?.map((item, idx) => (
                        <tr>
                          <td>{idx + 1}</td>
                          <td>{item.cartItemDetails.goldod_no}</td>
                          <td>{item.cartItemDetails.branch_id}, {item.cartItemDetails.branch_name}</td>
                          <td>
                            {item.cartItemDetails.goldod_description || "Loan"}
                          </td>
                          <td className="text-end">{item.amount}</td>
                          <td>
                            <PopConfirm onOk={() => handleRemoveCart(item._id)}>
                              <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100">
                                Delete
                              </button>
                            </PopConfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {cartItemssuguma.length > 0 && (
                <>
                  <h5 className="font_14 fw_600 mb-0 pb-2 text_master">Sugama</h5>
                  <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                    <thead className="bg_master text-white">
                      <tr>
                        <th className="fw_500" style={{ width: "60px" }}>
                          Sl.No
                        </th>
                        <th className="fw_500">Sugama No</th>          
                        <th className="fw_500" style={{ width: "250px" }}>Branch ID and Name</th>
                        <th
                          className="fw_500 text-end"
                          style={{ width: "120px" }}
                        >
                          Amount
                        </th>
                        <th className="fw_500" style={{ width: "90px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      {cartItemssuguma?.map((item, idx) => (
                        <tr>
                          <td>{idx + 1}</td>
                          <td>{item.cartItemDetails.sugama_no}</td>
                          <td>{item.cartItemDetails.branch_id}, {item.cartItemDetails.branch_name}</td>
                          <td className="text-end">{item.amount}</td>
                          <td>
                            <PopConfirm onOk={() => handleRemoveCart(item._id)}>
                              <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100">
                                Delete
                              </button>
                            </PopConfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          ) : (
            <NodataScreen
              title="No items found in cart"
            />
          )}
        </div>
        {/* only visible is there any data available on cart api */}
        {data?.count && (
          <div className="bg-white px-3 py-3 br_10 shadow_sm mb-3">
            <div className="align-items-center d-flex justify-content-end flex-wrap">
              <div className="col-12 text-end">
                <span className="pe-2 text-uppercase">Net Amount : </span>
                <span className="text-black word_break fw_600 font_14 d-inline-block min_w_65x">
                  {data?.total_amount}
                </span>
              </div>
              <div className="col-12 pt-2 text-end">
                <button
                  className="btn px_btn btn-master px-4 rounded fw_500 font_12"
                  onClick={() => getPaymentgateways()}
                >
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <PopupModal
            visible={invalidBrowserPopUp.show}
            closeButton={false}
            title={"Info"}
            message={invalidBrowserPopUp.message}
            buttons={[{ text: "Ok", onPress: () => handleBrowserMessageClose(invalidBrowserPopUp?.navigateRequired) }]}
            >
              {invalidBrowserPopUp.message ? invalidBrowserPopUp.message : 
              <div>
              <p>To ensure the best experience while viewing this page, we recommend using Updated Version of Google Chrome as your default web browser.</p>
              <p>Additionally, please make sure that cookies are enabled in your browser settings.</p>
              <p>If you need assistance with setting up Google Chrome or enabling cookies, feel free to reach out!</p>
              </div>
              }
            </PopupModal>
    </Layout>
  );
}
export default PaymentList;

