import { NewspaperOutline } from "react-ionicons"
import Layout from "../../Components/Layout/Layout"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { ContextAPI } from "../../common/Context/ContextApi"
import { ManageApis } from "../../common/APIS/ManageApis"
import { FailedPopup, customLogger, formatDate, getMonthsRange } from "../../common/Utils/Utils"
import BranchListDropdown from '../../Components/BranchList/BranchList'
import InputField from "../../Components/InputField/InputField"
import moment from "moment"
import dayjs from 'dayjs'
import NoData from "../../Components/NoData/NoData"


function AuctionDetails() {
  const [visible, setVisible] = useState(true)
  const [showSelectBranch, setShowSelectBranch] = useState(false)
  const [dates, setdates] = useState({ startDate: "", endDate: "" })
  const [auctionForm, setauctionForm] = useState({
    branch_id: '',
    chitty_reg_no: "",
    from_date: '',
    to_date: '',
  })
  const [branch_name, setbranch_name] = useState("")
  const [List, setList] = useState([])
  const { token , HomeData } = useContext(ContextAPI)
  
 
  const [IsLoading, setIsLoading] = useState(false)
  const [Errors, setErrors] = useState({})
  const [auctions, setauctions] = useState([])
  const [emptyListMessage, setemptyListMessage] = useState('')
  const [checked, setChecked] = useState(HomeData.no_of_customer_accounts)
  // const [checked, setChecked] = useState(true)
  const [isFilterApplied, setisFilterApplied] = useState(false)
  const [chitLoader, setchitLoader] = useState(false)
  
  //for testing without kyc_verified 
  // const [HomeData, setHomeData] = useState({
  //   is_min_kyc_verified: false
  // })


  const CurrentDate = new Date()


    const toggleCheckbox = () => {
      setErrors({});
      if (HomeData.no_of_customer_accounts) {
        setauctions([])
        setChecked(!checked);
        setauctionForm({
          branch_id: '',
          chitty_reg_no: "",
          from_date: dates.startDate,
          to_date: dates.endDate,
        })

      } else {
        FailedPopup('You have no chits to show');
      }
    };

  useEffect(()=>{
    customLogger('-------chitLoader--------->>>>>>>', chitLoader);
  },[chitLoader])
 
    useEffect(
      () => {
        return (() => {
          setemptyListMessage('')
          setauctionForm({
            branch_id: '',
            chitty_reg_no: "",
            from_date: dates.endDate,
            to_date: dates.startDate,
          })
          setErrors({})
          handlePressClearFilter()
          setauctions([])
          setList([])
          // setVisible(true)
        })
      }, [])
  
  useEffect(() => {
    // if(auctionForm.from_date){

    if (!auctionForm.from_date) {
      const dates = getMonthsRange(6, '', CurrentDate) //fetching 6 months date range
      setdates(dates)
      setauctionForm(prev => ({ ...prev, to_date: dates.endDate, from_date: dates.startDate }))
    } else {
     const dates = getMonthsRange(6, auctionForm.from_date) //fetching 6 months date range
      setdates(dates)
      setauctionForm(prev => ({ ...prev, to_date: dates.endDate > CurrentDate ? CurrentDate : dates.endDate }))
    }

    // customLogger('--------------------->', { dates })
    // }

  }, [])
  useEffect(() => {
    if (checked && auctionForm.branch_id) {
      GetmyChitList()
    } else {
      setList([])
      setauctionForm(prev => ({ ...prev, branch_id: '', chitty_reg_no: '', }))
      setbranch_name('')
    }
  }, [checked])



 
  const renderItem = () => {
    return (
      auctions?.map(itm =>
        <tr>
          <td>{itm.chit_id}</td>
          <td>{itm.auction_date}</td>
          <td>{itm.auction_No}</td>
          {auctions.some(item => item.winnerName) &&
            <td>{itm.winnerName}</td>
          }
          <td>{itm.winner}</td>
          <td className="text-end">{itm.foremanCommission}</td>
          <td className="text-end">{itm.auctionVeethapalisa}</td>
          <td className="text-end">{itm.prize_amount_payable}</td>
         
        </tr>
       
      )
    );
  };

  const handleFilter = () => { setVisible(!visible) }

  const myChitList = async (branch_id) => {
    // debugger
    // setchitLoader(true)
    const reqstBody = {
      page: 1,
      size: 30,
      limit: 30,
      branchId: Number(branch_id) || '',
      // chitty_reg_no: filter.chity_no
    }
    let response = await ManageApis('post', global.chitList, reqstBody, token)
    // customLogger({response})
    setchitLoader(false)
    if (response.message == "Success") {
      if (response.response_data.status) {
        let data = []
        customLogger('pagintaion', response.response_data.page > 1)
        // if (response.response_data.data.page > 1) {
        //   data = [...list, ...response.response_data.data.docs]
        // } else {
        // }
        data = response.response_data.data.docs
        setList(data.map(item => ({ key: item._id, value: item._id })))
        customLogger('new arr--------', data)
        // setnextPage(response.response_data.data.nextPage)

      }
    } else {
      setchitLoader(false)
      customLogger(response)
      FailedPopup(response)
    }
  }

  const handleClearBranch = () => {
    customLogger(dates.endDate, dates.startDate)
    setList([])
    setauctionForm(prev => ({ ...prev, branch_id: '', }));
    setbranch_name("")
  }

  const handledateChange = (value) => {
    if (value?.length) {
      setauctionForm((prevState) => ({ ...prevState, from_date: value[0], to_date: value[1] }))
    }
  }
  const handleClear = () => {
    setauctionForm((prevState) => ({ ...prevState, from_date: '', to_date: '' }))
    // setauctionForm((prevState) => ({ ...prevState, from_date: 'dates.startDate', to_date: dates.endDate }))
  }
  const handleOnchange = (text, input) => {
    //setNewRequestData((prevState) => ({ ...prevState, [input]: text }));
    if (input.includes(['from_date', 'to_date'])) {
      setauctionForm((prevState) => ({ ...prevState, [input]: text.toJSON() }))
    } else if (input == 'chitty_reg_no') {
      setauctionForm((prevState) => ({ ...prevState, [input]: text }))
    } else {
      setauctionForm((prevState) => ({ ...prevState, [input]: text }));
    }

    // when the input is branch call the chit list using the branch id
    if (input == 'branch_id') {
      myChitList(text)
      setauctionForm(prevState => ({ ...prevState, chitty_reg_no: '', }));
    }

    //customLogger("first");
  };

  // validate field and set error to state
  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };
  // const handleError = (name, message) => {
	// 	customLogger(name, message)
	// 	if (Errors.length) {
	// 		setErrors(Errors.map(item => item.name === name ? { name: name, message } : item))
	// 	} else
	// 		setErrors(prev => [...prev, { name: name, message }])

	// }

  const validate = async () => {
    let isValid = true;
    
    if (!auctionForm.branch_id) {
      handleError("Please select a Branch", "branch_id");
      isValid = false;
    }
    if (!auctionForm.chitty_reg_no) {
      if(checked){
        handleError("Please select a chit number", "chitty_reg_no");
      }else{
        handleError("Please enter a chit number", "chitty_reg_no");
      }
      isValid = false;
    }
    if(!checked && auctionForm.chitty_reg_no){
      const chittyPattern = /^(\d{1,4})\/(\d{4})\/?$/;
      if (!chittyPattern.test(auctionForm.chitty_reg_no)) {
        handleError("Please enter a valid chit number", "chitty_reg_no");
        isValid = false;
      }
    }


    if (!auctionForm.from_date || !auctionForm.to_date) {
      handleError("Please choose a start and end date", "from_date");
      isValid = false;
    }
    // if (!auctionForm.to_date) {
    //   handleError("Please choose a end date", "to_date");
    //   isValid = false;
    // }
    if (isValid) {
      customLogger("#####   READY TO CALL API  #####");
      submitRequest();
    } else {
      //FailedPopup();
    }
  };

  const handlePressClearFilter = () => {
    setVisible(false)
    handleClearBranch()
    setbranch_name('')
    setShowSelectBranch(false)
    setauctions([])
    setemptyListMessage('')
    setauctionForm({
      branch_id: '',
      chitty_reg_no: "",
      from_date: dates.startDate,
      to_date: dates.endDate,
    })
    handleError("", "from_date")
    
    setisFilterApplied(false)
  }


  const submitRequest = async () => {
    setIsLoading(true);
    setisFilterApplied(true)
    let year = auctionForm.chitty_reg_no.split('/')
    // let chit_no = year[0] + '/' + year[1].slice(0, 4)
    let chit_no = year[0] + '/' + year[1]
    const reqstBody = { ...auctionForm, chitty_reg_no: chit_no, from_date: formatDate(auctionForm.from_date, 'DD-MM-YYYY', 'YYYY-MM-DD'), to_date: formatDate(auctionForm.to_date, 'DD-MM-YYYY', 'YYYY-MM-DD') };

    // customLogger("reqstBody", reqstBody);
    //let { token } = UserData
    let response = await ManageApis("post", global.listAuctionDetails, reqstBody, token)
    //debugger
    if (response.message == 'Success') {
      setIsLoading(false);
      // customLogger("res", response)
      const { status, message, data } = response.response_data || {}
      if (status) {
        setauctions(data.docs)
        setVisible(false)
        setShowSelectBranch(true)
        // showToastWithDuration(message);
        let msg = `No auction details found for this chitty, Please check the chitty number you have entered`
        setemptyListMessage(msg)
      } else {
        // setIsLoading(false);
        customLogger("err", response)
        FailedPopup(response);
        setisFilterApplied(false)
        handlePressClearFilter()
      }
    } else {
      setisFilterApplied(false)
      setIsLoading(false);
      customLogger("err", response)
      FailedPopup(response);
      handlePressClearFilter()
    }

  };

  // get chit list
  const GetmyChitList = useCallback(async () => {
    setIsLoading(true)
    const path = global.listMyChitsForBranch + `?branch_id=${auctionForm.branch_id}`
    let response = await ManageApis('get', path, '', token)
    if (response.message == "Success") {
      // customLogger(response)
      setIsLoading(false)
      if (response.response_data.status) {
        let data = []
        customLogger('pagintaion', response.response_data.page > 1)
        data = response.response_data.data.docs
        if(data){
          setList(data.map(item => ({ key: item.chit_id, value: item.chit_id })))
          customLogger('new arr--------', data)
        }
        
      }
    } else {
      setIsLoading(false)
      customLogger(response)
      FailedPopup(response)
    }
  }, [auctionForm.branch_id])



  	return (
      	<>
          <Layout loading={IsLoading}>
            <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>	
              <div>
                <div className="mb-3">
                  <div className='align-items-center d-flex justify-content-between flex-wrap'>
                    <div className='col-12 col-sm d-flex pt-2'>
                      <NewspaperOutline height="23px" width="23px"  color={''}  className="text_master" />	
                      <h3 className='fw_600 font_18 text_master ms-2 mb-0'>Auction Details</h3>
                    </div>
                    {isFilterApplied ?
                      <div className="col-12 col-sm-auto col-xl-auto mt-3 mt-sm-0">
                        <button 
                          className="btn btn-master-line flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded w-100" 
                          onClick={handlePressClearFilter}>Clear Filter</button>
                      </div> 
                      : ""
                    }
                    
                  </div>
                </div>
                <div className="p-3 bg-white br_10 shadow_sm">
                  <div>
                    
                      
                        <div className="align-items-md-center d-flex mb-3">
                          <input 
                            type="checkbox" 
                            className="font_18 form-check-input me-2 mt-1" 
                            onChange={toggleCheckbox} 
                            checked={checked}
                          />
                          <span onClick={toggleCheckbox} className="font_14 fw_500 mt-1 text_master cursor-pointer">Show my chits auctions</span>
                        </div>
                      
                    
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                            Branch<span className="text-danger">*</span>
                            </label>
                            <select className="form-control rounded font_12">
                              <option>Option1</option>
                            </select> */}
                            <BranchListDropdown
                              isUserBranch={checked}
                              isRequied 
                              selectedItem={(text) => handleOnchange(text, "branch_id")}
                              label={'Branch'}
                              errorMessage={Errors.branch_id}
                              seterrorMessage={handleError}
                              onChange={(text) => handleOnchange(text, "branch_id")}
                              onFocus={() => handleError(null, "branch_id")}
                              onClear={handleClearBranch}
                              textValue={branch_name}
                              selectedItemString={(param) => setbranch_name(param)}
                              value={auctionForm.branch_id}
                            />
                        </div>

                        
                        

                        <div className="col-12 col-md-6 col-lg-4 mb-0">
                            {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                            Chit<span className="text-danger">*</span>
                            </label>
                            <select className="form-control rounded font_12">
                              <option>Option1</option>
                            </select> */}
                            {checked ?
                          
                              <InputField 
                                required
                                label={'Chit No'} 
                                type={'select'}  
                                onchange={(text) => handleOnchange(text, "chitty_reg_no")}
                                name={'chitty_reg_no'}
                                onfocus={() => handleError(null, "chitty_reg_no")}
                                errorMessage={Errors.chitty_reg_no}
                                seterrorMessage={handleError}
                                options={List} 
                                value={auctionForm.chitty_reg_no}
                              />
                              :
                              
                              <InputField 
                                required
                                label={'Chit No'} 
                                type={'text'}  
                                maxLen={9}
                                onchange={(text) => handleOnchange(text, "chitty_reg_no")}
                                name={'chitty_reg_no'}
                                onfocus={() => handleError(null, "chitty_reg_no")}
                                errorMessage={Errors.chitty_reg_no}
                                seterrorMessage={handleError}
                                value={auctionForm.chitty_reg_no}
                                helperText={"Sample Chit number format : 88/2021/"}
                              />
                          }
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                            From Date<span className="text-danger">*</span>
                            </label>
                            <input className="form-control rounded font_12"/> */}
                            <InputField 
                              marginBottom 
                              label={'From & To date'} 
                              type={'date-range'} 
                              onchange={handledateChange} 
                              onClear={handleClear} 
                              onselect={() => handleError(null, "from_date")}
                              value={[auctionForm.from_date, auctionForm.to_date]} 
                              disabledDate={(current) => current.isAfter(dayjs())}
                              errorMessage={Errors.from_date}
                              seterrorMessage={handleError}
                            />

                            {/* <InputField
                              type={'date'}
                              label={"From Date"}
                              name={'start_date'}
                              onchange={(param) => handleOnchange(param, 'from_date')} 
                              value={auctionForm.from_date} 
                              maxDate={CurrentDate} 
                              errorMessage={Errors.from_date}
                              seterrorMessage={handleError}
                            /> */}
                        </div>
                        
                        <div className="col-12 d-flex justify-content-end">
                          <button className="btn btn-master flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded" onClick={validate}>Submit</button>
                        </div>
                      </div>
                   
                    
                  </div>
                  {
                    auctions.length ?
                      <div className="mt-3 table_type02 table-responsive custom_scroll1">
                        <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                          <thead className="bg_master text-white">
                            <tr>
                              <th className="fw_500">Chit No</th>
                              <th className="fw_500" style={{minWidth:100}}>Auction Date</th>
                            <th className="fw_500">Auction No</th>
                            {auctions.some(item => item.winnerName) &&
             <th className="fw_500">Winner Name</th>
          }
                             
                              <th className="fw_500">Winner Chital No</th>
                              <th className="fw_500 text-end">Foreman Commission</th>
                              <th className="fw_500 text-end">Auction Veethapalisa</th>
                              <th className="fw_500 text-end">Prize Money</th>
                            
                            </tr>

                            
                          </thead>
                          
                          <tbody>
                          {
                            renderItem()
                          }
                            {/* <tr>
                              <td>40/2022/A/1</td>
                              <td>09-08-2023</td>
                              <td>10</td>
                              <td>5000</td>
                              <td>8100</td>
                              <td>86900</td>
                              <td>Shameer L</td>
                              <td>27</td>
                            </tr> */}
                            
                            
                            
                            
                          </tbody>
                        </table>
                      </div>
                    :
                    emptyListMessage &&
                    <NoData title={emptyListMessage}/>
                   
                  }
                  
                </div>
              </div>
            </div>
          </Layout>
          
      	</>
  	)
}
export default AuctionDetails