import NoData from "./NoData/NoData";

export const CustomTable = ({ data,tableData }) => (
    <div className="p-3 bg-white br_10 shadow_sm">
        {data?.length ? (
          <div className="table_type02 table-responsive custom_scroll1">
            <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
              <thead className="bg_master text-white">
                <tr>
                  {tableData.map((col, index) => (
                    <th key={index} className="fw_500" style={col.headstyle}>
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {tableData.map((col, idx) => (
                       <td key={idx} style={typeof col.style === "function" ? col.style(item) : col.style}>
                       {col.getValue(item, index)}
                     </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <NoData title="No data to display" />
        )}
      </div>
  );