import { NewspaperOutline } from "react-ionicons";
import PopupModal from "../../Components/PopupModal";
import Layout from "../../Components/Layout/Layout";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import {
  FailedPopup,
  JSONStringify,
  customLogger,
  dataTableInitialLoader,
  formatDate,
  getMonthsRange,
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from "../../common/Utils/Utils";
import { ManageApis } from "../../common/APIS/ManageApis";
import DataTable from "../../Components/DataTable/DataTable";
import { DatePicker, Skeleton, Space } from "@arco-design/web-react";
import InputField from "../../Components/InputField/InputField";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { IconDownload } from "@arco-design/web-react/icon";
import { Button } from "@arco-design/web-react";
import { Spin } from "@arco-design/web-react";

import dayjs from "dayjs";

function PaymentHistory() {
  const dates = getMonthsRange();
  const { token } = useContext(ContextAPI);
  const [visible, setVisible] = useState(false);
  // const [startDate, setStartDate] = useState(dates.startDate);
  // const [EndDate, setEndDate] = useState(dates.endDate);
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [loader, setloader] = useState(false);

  const [data, setdata] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totolPages, settotolPages] = useState(0);
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [transaction_id, settransaction_id] = useState("");
  const [orderIdForDownload, setOrderIdForDownload] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  useEffect(() => {
    if (startDate && EndDate) {
      setcurrentPage(1);
      getPaymentHistory();
    } else {
      setStartDate(
        getSessionStorage("StartDate")
          ? getSessionStorage("StartDate")
          : dates.startDate
      );
      setEndDate(
        getSessionStorage("EndDate")
          ? getSessionStorage("EndDate")
          : dates.endDate
      );
    }
  }, [startDate, EndDate]);
  // get payment history details
  const getPaymentHistory = useCallback(
    async (pageNo) => {
      setloader(true);
      setdata(dataTableInitialLoader(TableColumn));
      const reqstBody = {
        page: pageNo || 1,
        size: 10,
        date_from: formatDate(
          getSessionStorage("StartDate")
            ? getSessionStorage("StartDate")
            : dates.startDate,
          "DD-MM-YYYY",
          "YYYY-MM-DD"
        ),
        date_to: formatDate(
          getSessionStorage("EndDate")
            ? getSessionStorage("EndDate")
            : dates.endDate,
          "DD-MM-YYYY",
          "YYYY-MM-DD"
        ),
      };
      customLogger({ token });
      try {
        let response = await ManageApis(
          "post",
          global.paymentHistory,
          reqstBody,
          token
        );
        if (response.message == "Success") {
          customLogger(("res", response));
          setloader(false);
          if (response.response_data.status) {
            setdata(response.response_data.data.docs);
            settotolPages(response.response_data.data.total);
          } else {
            setdata(response.response_data.data.docs);
          }
        } else {
          setloader(false);
          customLogger("err", response);
          FailedPopup(response);
          setdata([]);
        }
      } catch (error) {
        setloader(false);
        console.log("Error fetching payment history", error);
        FailedPopup("Error fetching payment history,Please try again later");
        setdata([]);
      }
    },
    [startDate, EndDate]
  );

  // modal vissilbel
  const handleInfoIconPress = (value) => {
    setVisible(true);
    setOrderStatus(value.order_status);
    settransaction_id(value.transaction_id);
    getPaymentDetails(value._id);
  };
  const getPaymentDetails = useCallback((id) => {
    setOrderIdForDownload(id);
    let path = global.getTransactionDetails + `?order_id=${id}`;
    ManageApis("get", path, "", token).then((res) => {
      if (res.response_data.status) {
        setpaymentDetails(res.response_data.data.order_details);
      } else {
        FailedPopup(res);
      }
    });
  }, []);

  const TableColumn = [
    // { title: 'Sl.No', dataIndex: 'slno', render: (e, v, idx) => <div>{`${idx + 1}`}</div> },

    { title: "Transaction ID", dataIndex: "transaction_id" },
    { title: "Payment Gateway", dataIndex: "payment_gateway" },
    {
      title: "Payment Date",
      dataIndex: "paid_at",
      headerCellStyle: {
        minWidth: "150px",
      },
    },
    {
      title: "Status",
      dataIndex: "order_status",
      render: (v, item) =>
        loader ? (
          <Skeleton text={{ rows: 1, width: 100 }} />
        ) : (
          <span
            className={`badge badge-warning ${
              item?.order_status && item?.order_status?.includes("Success")
                ? "badge_success_soft"
                : "badge_danger_soft"
            }  font_12 fw_500 rounded-pill`}
          >
            {item?.order_status}
          </span>
        ),
    },
    { title: "Amount", dataIndex: "total_amount", align: "right" },
    {
      title: "Action",
      dataIndex: "action",
      render: (v, rec) =>
        loader ? (
          <Skeleton text={{ rows: 1, width: 100 }} />
        ) : (
          <div className="d-flex justify-content-end">
            <>
              <button
                className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp"
                onClick={() => handleInfoIconPress(rec)}
              >
                More
              </button>
              {/* <LoadingButton type={'text'} disabled={viewBtnLoader} handleClick={() => handleCallBase64(rec, rec.modelId, modelDetails)} BtnText={'View'} icon={<IconEye />} Loading={viewBtnLoader === rec.id} /> */}
            </>
          </div>
        ),
    },
  ];

  const handleChangeTable = useCallback(({ current }) => {
    getPaymentHistory(current);
    setcurrentPage(current);
  }, []);

  const newPaymentDetails = useMemo(() => {
    return paymentDetails.map((item) => ({
      items: [
        // { key: 'Scheme Name', value: item.type },
        {
          key: "Scheme Name",
          value:
            item.type === "Loan" ? item.type || item.description : item.type,
        },
        {
          key: item.type === "Chitty" ? "Scheme Details" : "Loan Account No",
          value:
            item.type === "Chitty"
              ? `${item.chit_no} - ${item.chittal_no}`
              : item.type === "Gold OD"
                ? item.goldod_no
                : item.type === "Sugama"
                  ? item.sugama_no
                  : item.loan_no,
        },
        {
          key: "Branch Name",
          value: `${item.branch_id ? `${item.branch_id},` : ``}${
            item.branch_name
          }`,
        },
        { key: "Sync Status", value: item.syncStatus },
        { key: "Amount", value: item.amount },
      ],
    }));
  }, [paymentDetails]);

  const handledateChange = (value) => {
    if (value?.length) {
      setSessionStorage("StartDate", JSONStringify(value[0]));
      setSessionStorage("EndDate", JSONStringify(value[1]));
      setStartDate(getSessionStorage("StartDate"));
      setEndDate(getSessionStorage("EndDate"));
    }
  };

  const handleClear = () => {
    removeSessionStorage("StartDate");
    removeSessionStorage("EndDate");
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    getPaymentHistory();
  };
  const downloadPDFFile = async (fileContent, fileName) => {
    try {
      // Decode the base64 content to a binary string
      const byteCharacters = atob(fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob from the binary data
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Save the file using FileSaver.js or native APIs
      saveAs(blob, `${fileName}.pdf`);

      console.log("PDF file downloaded successfully!");
    } catch (error) {
      console.error("Error downloading PDF file:", error);
      alert(`Failed to download PDF file: ${error.message}`);
    }
  };
  const handlePrintReciept = async (transId) => {
    try {
      setloader((prev) => true);
      // setVisible(false)
      let reqstBody = {
        order_id: `${transId}`,
      };
      // const { token } = UserData
      let path = global.getTransactionDetailsPdf;
      let response = await ManageApis("post", path, reqstBody, token);
      if (response.message == "Success") {
        //console.log("res", response)
        let fileName = `PaymentHistory-${transId}`;
        let data_ = response.response_data.data;
        downloadPDFFile(data_, fileName);
        // setVisible(false)
      } else {
        // await disableLoader()
        FailedPopup(response);
      }
    } catch (error) {
      FailedPopup(error);
    } finally {
      setloader((prev) => false);
    }
  };
  return (
    <Layout>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <div className="col-12 col-sm-5 d-flex pt-2">
                <NewspaperOutline
                  height="23px"
                  width="23px"
                  color={""}
                  className="text_master"
                />
                <h3 className="fw_600 font_18 text_master ms-2 mb-0">
                  Payment History
                </h3>
              </div>
              <div className="col-12 col-sm-7 col-lg-4 col-xl-auto">
                <div className="d-flex gap-2 p-2 bg-white rounded mt-2">
                  {/* <RangePicker style={{ width: 500 }} mode='date'  /> */}

                  <InputField
                    marginBottom
                    label={"Choose dates"}
                    type={"date-range"}
                    onchange={handledateChange}
                    onClear={handleClear}
                    value={[startDate, EndDate]}
                    disabledDate={(current) => current.isAfter(dayjs())}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="p-3 bg-white br_10 shadow_sm">
            {data.length > 0 ? (
              <div className="arco_table1 table-responsive custom_scroll1">
                <DataTable
                  columnData={TableColumn}
                  rowData={data}
                  onChangeTable={handleChangeTable}
                  total={totolPages}
                  current={currentPage}
                />
              </div>
            ) : (
              <div className="text-center d-grid py-5 text_gray7">
                <i className="far fa-file-alt font_18"></i>
                <p className="mb-0 mt-2">No History Found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {visible && (
        // <Spin loading={loader} size="large" tip="Loading...">
        <PopupModal
          title="Payment Details"
          size="xl"
          handleClose={() => setVisible(false)}
          visible={visible}
          loader={loader}
        >
          <div className="mt-0">
            <div className="d-flex justify-content-end mb-2">
              {/* { orderStatus==='Success' &&
           ( <> Download <Button
                // type="primary"
                icon={!loader && <IconDownload />} // Show icon only when not loading
                disabled={loader} // Disable button during loading
                onClick={() => handlePrintReciept(orderIdForDownload)}
              >
                {loader && <Spin size="small" /> }
              </Button> </>)} */}
              {orderStatus === "Success" && (
                <>
                  <Button
                    style={{
                      backgroundColor: "transparent", // Removes the background color
                      border: "none", // Removes the border
                      display: "flex", // Flexbox for aligning text and icon
                      alignItems: "center", // Centers items vertically
                      gap: "8px", // Adds spacing between the text and the icon
                      padding: 0, // Removes extra padding
                    }}
                    icon={!loader && <IconDownload />} // Show icon only when not loading
                    disabled={loader} // Disable button during loading
                    onClick={() => handlePrintReciept(orderIdForDownload)}
                  >
                    {loader ?<>  <Spin size="small" style={{color:"black !important"}}/>Download</>  : "Download"}
                  </Button>
                </>
              )}
            </div>
            <div className="bg_gray2 p-2 rounded">
              <div className="row">
                <div className="col-12 col-sm-auto text_gray7">
                  Transaction ID
                </div>
                <div className="col-12 col-sm-auto pt-1 pt-sm-0">
                  <div className="d-flex">
                    <span className="pe-1 d-none d-sm-block">:</span>
                    <span className="text-black word_break fw_600">
                      {transaction_id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="pt-3 pb-1">
                <div className="table_type02 table-responsive custom_scroll1">
                  <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                    <thead className="bg_master text-white">
                      <tr>
                        <th className="fw_500">Scheme Name</th>
                        <th className="fw_500" style={{ minWidth: "159px" }}>
                        Chitty Details/ Account No
                          {/* {global.isGoldODEnabled && "Gold OD Account No"} */}
                        </th>
                        <th className="fw_500">Branch ID and Name</th>
                        <th className="fw_500">Sync Status</th>
                        <th className="fw_500 text-end">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newPaymentDetails.map((itm) => (
                        <tr>
                          {itm.items.map((item) => (
                            <td
                              className={item.key == "Amount" ? "text-end" : ""}
                            >
                              {item.value}{" "}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </PopupModal>
        // </Spin>
      )}
    </Layout>
  );
}
export default PaymentHistory;
