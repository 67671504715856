import React, { useContext } from "react";
import { Checkbox } from "@arco-design/web-react";
import { ManageApis } from "../../../common/APIS/ManageApis";
import { ContextAPI } from "../../../common/Context/ContextApi";
import GeneralButton from "../../../Components/Buttons/GeneralButton";
import { GoldOdConstants } from "../../../constant";
import KeyValue from "../../../Components/KeyValue/KeyValue";
import { getGoldODBankVerificationStatusMessage } from "../../../common/Utils/Utils";

export default function ODPendingSection({
  isChecked,
  setIsChecked,
  setModalProps,
  bankAccountNo,
  AccountDetails,
  getReqBodyAndPath,
  isVerificationPending
}) {
  const { token } = useContext(ContextAPI);

  const verifyDetails = [
    {
      key: "Account Holder Name",
      value: AccountDetails?.bank_details?.bene_name,
    },
    { key: "Bank Name", value: AccountDetails?.bank_details?.bank_name },
    { key: "Branch", value: AccountDetails?.bank_details?.bank_branch_name },
    { key: "IFS Code", value: AccountDetails?.bank_details?.bene_ifsc_code },
    { key: "Bank A/C No:", value: bankAccountNo },
  ];

  const handleValidateAccount = async (type) => {
    try {
      const { reqBody, path } = getReqBodyAndPath(type);
      const response = await ManageApis("post", path, reqBody, token);
      const { response_data, status_code } = response;
      const { status, errors, message } = response_data;
      switch (status_code) {
        case 200:
          setModalProps({
            open: true,
            title: status ? `Success` : `Failure`,
            message: message,
          });
          break;
        default:
          setModalProps({
            open: true,
            title: `Failure`,
            message: `${message}`,
          });
          break;
      }
    } catch (error) {
      setModalProps({
        open: true,
        title: `Failure`,
        message: `error : ${error?.message}`,
      });
      console.log("error -- ", error);
    }
  };
  return (
    <div>
      <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
        <p style={{ fontWeight: 600 }}>
          {!isVerificationPending ? GoldOdConstants.BankDetailsConfirmationLabel : "Bank Details"}
        </p>
        <div className={`${!isVerificationPending && `mb-2`}`}>
          {verifyDetails.map((item) => (
            <KeyValue item={item} />
          ))}
        </div>
        {AccountDetails?.bank_details?.status===4 && <div class="mb-3 text-danger">{GoldOdConstants.AlreadyRejected}</div>}
        {
          !isVerificationPending ?
          <>
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked((prev) => !prev)}
            disabled={AccountDetails?.bank_details?.status===4}
          >
            {GoldOdConstants.CheckBoxConfirmationLabel}
          </Checkbox>
          <div className="d-md-flex justify-content-end mb-2">
            <div className="d-flex gap-1">
              <GeneralButton onClick={()=>handleValidateAccount("reject")} title={"Reject"} type="btn-master-line" disabled={AccountDetails?.bank_details?.status===4}/>
              <GeneralButton onClick={()=>handleValidateAccount("accept")} title={"Accept"} disabled={AccountDetails?.bank_details?.status===4 || !isChecked}/>
            </div>
          </div>
          </>
          :
          <div>
            <KeyValue item={{key:"Status",value:getGoldODBankVerificationStatusMessage(isVerificationPending)}} textStyle="text-danger"/>
          </div>
        }
      </div>
    </div>
  );
}
