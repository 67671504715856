


import React, { useCallback, useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
// import { useNavigation } from "react-router-dom";
import { ManageApis } from "../../common/APIS/ManageApis";
import InputField from "../../Components/InputField/InputField";
import BranchListDropdown from '../../Components/BranchList/BranchList'
import { FailedPopup, customLogger, generateNonce, successPopup } from "../../common/Utils/Utils";
import { Spin } from "@arco-design/web-react";


function AddSchemeRequestTab(props) {
  const {index,setVisible,refreshSchemeTab} = props
  const { token, HomeData } = useContext(ContextAPI);
  const [isSModalLoading, setIsSModalLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [NewRequestData, setNewRequestData] = useState({
    scheme: 1,
    branch: "",
    chitNo: "",
    subject: "Scheme Request",
    requestType: 2,
    request: "",
    request_to: 1
  });

  const [BranchList, setBranchList] = useState([]);
  const [Scheme, setScheme] = useState([]);
  const [RequestTo, setRequestTo] = useState([])
  // const navigation = useNavigation();
  const [List, setList] = useState([])
  const [previousReqstBody, setpreviousReqstBody] = useState('')
  const [nonce, setnonce] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [apiParam, setapiParam] = useState('')

  useEffect(() => {
    customLogger("index", index);
    // For tabwise api calls 
    if (index == 1) {
      ListBranch();
      ListSchemes();
      ListRequestTo()
    }
  }, []);

  useEffect(() => {
    let data = NewRequestData.scheme == 1 ? 'scheme=chitty' : 'scheme=loan'
    setapiParam(data)
  }, [NewRequestData.scheme])

  // list RequestTo dropdown
  const ListRequestTo = async () => {
    setIsSModalLoading(true)
    //let { token } = UserData
    const path = global.getRequestOwner
    let response = await ManageApis('get', path, "", token)
    if (response.message == 'Success') {
      // customLogger("RequestTo", response.response_data.data.result)

      let temArray = response.response_data.data.result
      let temArray1 = []
      Object.keys(temArray).forEach(key => (temArray1.push({
        'key': temArray[key].request_to, 'value': temArray[key].type
      })))
      setRequestTo(temArray1)
      // customLogger('new request to', temArray1);
      setIsSModalLoading(false)
    } else {
      FailedPopup(response)
      setIsSModalLoading(false)
    }
  }

  // list Branch details
  const ListBranch = async () => {
    setIsSModalLoading(true);
    //let { token } = UserData;
    const path = global.listBranch + "?customerBranches=true";
    let response = await ManageApis("get", path, "", token);
    if (response.message == "Success") {
      // customLogger("customerBranches", response.response_data.data.docs);

      let temArray = response.response_data.data.docs;
      let temArray1 = [];
      Object.keys(temArray).forEach((key) =>
        temArray1.push({
          key: temArray[key].branch_name,
          value: temArray[key].branch_id,
        })
      );
      setBranchList(temArray1);
      // customLogger("customerBranches object", temArray1);
      setIsSModalLoading(false);
    } else {
      setIsSModalLoading(false);
      FailedPopup(response);
    }
  };
  // list Schemes
  const ListSchemes = async () => {
    setIsSModalLoading(true);
    //let { token } = UserData;
    const path = global.listschemes;
    let response = await ManageApis("get", path, "", token);
    if (response.message == "Success") {
      //customLogger("res12", response.response_data.data.docs)
      let temArray = response.response_data.data.docs;
      let newArr = temArray.filter((item) => item.scheme_id == 2);
      let subschemeArray = newArr[0].sub_schemes;
      let temArray1 = [];
      Object.keys(subschemeArray).forEach((key) =>
        temArray1.push({
          key: subschemeArray[key].sub_scheme_name,
          value: subschemeArray[key].sub_scheme_id,
        })
      );
      setScheme(temArray1);
      customLogger("ListSchemes", temArray1);
      setIsSModalLoading(false);
    } else {
      setIsSModalLoading(false);
      FailedPopup(response);
    }
  };



  // handle change  set all fields value to object state
  const handleOnchange = (text, input) => {
    //setNewRequestData((prevState) => ({ ...prevState, [input]: text }));
    if (input == "scheme") {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text}));
      setNewRequestData((prevState) => ({ ...prevState, chitNo: "" }));
      setNewRequestData((prevState) => ({ ...prevState, branch: "" }));
      setErrors({})
      setList([])
    }else if (input == "branch") {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text}));
      setNewRequestData((prevState) => ({ ...prevState, chitNo: "" }));
      setList([])
    } else if (input == "request") {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text.slice(0, 250) }));
    }else if (input == "chitNo") {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text }));
    } else {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text }));
    }
    //customLogger("first");
  };

  // validate field and set error to state
  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };
  const handleButtonClick = () => {
    // Disable the button
    setIsButtonDisabled(true);
    // Enable the button after 5 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };

  // handle  verify
  const handleSubmitPress = async () => {
    // call validate fn and is true call backend api
    handleButtonClick()
    //debugger
    validate();
  };

  const validate = async () => {
    let isValid = true;
    if (!NewRequestData.scheme) {
      handleError("Please select scheme", "scheme");
      isValid = false;
    }
    if (!NewRequestData.request_to) {
      handleError("Please select Request to", "request_to");
      isValid = false;
    }
    if (NewRequestData.request_to == 1) {
      if (!NewRequestData.branch) {
        customLogger("branch ::",NewRequestData.branch);
        handleError("Please select a Branch", "branch");
        isValid = false;
      }
    }

    if (!NewRequestData.chitNo) { 
      handleError(NewRequestData.scheme == 1? "Chit Number is required" : "Loan Account Number is required", "chitNo");
      isValid = false;
    }
    
    
    // if (!NewRequestData.subject) {
    //   handleError("Please select Subject", "subject");
    //   isValid = false;
    // }
    if (!NewRequestData.request) {
      handleError("Please enter Request", "request");
      isValid = false;
    }

    if (isValid) {
      customLogger("#####   READY TO CALL API  #####");
      submitRequest();
      
    } else {
      FailedPopup("Fill all mandatory fields");
    }
  };

  const submitRequest = async () => {
    setIsSModalLoading(true);
    const reqstBody = {
      request_to: NewRequestData.request_to,
      subject: NewRequestData.subject,
      scheme_type: NewRequestData.scheme,
      subscribed_scheme: NewRequestData.chitNo,
      request_type: NewRequestData.requestType,
      comment_text: NewRequestData.request,
    };
    let newreqstBody = (NewRequestData.request_to == 1) ? { ...reqstBody, branch_id: NewRequestData.branch } : reqstBody
    // customLogger("reqstBody", newreqstBody);
    let tempNonce = ''
    if (newreqstBody) {
      if (JSON.stringify(newreqstBody) !== previousReqstBody) {
        tempNonce = generateNonce(32)
        setnonce(tempNonce)
      }
    }

    newreqstBody = { ...newreqstBody, nonce: tempNonce }

    //let { token } = UserData
    let response = await ManageApis("post", global.createRequest, newreqstBody, token)
    if (response.message == 'Success') {
      setpreviousReqstBody(JSON.stringify(newreqstBody))
      setIsSModalLoading(false);
      successPopup(response.response_data.message);
      refreshSchemeTab()
      setVisible(false)
      // customLogger("res", response)
      //navigate("My Request")
    } else {
      setIsSModalLoading(false);
      customLogger("err", response)
      FailedPopup(response);
    }

  };

  useEffect(() => {
    if (NewRequestData.request_to) {
      setNewRequestData(prev => ({ ...prev, branch: '' }))
    }
  }, [NewRequestData.request_to])

  useEffect(() => {
    if (NewRequestData.branch) {
      myChitList()
    }
  }, [NewRequestData.branch])

  // get chit list
  const myChitList = useCallback(async () => {
    setIsSModalLoading(true)
   // const path = global.listMyChitsForBranch + `?branch_id=${NewRequestData.branch}`
    let path = NewRequestData.scheme == 1 ? global.listMyChitsForBranch : global.loanList
    path += NewRequestData.scheme == 1 ? `?branch_id=${NewRequestData.branch}` : ''
    let method = NewRequestData.scheme == 1 ? 'get' : 'post'
    let param=''
    if (NewRequestData.scheme == 2) {
      param = {
        "page": 1,
        "size": 50,
        "limit": 100,
        "branchId": Number(NewRequestData.branch)
      }
    }
    let response = await ManageApis(method, path, param, token)
    if (response.message == "Success") {
      // customLogger(response)
      setIsSModalLoading(false)
      if (response.response_data.status) {
        let data = []
        // customLogger('pagintaion', response.response_data.page > 1)
        // if (response.response_data.data.page > 1) {
        //   data = [...list, ...response.response_data.data.docs]
        // } else {
        // }
        data = response.response_data.data.docs
        // setList(data.map(item => ({ key: item.chit_id, value: item._id })))
        //setList(data.map(item => ({ key: item.chit_id, value: item.chit_id })))

        if (NewRequestData.scheme == 1) {
          setList(data.map(item => ({ key: item.chit_id, value: item.chit_id })))
        } else setList(data.map(item => ({ key: `${item?.loan_description} - ${item?.account_no}`, value: item.account_no })))
        // customLogger('new arr--------', data)
        // setnextPage(response.response_data.data.nextPage)

      }
    } else {
      setIsSModalLoading(false)
      // customLogger(response)
      FailedPopup(response)
    }
  }, [NewRequestData.branch])

  const handleClearBranch = () => {
    setNewRequestData(prev => ({ ...prev, branch: '', chitNo: "" }));
    setList([])
  }


  return (
  <>
    {/* <div>
      <div className="mb-3">
          <label className="mb-0 pb_2x text_master fw_500 font_13">
          Scheme<span className="text-danger">*</span>
          </label>
          <select className="form-control rounded font_12">
            <option>Option1</option>
          </select>
      </div>      
      <div className="mb-3">
          <label className="mb-0 pb_2x text_master fw_500 font_13">
          Request To<span className="text-danger">*</span>
          </label>
          <select className="form-control rounded font_12">
            <option>Option1</option>
          </select>
      </div>
      <div className="mb-3">
          <label className="mb-0 pb_2x text_master fw_500 font_13">
          Branch<span className="text-danger">*</span>
          </label>
          <select className="form-control rounded font_12">
            <option>Option1</option>
          </select>
      </div>
      <div className="mb-3">
          <label className="mb-0 pb_2x text_master fw_500 font_13">
          Chit No<span className="text-danger">*</span>
          </label>
          <select className="form-control rounded font_12">
            <option>Option1</option>
          </select>
      </div>
      <div className="mb-3">
          <label className="mb-0 pb_2x text_master fw_500 font_13">
          Request
          </label>
          <textarea className="form-control rounded font_12"></textarea>
          <div className="font_10 fw_300 pt-1 text_gray7">Max allowed characters 250</div>
      </div>
      <div>
        <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100">Submit</button>
      </div>
    </div> */}
    


    <Spin dot loading={isSModalLoading}>
    {!HomeData.no_of_customer_accounts ?
      <div>Please verify your PAN to use this option</div>
      :
      <div>
        <div className="mb-3">
          <InputField 
            required
            //disabled
            label={'Scheme'} 
            type={'select'}  
            onchange={(text) => handleOnchange(text, "scheme")}
            onfocus={() => handleError(null, "scheme")}
            errorMessage={errors?.scheme}
            seterrorMessage={handleError}
            options={Scheme} 
            value={NewRequestData.scheme}
          />
        </div>
        <div className="mb-3">
          <InputField 
            required
            disabled
            label={'Request'} 
            type={'select'}  
            onchange={(text) => handleOnchange(text, "request_to")}
            onfocus={() => handleError(null, "request_to")}
            errorMessage={errors?.request_to}
            seterrorMessage={handleError}
            options={RequestTo} 
            value={NewRequestData.request_to}
          />

        </div>

        {NewRequestData.request_to == 1 ?
          <div className="mb-3">
            <BranchListDropdown
                isUserBranch
                apiParam={apiParam}
                selectedItem={(text) => handleOnchange(text, "branch")}
                label={'BRANCH'}
                isRequied 
                errorMessage={errors?.branch}
                seterrorMessage={handleError}
                onChange={(text) => handleOnchange(text, "branch")}
                onFocus={() => handleError(null, "branch")}
                onClear={handleClearBranch}
                value={NewRequestData.branch}
              />
            </div>
            : ""
        }
        <div className="mb-3">
          <InputField 
            required
            label={NewRequestData.scheme == 1 ? "Chit No" : "Loan Account No"}
            type={'select'}  
            onchange={(text) => handleOnchange(text, "chitNo")}
            onfocus={() => handleError(null, "chitNo")}
            errorMessage={errors?.chitNo}
            seterrorMessage={handleError}
            options={List} 
            value={NewRequestData.chitNo}
          />

        </div>
        
        <div className="mb-3">
          <InputField 
            required
            errorMessage={errors?.request}
            seterrorMessage={handleError}
            label={"Request"}
            placeholder={'Enter Here'} 
            type={'textarea'}  
            onchange={(text) => handleOnchange(text, "request")}
            onfocus={() => handleError(null, "request")}
            rows={5} 
            value={NewRequestData.request}
            helperText={"Max allowed characters 250"}
          />


        </div>
        <div>
          <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100"
            disabled={isButtonDisabled}
            onClick={isButtonDisabled? ()=>{return} : handleSubmitPress}
           >{isButtonDisabled ? 'Submitting...' : 'Submit'}</button>
        </div>
      </div>
    }
    </Spin>

  </>
  )
  
}

export default  AddSchemeRequestTab