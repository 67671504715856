import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import DataTable from "../../Components/DataTable/DataTable";
import { Skeleton } from "@arco-design/web-react";
import {useNavigate } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { ContextAPI } from "../../common/Context/ContextApi";
import { FailedPopup, replaceUnderScoreAndCapitialize } from "../../common/Utils/Utils";
import { ManageApis } from "../../common/APIS/ManageApis";
import NoData from "../../Components/NoData/NoData";

function MySugamaAccount() {
  const Navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const { token } = useContext(ContextAPI);
  const [sugumaAcc, setSugumaAcc] = useState()
  const [currentPage, setcurrentPage] = useState(1)
  const [totalPages, settotalPages] = useState(0)

  useEffect(() => {
    GetSugumaAccs()
  }, [])

  const handleChangeTable = ({ current }) => {
    GetSugumaAccs(current)
    setcurrentPage(current)
  }

  const handleBackBtn = () => {
    Navigate("/");
  };

  const TableColumn = [
    { title: "Branch Name", dataIndex: "branch_name" },
    { title: "Opened Date", dataIndex: "opening_date" },
    { title: "Account Type", dataIndex: "account_type",
      render: (account_type) => (
        <span>
          {account_type ? replaceUnderScoreAndCapitialize(account_type) : 'N/A'}
        </span>
      ),
     },
    { title: "Account Number", dataIndex: "account_no" },
    {
      title: "Action",
      dataIndex: "action",
      render: (v, rec) =>
        isLoading ? (
          <Skeleton text={{ rows: 1, width: 100 }} />
        ) : (
          <div className="d-flex justify-content-end">
            <>
              <button
                onClick={() => Navigate("/mySugamaAccountDetails", { state: { accountId: rec.account_no } })}
                className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp"
              >
                View
              </button>
            </>
          </div>
        ),
    },
  ];


  const GetSugumaAccs = async (nextPage) => {
    try {
      setisLoading(true)
      const reqstBody = {
        page: isNaN(nextPage) ? 1 : nextPage,
        size: 10
      }
      let response = await ManageApis(
        "post",
        global.getSugamaAccs,
        reqstBody,
        token
      );
      if (response?.response_data?.status) {
        const accountList = response?.response_data?.data?.docs;
        setSugumaAcc(accountList);
        settotalPages(response?.response_data?.data?.total)
      } else {
        FailedPopup(response);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setisLoading(false)
    }
  };

  return (
    <Layout loading={isLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <BackBtn
              title={"My Sugama Accounts"}
              handleBackBtn={handleBackBtn}
            />
          </div>
        </div>
        <div className="p-3 bg-white br_10 shadow_sm">
        {sugumaAcc?.length ?
          <div className="arco_table1 table-responsive custom_scroll1">
            <DataTable
              columnData={TableColumn}
              rowData={sugumaAcc}
              onChangeTable={handleChangeTable}
              total={totalPages}
              current={currentPage}
            />
          </div>
          :
          <NoData title={'No Sugama A/C found'} />
        }
        </div>
      </div>
    </Layout>
  );
}

export default MySugamaAccount;
