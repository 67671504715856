import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (true) {
  console.log = function () { };
  console.error = function() {};
  
}


const tagManagerArgs = {
  gtmId: global.googleTag // Your GTM ID
};

TagManager.initialize(tagManagerArgs);

root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
