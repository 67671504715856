import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import InputField from "../../Components/InputField/InputField";
import ViewNomineeDetailsModal from "../ViewNomineeDetails/ViewNomineeDetailsModal";
import { ContextAPI } from "../../common/Context/ContextApi";
import {
  FailedPopup,
  replaceUnderScoreAndCapitialize,
  sanitizedText,
} from "../../common/Utils/Utils";
import { ManageApis } from "../../common/APIS/ManageApis";
import PopupModal from "../../Components/PopupModal";

function MySugamaAccountDetails() {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { token, setnotificationsDetails } = useContext(ContextAPI);
  const { accountId } = state || {};
  const [isLoading, setisLoading] = useState(false);
  const [Accdetails, setAccDeatils] = useState();
  const [remark,setRemark]=useState()
  const [isNomineeDeatilsModal, setIsNomineeDeatilsModal] = useState(false);
  const [amount, setamount] = useState("");
  const [ModalProps, setModalProps] = useState({
    open: false,
    title: "",
    message: "",
    props: [],
  });

  useEffect(() => {
    GetAccDetails();
  }, []);

  const handleBackBtn = () => {
    Navigate("/mySugamaAccount");
  };

  const handleonChange = (text) => {
    text = sanitizedText(text).replace(/^0+(?=\d)/, "");
    setamount(text);
  };

  //Function to get Acc Details
  const GetAccDetails = async () => {
    try {
      setisLoading(true);
      const reqstBody = {
        account_no: accountId,
      };
      let response = await ManageApis(
        "post",
        global.getSugamaDetails,
        reqstBody,
        token
      );
      if (response?.response_data?.status) {
        const accountList = response?.response_data?.data?.sugama;
        setRemark(response?.response_data?.data?.remarks)
        setAccDeatils(accountList);
      } else {
        FailedPopup(response);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setisLoading(false);
    }
  };

  //Function to add to cart
  const handlePaymentRequest = async () => {
    try {
      setisLoading(true);
      const reqstBody = {
        account_id: Accdetails?.account_id,
        type: "sugama",
        amount: amount,
      };
      const response = await ManageApis(
        "post",
        global.addToCart,
        reqstBody,
        token
      );
      const { response_data, status_code } = response;
      const { status, errors, message } = response_data;
      const showModal = (title, msg, actions = []) => {
        setModalProps({
          open: true,
          title,
          message: msg,
          props: actions,
        });
      };
      if (status_code === 200) {
        setamount("");
        setnotificationsDetails((prev) => ({
          ...prev,
          cart_count: prev.cart_count + 1,
        }));

        return showModal(
          status ? "" : "Failure",
          status
            ? "Item successfully added to cart, Do you want to add more items?"
            : message,
          status
            ? [
                {
                  text: "Proceed to cart",
                  onPress: () => Navigate("/cart"),
                  style: "cancel",
                  variant: "master",
                },
                {
                  text: "Yes",
                  onPress: () => Navigate("/"),
                  variant: "master",
                },
              ]
            : []
        );
      }
      if (message.includes("already added")) {
        return showModal("Failed!", response.message, [
          {
            text: "Cancel",
            style: "cancel",
            variant: "master-line",
            onPress: () => setModalProps({ open: false }),
          },
          {
            text: "Go to cart",
            variant: "master",
            onPress: () => Navigate("/cart"),
          },
        ]);
      }
      return showModal("Failure", message);
    } catch (error) {
      console.log("error -- ", error);
    }
  };

  const ChitDetailArray = [
    {
      title: "Customer Name",
      value: (() => {
        const primaryHolder = Accdetails?.accountHolderDetails?.find(
          (holder) => holder?.holderType === "PRIMARY_HOLDER"
        );
        return primaryHolder
          ? `${primaryHolder?.firstName || ""} ${
              primaryHolder?.lastName || ""
            }`.trim()
          : null;
      })(),
    },
    { title: "Sugama A/C No", value: Accdetails?.accountNo },
    { title: "Account Type", value: Accdetails?.accountType },
    { title: "Account Balance", value: Accdetails?.currentBalance },
    { title: "Opened Date", value: Accdetails?.openingDate },
    { title: "Branch", value: Accdetails?.branch?.name },
    {
      title: "Holder Name",
      value: (() => {
        const holder = Accdetails?.accountHolderDetails?.find(
          (holder) => holder?.holderType === "HOLDER"
        );
        return holder
          ? `${holder?.firstName || ""} ${holder?.lastName || ""}`.trim()
          : null;
      })(),
    },
    { title: "Status", value: Accdetails?.approvalStatus },
  ].filter((item) => item.value !== null && item.value !== "");

  return (
    <Layout loading={isLoading}>
      {ModalProps?.open && (
        <PopupModal
          visible={ModalProps.open}
          handleClose={() => {
            setModalProps({ open: false });
            GetAccDetails();
          }}
          title={ModalProps?.title}
          message={ModalProps?.message}
          buttons={ModalProps?.props}
        />
      )}
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-lg-center d-flex flex-column flex-lg-row justify-content-between flex-wrap gap-2">
            <BackBtn
              title="My Sugama Account Details"
              handleBackBtn={handleBackBtn}
            />
            {[
              {
                text: "Standing Instructions",
                onClick: () =>
                  Navigate("/viewStandingInstructions", {
                    state: { accountId: accountId },
                  }),
              },

              {
                text: "View Nominee Details",
                onClick: () => setIsNomineeDeatilsModal(true),
              },
              {
                text: "View Passbook",
                onClick: () =>
                  Navigate("/viewSugamaPassbook", {
                    state: { accountId: Accdetails?.accountNo },
                  }),
              },
            ].map((btn, index) => (
              <button
                key={index}
                onClick={btn.onClick}
                className="btn btn-master flex-fill flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded"
              >
                {btn.text}
              </button>
            ))}
          </div>
        </div>
        <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
          <div className="row">
            {ChitDetailArray.map((item, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2"
              >
                <div className="row">
                  <div className="col-12 col-sm-6 font_13">{item.title}</div>
                  <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                    <div className="d-flex">
                      <span className="pe-1 d-none d-sm-block">:</span>
                      <span
                        className={`word_break fw_600 font_13 ${
                          item.value == "APPROVED"
                            ? "text-success"
                            : "text-black"
                        }`}
                      >
                        {item.value}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {
              !remark || remark === ""
                ? ''
                :
                <div className="rounded-3 bg_gray d-inline-block p-1 border font_13 mb-1 rounded-3">
                  <span>Remarks :</span>
                  <span className="fw_600 ps-2">{remark}</span>
                </div>
            }
          </div>
          <div className="row justify-content-end">
            {/* <div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
              <div className="row align-items-center">
                <div className="col-12 col-sm-6 font_13">Status</div>
                <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                  <div className="d-flex align-items-center">
                    <span className="pe-1 d-none d-sm-block">:</span>
                    <span className=" word_break text_green3 fw_600 font_13">
                      ACTVE
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <h3 className="fw_600 font_18 text_master ms-2 mb-3">Deposit Amount</h3>
        <div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
          <div className="row justify-content-end">
            <div className="col-12 col-lg-4 col-md-6 col-xl-3">
              <InputField
                label={"Enter the amount you wish to deposit"}
                type={"number"}
                required
                placeholder={"Enter amount"}
                value={amount}
                onchange={handleonChange}
                maxLen={8}
                name={"amount"}
                className="text-end"
              />
                <button
                  disabled={amount < 10}
                  onClick={handlePaymentRequest}
                  className="btn btn-master flex-fill flex-lg-grow-0 font_12 fw_500 px-3 px_btn rounded w-100"
                >
                  Add to Payment List
                </button>
            </div>
          </div>
        </div>
      </div>
      <ViewNomineeDetailsModal
        isNomineeDeatilsModal={isNomineeDeatilsModal}
        setIsNomineeDeatilsModal={setIsNomineeDeatilsModal}
        Details={Accdetails}
      />
    </Layout>
  );
}

export default MySugamaAccountDetails;
