import { useCallback, useContext, useEffect, useState } from "react";
import BackBtn from "../../../Components/BackBtn/BackBtn";
import Layout from "../../../Components/Layout/Layout";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { ManageApis } from "../../../common/APIS/ManageApis";
import { FailedPopup } from "../../../common/Utils/Utils";
import DataTable from "../../../Components/DataTable/DataTable";
import { Skeleton } from "@arco-design/web-react";
import NoData from "../../../Components/NoData/NoData";
import { useNavigate } from "react-router-dom";

const GoldOdList = () => {
    const Navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const [nextPage, setnextPage] = useState(0)
    const [currentPage, setcurrentPage] = useState(1)
    const [totalPages, settotalPages] = useState(0)
    const { token } = useContext(ContextAPI)
  const [list, setList] = useState([])
    useEffect(
      () => {
       
        setList([])
        myLoansList()
        return (() => {
          setList([])
        })
      },[],
    )
    const handleChangeTable = ({ current }) => {
        myLoansList(current)
        setcurrentPage(current)
    }

    const TableColumn = [
        { title: 'Branch Name', dataIndex: 'branch_name' },
        { title: 'Date of Sanction', dataIndex: 'date_of_sanction',},
        { title: 'Sanctioned Amount', dataIndex: 'sanction_amount',},
        { title: 'Account No', dataIndex: 'account_no' },
        {
          title: 'Action', dataIndex: 'action', render: (v, rec) =>
          loading ? <Skeleton text={{ rows: 1, width: 100 }} />
              :
              <div className="d-flex justify-content-end">
                <>
                  <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 ws_nowrp" onClick={() => Navigate(`/goldoddetails`, { state: { accountId: rec.account_no  } })} >View</button>
                </>
              </div>
        },
        
    ]
    
    const myLoansList = useCallback(async (nextPage) => {
        //console({ list })
        
        setloading(true)
        const reqstBody = {
            page: isNaN(nextPage) ? 1 : nextPage,
            size: 10,
            limit: 10,
        }
        //console.log('tok', token)
        let response = await ManageApis('post', global.getGoldOdAccounts, reqstBody, token)
        if (response.message == "Success") {
            console.log(response)
            setloading(false)
            if (response?.response_data?.status) {
                let data = []
                data = response.response_data.data.docs
                settotalPages(response.response_data.data.total)
                // console('pagintaion', response.response_data.page > 1)
                if (response.response_data.data.page > 1) {
                    data = [...list, ...response.response_data.data.docs]
                    settotalPages(response.response_data.data.total)
                } else {
                  
                    data = response.response_data.data.docs
                    settotalPages(response.response_data.data.total)
                }
                setList(data)
                //console('new arr--------', data)
                setnextPage(response.response_data.data.nextPage)

            }
        } else {
            setloading(false)
            //console.log(response)
            FailedPopup( response)
        }
    }, [token])

    return (
        <Layout loading={loading}>
          <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
            <div className="mb-3">
              <div className="align-items-center d-flex justify-content-between flex-wrap">
              <BackBtn title={'My Gold OD'} />
              </div>
            </div>
            <div>
              <div className="p-3 bg-white br_10 shadow_sm">
              {list?.length ?
                <div className="arco_table1 table-responsive custom_scroll1">
                  <DataTable 
                    columnData={TableColumn} 
                    rowData={list} 
                    onChangeTable={handleChangeTable} 
                    total={totalPages} 
                    current={currentPage} 
                  />
                </div>
                :
                <NoData title={'No Gold OD found'} />
                
              }
              </div>
            </div>
          </div>
        </Layout>
      );
}

export default GoldOdList
