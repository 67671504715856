import React, { useContext, useEffect, useState } from 'react'
import Dashboard from '../Dashboard/Dashboard';
import Layout from '../../Components/Layout/Layout';
// import { FailedPopup, getSessionStorage, WarningPopup } from '../../common/Utils/Utils';
// import { useNavigate } from 'react-router-dom';





function Home() {
	// const navigate=useNavigate()
    const [IsLoading, setIsLoading] = useState(false)
// 	const [visible, setVisible] = useState(false)
// 	// const userDetails= getSessionStorage('userdetails')
// 	let {notLinkedAccounts} = getSessionStorage('userdetails')
// 	useEffect(() => {
// 		let {notLinkedAccounts} = getSessionStorage('userdetails')
// debugger
// 		setVisible(notLinkedAccounts !== 0)
// 	  }, [])
	return (
		<Layout loading={IsLoading}>
			{/* {	true &&	WarningPopup( `You have ${notLinkedAccounts} Account to Link  Please Link the Account`,()=>{navigate("/myaccount")},"Need to Link Account")}	 */}
			<Dashboard />
		</Layout>

	)
}
export default Home