import { ChevronBackOutline } from "react-ionicons";
import Logo from "../../assets/images/Logo.png";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { useCallback, useState } from "react";
import InputField from "../../Components/InputField/InputField";
import {
  FailedPopup,
  FormValidation,
  customLogger,
  successPopup,
} from "../../common/Utils/Utils";
import { useLocation, useNavigate } from "react-router";
import { ManageApis } from "../../common/APIS/ManageApis";
import LoginRegLayout from "../UserLoginReg/LoginReg";
import { defaultTexts } from "../../constant";

function ChangeForgotPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const [Errors, setErrors] = useState([])
  const [Errors, setErrors] = useState([
    { name: "password", message: "" },
    { name: "confirmPassword", message: "" },
  ]);
  const [form, setform] = useState({ password: "", confirmPassword: "" });
  const [IsLoading, setIsLoading] = useState(false);

  const handleChange = useCallback(
    (value, names) => {
      //customLogger(({ value, name }))
      setform((prev) => ({ ...prev, [names]: value }));
      if (names == "confirmPassword") {
        if (value.length) {
          setErrors(
            Errors.map((item) =>
              item?.name === names ? { name: names, message: "" } : item
            )
          );
        }
      }
      // if (names == 'confirmPassword') {
      // 	if(form.password){
      // 		if(form.password !== value) {
      // 			setErrors(Errors.map(item => item?.name === names ? { name:names, message: 'Password and Confirm Password does not match.' } : item))
      // 		}else{
      // 			setErrors(Errors.map(item => item?.name === names ? { name:names, message: '' } : item))
      // 		}
      // 	}
      // }
      // if (names == 'password') {
      // 	if(form.confirmPassword){
      // 		if(form.confirmPassword !== value) {
      // 			setErrors(Errors.map(item => item?.name === 'confirmPassword' ? { name:'confirmPassword', message: 'Password and Confirm Password does not match.' } : item))
      // 		}else{
      // 			setErrors(Errors.map(item => item?.name === 'confirmPassword' ? { name:'confirmPassword', message: '' } : item))
      // 		}
      // 	}
      // }
    },
    [Errors]
  );

  const formField = [
    {
      type: "password",
      label: "Password",
      placeholder: "",
      required: true,
      onchange: handleChange,
      name: "password",
      disabled: false,
    },
    {
      type: "password",
      label: "Confirm Password",
      placeholder: "",
      required: true,
      onchange: handleChange,
      name: "confirmPassword",
      disabled: false,
    },
    ,
  ];
  const handleErrors = (name, message) => {
    if (Errors.length) {
      if (name.includes("password")) {
        message == "Enter a strong Password"
          ? setErrors(
              Errors.map((item) =>
                item?.name === name
                  ? {
                      name: name,
                      message:
                        "Password must be a minimum of 8 characters, special chars, uppercase, and lowercase letters and numbers.",
                    }
                  : item
              )
            )
          : setErrors(
              Errors.map((item) =>
                item?.name === name ? { name: name, message: message } : item
              )
            );
      } else {
        setErrors(
          Errors.map((item) =>
            item?.name === name ? { name: name, message: message } : item
          )
        );
      }
    } else setErrors((prev) => [...prev, { name: name, message: message }]);
  };

  const validate = () => Errors.some((item) => item?.message);

  const handleSubmit = async () => {
    let requiredItems = formField
      .filter((item) => item.required)
      .map((item) => !!item.required && item.name);
    let validationResult = FormValidation(form, requiredItems);
    if (form.password.toLowerCase().includes(state?.username?.toLowerCase())) {
      FailedPopup("Password cannot contain the username");
      return;
    }
    if (form.password == form.confirmPassword) {
      if (validationResult.validation) {
        // trigger check username api
        //debugger
        if (!validate()) {
          handleCallApi();
        }
      } else {
        if (validationResult.failedItems.length) {
          let requiredItems = formField.map((item) => ({
            name: item.name,
            message: validationResult.failedItems.includes(item.name)
              ? "Field is required"
              : "",
          }));
          setErrors(requiredItems);
          // if (!Errors.length) {
          // 	setErrors(requiredItems)
          // } else {
          // 	setErrors(prev => [...prev, ...requiredItems])
          // }
        }
      }
    } else {
      FailedPopup("Password and Confirm Password does not match.");
    }
  };

  const handleCallApi = async () => {
    // call validate fn and is true call backend api
    setIsLoading(true);
    //  axios call

    const rqstBody = {
      ...form,
      token: state.changePasswordToken,
    };

    let response = await ManageApis(
      "post",
      global.changeForgotPassword,
      rqstBody
    );
    // if axios success
    if (response.message === "Success") {
      customLogger(response);
      setIsLoading(false);
      successPopup(response.response_data.message);
      navigate("/login");
    }
    // axios fail
    else {
      setIsLoading(false);
      customLogger(response.response_data);
      FailedPopup(response);
    }
  };

  return (
    <LoginRegLayout loading={IsLoading}>
      <div className="d-flex flex-column h-100 p-5 overflow-auto scrollbar1">
        {/* <div className="">
					<BackBtn />
				</div> */}
        <div className="m-auto max_w_350x py-3 w-100">
          <div>
            <div className="text-center">
              <img src={Logo} className="mb-4 logo_prop" />
            </div>
            <h1 className="h4 text-center text_master fw_700 pb-3">
              Change Password
            </h1>
            {formField.map((item) => (
              <div>
                <InputField
                  {...item}
                  errorMessage={
                    !!Errors.length &&
                    Errors?.find((itm) => itm.name === item.name)?.message
                  }
                  seterrorMessage={handleErrors}
                  helperText={
                    item.name === "password" &&
                    Errors.some(
                      (item) => item.name === "password" && item.message === ""
                    )
                      ? defaultTexts?.passwordNote
                      : ""
                  }
                />
              </div>
            ))}
            <div className="d-flex gap-3">
              <button
                className="btn px_btn btn-master-line px-4 rounded w-100 fw_500"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn px_btn btn-master px-4 rounded w-100 fw_500"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoginRegLayout>
  );
}
export default ChangeForgotPassword;
