import React from "react";
import PopupModal from "../../Components/PopupModal";

function ViewNomineeDetailsModal({
  isNomineeDeatilsModal,
  setIsNomineeDeatilsModal,
  Details
}) {

  const ChitDetailArray = (Details?.nomineeDetail || [])
  .map((nominee) =>
    [
      { title: "Nominee Name", value: nominee.nominee },
      { title: "Share", value: nominee.share },
      { title: "Age", value: nominee.age },
      { title: "Address", value: nominee.address },
      { title: "Date of Birth", value: nominee.dob },
      { title: "Relation", value: nominee.relation },
    ].filter((item) => item.value) 
  )
  .flat();


  return (
    <PopupModal
      title="Nominee Details"
      size="lg"
      visible={isNomineeDeatilsModal}
      Backdrop={"static"}
      handleClose={() => setIsNomineeDeatilsModal(false)}
    >
      <div>
        <div className="border br_10 p-3">
          <div className="row">
            {ChitDetailArray.map((item, index) => (
              <div key={index} className="col-12 col-md-6 pb-0 pb-sm-2">
                <div className="row">
                  <div className="col-12 col-sm-6 font_13">{item.title}</div>
                  <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                    <div className="d-flex">
                      <span className="pe-1 d-none d-sm-block">:</span>
                      <span className="text-black word_break fw_600 font_13">
                        {item.value}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PopupModal>
  );
}

export default ViewNomineeDetailsModal;
