import Layout from "../../Components/Layout/Layout"
import InputField from "../../Components/InputField/InputField"
import BackBtn from "../../Components/BackBtn/BackBtn"
import PopupModal from "../../Components/PopupModal"
import { FailedPopup, downloadBase64PDF, formatDate, getMonthsRange, sortByDateDescending } from "../../common/Utils/Utils"
import { useContext, useEffect, useState } from "react"
import { ContextAPI } from "../../common/Context/ContextApi"
import { useLocation } from "react-router-dom"
import { ManageApis } from "../../common/APIS/ManageApis"
import moment from "moment"
import NoData from "../../Components/NoData/NoData"

const dates = getMonthsRange()
function ViewLoanPassBook() {
  const { state } = useLocation()
  //debugger
  const { loanAccountNumber, isEmi } = state || {}
  const {token} = useContext(ContextAPI)
  const [data, setdata] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [visible, setvisible] = useState(false)
  const [selectedColItems, setselectedColItems] = useState([])
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);

//   useEffect(() => {
//     handleViewPassbook()
    
// }, [])

// const handledateChange = (value) => {
//   if (value?.length) {
//     setStartDate(value[0])
//     setEndDate(value[1])
//   }
// }

useEffect(() => {
  if (startDate && endDate) {
    handleViewPassbook()
  }
},
  [startDate, endDate],
)



  // handle view passbook axios call
  const handleViewPassbook = async () => {
    setisLoading(true)
    // const  reqstBody = { loanAccountNumber :loanAccountNumber,
    //   fromDate: formatDate(startDate,'DD-MM-YYYY', 'YYYY-MM-DD'),
    //   toDate: formatDate(endDate,'DD-MM-YYYY','YYYY-MM-DD'), 
    //  }
     const  reqstBody = { loanAccountNumber }
    let response = await ManageApis("post", global.getLoanPassbook, reqstBody, token)
    if (response.message == 'Success') {
      //console.log("res", response)
      setisLoading(false)
      const { status, data: { passBookList } } = response.response_data
      if (status) {
        let sortedData = sortByDateDescending(passBookList, 'date');
        setdata(sortedData)
      }
    }
    else {
      setisLoading(false)
      //console.log("res", response)
      FailedPopup(response)
    }

  }


  const handleClickViewMore = (item) => {
    let array = [
      { key: "Voucher No", value: item.voucherNo },
      { key: "Transaction Date", value: item.date },
      { key: "Amount", value: item.netChallanAmount },


  
      // { key: "Payment Mode", value: item.paymentMode },
      // { key: "Value Date", value: item.valueDate },
      // { key: "Principal", value: item.principal },
      // { key: "Interest", value: item.interest },
      // { key: "Outstanding Balance", value: item.outstandingBalance },
      // { key: "Credit", value: item.credit },
      // { key: "Debit", value: item.debit },
      // { key: "Net Challan Amount", value: item.netChallanAmount },
      // { key: "Description", value: item.description },
    ]
    if(isEmi){
      array.push({ key: "Due Date", value: item.dueDate },{ key: "Installment No", value: item.installmentNo })
    }
    array.push({ key: "Payment Mode", value: item.paymentMode },
    { key: "Value Date", value: item.valueDate },
    { key: "Principal", value: item.principal },
    { key: "Interest", value: item.interest },
    { key: "Outstanding Balance", value: item.outstandingBalance },
    { key: "Credit", value: item.credit },
    { key: "Debit", value: item.debit },
    { key: "Net Challan Amount", value: item.netChallanAmount },
    { key: "Description", value: item.description })

    //debugger
    setselectedColItems(array)
    setvisible(true)
  }
  const handlecloseModal = () => {
    setselectedColItems([])
    setvisible(false)
  }
  // handle export passbook to pdf 
  const handleExportPassbookToPdf = async () => {
    //debugger
    const reqstBody = { loanAccountNumber }
    setisLoading(true)
    let response = await ManageApis("post", global.exportLoanPassbook, reqstBody, token)
    if (response.message == "Success") {
      setisLoading(false)
      let fileName = `loan-passbook`
      let data_ = response.response_data.data
      downloadBase64PDF(data_, fileName)

    }
    else {
      setisLoading(false)
      FailedPopup(response)
    }
  }

  return (
    <Layout loading={isLoading}>
      <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
        <div className="mb-3">
          <div className="mb-3">
           
            <div className='align-items-center d-flex justify-content-between flex-wrap'>
              <BackBtn title={'View Loan Passbook'} />

              <div className="col-12 col-sm-7 col-lg-4 col-xl-auto">
                {/* <div className='d-flex gap-2 p-2 bg-white rounded mt-2'>

                  <InputField marginBottom label={'Choose Date'} 
                    onchange={handledateChange}
                    type={'date-range'} 
                    value={[startDate, endDate]} 
                  />

                </div> */}
              </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm mb-2">
            <div className="align-items-center row">
              <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0">
                <span className="pe-1 fw_700">Loan Account Number :</span>
                <span className="fw_500">{loanAccountNumber}</span>
              </div>
              <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-md-end">
              {data?.length ?
                <button className="btn btn-master flex-fill flex-md-grow-0 font_12 px_btn py-1 rounded" onClick={handleExportPassbookToPdf}>Export as PDF</button>
              :""}
                </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm ">
            {data?.length ?
              <div className="table_type02 table-responsive custom_scroll1">
                <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
                  <thead className="bg_master text-white">
                    <tr>
                      <th className="fw_500" style={{ width: "60px" }}>Sl.No</th>
                      <th className="fw_500">Voucher No</th>
                      <th className="fw_500">Date</th>
                      <th className="fw_500 text-end">Amount</th>
                      <th className="fw_500" style={{ width: "90px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item,index) =>
                      <tr>
                        <td>{1+index}</td>
                        <td>{item.voucherNo}</td>
                        <td>{item.date}</td>
                        <td className="text-end">{item.netChallanAmount}</td>
                        <td>
                          <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100" onClick={() => handleClickViewMore(item)}>More</button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              :
              <NoData title={'No data to display'} />
            }
          </div>
        </div>
      </div>
      <PopupModal title="Voucher Details" size="lg" visible={visible} handleClose={handlecloseModal}>
        <div className="pb-1">
          <div className="row">
            {selectedColItems.map(item =>
            <> 
              {item.key.includes('Description')?
                <div className="col-12 pb-0 pb-sm-2">
                  <div className="row">
                    <div className="col-12 col-sm-3 text_gray7">{item.key}</div>
                    <div className="col-12 col-sm-9 pt-1 pt-sm-0 mb-3 mb-sm-0">
                      <div className="d-flex">
                        <span className="pe-1 d-none d-sm-block">:</span>
                        <span className="text-black word_break fw_500">{item.value}</span>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="col-12 col-md-6 pb-0 pb-sm-2">
                  <div className="row">
                    <div className="col-12 col-sm-6 text_gray7">{item.key}</div>
                    <div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
                      <div className="d-flex">
                        <span className="pe-1 d-none d-sm-block">:</span>
                        <span className="text-black word_break fw_500">{item.value}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
              
            </>
             
            )}

          </div>
        </div>
      </PopupModal>
    </Layout>
  )
}
export default ViewLoanPassBook