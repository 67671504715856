import React, { useMemo, useState, useContext } from "react";
import { FailedPopup, replaceUnderScoreAndCapitialize } from "../../common/Utils/Utils";
import { useNavigate } from "react-router-dom";
import { ManageApis } from "../../common/APIS/ManageApis";
import { ContextAPI } from "../../common/Context/ContextApi";

const LOAN_TABLE_HEAD = [
  "account_no",
  "loan_category",
  "customer_name",
  "branch_name",
];

const CHITTAL_TABLE_HEAD = [
  "chit_id",
  "chittal_no",
  "customer_name",
  "branch_name",
];

const Favourite = React.memo(({ data, type , setLoading}) => {
  const navigate = useNavigate();
  const { token } = useContext(ContextAPI);

  const extractChitDivAndClass = (chit) => {
    if (chit) {
      const parts = chit.split("/");
      const chitty_class = parts[parts.length - 2];
      const chitty_division = parts[parts.length - 1];
      return { chitty_class, chitty_division };
    }
  };

  const handleNavigate = async (type, param) => {
    try {
      setLoading(true);
      let reqstBody = {};
      const isChitty = type === "chitty";
    let temp_chittal_name
      const { favourite_id } = param;
      if (isChitty) {
        const { branch_id, temp_chittal_name:chittal_name, chittal_no, chit_reg_no, chit_id ,customer_name} =
          param;
          if (temp_chittal_name === undefined) {
            temp_chittal_name = customer_name;
          }     
        const { chitty_division, chitty_class } =
        extractChitDivAndClass(chit_id);
        reqstBody = {
          branch_id,
          chittal_name:temp_chittal_name,
          chittal_no,
          chitty_no: chit_reg_no,
          chitty_class,
          chitty_division,
        };
      } else {
        const { branch_id, account_no, customer_name } = param;

        reqstBody = {
          branch_id,
          loan_account_no: account_no,
          loanee_name: customer_name,
        };
      }

      // let { token } = UserData;
      let path = isChitty
        ? global.getCustomerChitdetails
        : global.getCustomerLoandetails;
      let response = await ManageApis("post", path, reqstBody, token);

      if (response.message == "Success") {
        const { status, message, data } = response.response_data || {};
        if (status) {
          if (isChitty) {
            let state = {
              chittal_id:
                data.chittal_details._id || data.chittal_details.account_id,
              chittal_no: reqstBody.chittal_no,
              chit_id: data.chit_details.chit_id,
              branch_id: reqstBody.branch_id,
              quickPaydata: { ...data, ...reqstBody, remarks: data.remarks },
              ChitDetails: data.chit_details,
              isAlreadyExist: true,
              favourite_id,
            };
            navigate("/chittaldetails", { state: state });
          } else {
            let state = {
              quickPaydata: { ...data, isAlreadyExist: true, favourite_id },
            };
            navigate("/loandetails", { state: state });
          }
        } else {
          //console("err", response)
          // alert('Failed!', message);
        }
      } else {
        //console("err", response)
        // alert('Failed!', response);
      }
    } catch (error) {
      FailedPopup(error?.message || error);
    } finally {
      setLoading(false);
    }
  };
  const renderItem = (row, idx) => {
    const TABLEHEAD = type === "loan" ? LOAN_TABLE_HEAD : CHITTAL_TABLE_HEAD;
    return (
      <>
        <td>{idx + 1}</td>
        {TABLEHEAD.map((key, index) => (
          <td key={index}>{row[key]}</td>
        ))}
        <td>
          <button
            className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 text-nowrap"
            // onClick={() => handleNavigate(row)}
            onClick={() => handleNavigate(type, row)}
          >
            View
          </button>
        </td>
      </>
    );
  };
  const TABLEHEAD = useMemo(
    () => (type === "loan" ? LOAN_TABLE_HEAD : CHITTAL_TABLE_HEAD),
    [type]
  );

  return (
    <div className="table_type02 table-responsive custom_scroll1">
      <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
        <thead className="bg_master text-white">
          <tr>
            <th className="fw_500 " style={{ width: "40px" }}>Sl.No</th>
            {TABLEHEAD.map((item) => (
              <th className="fw_500" style={{ width: "40px" }} key={item}>
                {replaceUnderScoreAndCapitialize(item === "chit_id" ? "chit_no" : item)}
              </th>
            ))}
            <th className="fw_500 " style={{ width: "40px" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.filter(row => row._id).map((row, index) => (
            <tr key={index}>{renderItem(row, index)}</tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default Favourite;
