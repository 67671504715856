import Layout from "../../Components/Layout/Layout";
import InputField from "../../Components/InputField/InputField";
import BackBtn from "../../Components/BackBtn/BackBtn";
import {
	FailedPopup,
	downloadBase64PDF,
	formatDate,
	getMonthsRange,
} from "../../common/Utils/Utils";
import { useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { useLocation } from "react-router-dom";
import { ManageApis } from "../../common/APIS/ManageApis";
import NoData from "../../Components/NoData/NoData";
import { formatToDOMMYYYY } from "../../common/Utils/dateFormatter";

const dates = getMonthsRange();
const statusMap = {
	1: "PROCESSING",      // PENDING
	2: "INITIATED",
	3: "SUCCESS",         // PROCESSED
	4: "REJECTED",
	5: "SUCCESS",         // SUCCESS
	6: "RETURN",
	7: "FAILURE",
	8: "TIMEOUT",
	9: "ERROR"
};

function GoldODWithdrawalHistory() {
	const { state } = useLocation();
	const { account_no, customerName } = state || {};
	const { token } = useContext(ContextAPI);
	const [startDate, setStartDate] = useState(dates.startDate);
	const [endDate, setEndDate] = useState(dates.endDate);
	const [isLoading, setisLoading] = useState(false);
	const [data, setdata] = useState([]);


	useEffect(() => {
		handleViewPassbook();
	}, []);

	const handleViewPassbook = async () => {
		try {
			setisLoading(true);
			let reqstBody = {};
	
			reqstBody = {
				account_no
			};
			let path = global.getWithdrawalDetails;
			let response = await ManageApis('post', path, reqstBody, token);
			console.log('res', response.response_data.data);
			const { status, data } = response.response_data;
			if (status) {
				let newData = data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
				setdata(newData || []);
			} else {
				FailedPopup(response)
			}
		} catch (error) {
			FailedPopup(error?.message)
		} finally {
			setisLoading((prev) => false);
		}
	};

	return (
		<Layout loading={isLoading}>
			<div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
				<div className="mb-3">
					<div className="mb-3">
						<div className="align-items-center d-flex justify-content-between flex-wrap">
							<BackBtn title={"View Gold OD Withdrawal History"} />

							{/* <div className="col-12 col-sm-7 col-lg-4 col-xl-auto">
								<div className="d-flex gap-2 p-2 bg-white rounded mt-2">
									<InputField
										marginBottom
										label={"Choose Date"}
										onchange={handledateChange}
										type={"date-range"}
										value={[startDate, endDate]}
										onClear={() => {
											setStartDate("");
											setEndDate("");
										}}
									/>
								</div>
							</div> */}
						</div>
					</div>
					<div className="p-3 bg-white br_10 shadow_sm mb-2">
						<div className="align-items-center row">
							<div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
								<span className="pe-1 fw_700">Customer Name :</span>
								<span className="fw_500">{customerName}</span>
							</div>
							<div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
								<span className="pe-1 fw_700">Gold OD A/C No :</span>
								<span className="fw_500">{account_no}</span>
							</div>
						</div>
					</div>
					<div className="p-3 bg-white br_10 shadow_sm ">
						{data?.length ? (
							<div className="table_type02 table-responsive custom_scroll1">
								<table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
									<thead className="bg_master text-white">
										<tr>
											<th className="fw_500" style={{ width: "60px" }}>
												Sl.No
											</th>
											<th className="fw_500" style={{ width: "200px" }}>Date</th>
											<th className="fw_500">Amount</th>
											<th className="fw_500">Status</th>
											{/* <th className="fw_500" style={{ width: "90px" }}></th> */}
										</tr>
									</thead>
									<tbody>
										{data.map((item, index) => (
											<tr>
												<td>{1 + index}</td>
												<td>{formatToDOMMYYYY(item.dateTime,true)}</td>
												<td>{item.amount}</td>
												<td>{statusMap[item.status] || "UNKNOWN"}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<NoData title={"No data to display"} />
						)}
					</div>
					<div className="p-3 bg-white br_10 shadow_sm d-none">
						<div className="d-flex">
							<div className="m-auto">
								<div className="text-center d-grid py-5 text_gray7">
									<i className="far fa-file-alt font_18"></i>
									<p className="mb-0 mt-2">No Data Found.</p>
									<p className="mb-0 mt-1 font_12">please From and To date</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
export default GoldODWithdrawalHistory;
