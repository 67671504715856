import Layout from "../../Components/Layout/Layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ManageApis } from "../../common/APIS/ManageApis";
import { useContext } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { Select, Skeleton, Table } from "@arco-design/web-react";
import BackBtn from "../../Components/BackBtn/BackBtn";
import BranchList from "../../Components/BranchList/BranchList";
import {
  FailedPopup,
  customLogger,
  preventFirstLetterZero,
  successPopup,
} from "../../common/Utils/Utils";
import PopupModal from "../../Components/PopupModal";
import InputField from "../../Components/InputField/InputField";
import { AlbumsOutline, Grid } from "react-ionicons";
import GeneralButton from "../../Components/Buttons/GeneralButton";
import { render } from "@testing-library/react";
import { Button } from "react-bootstrap";

const { Option } = Select;
const initialValue = {
  chit_no: "",
  branch_id: "",
  branch_name: "",
  pattern: "",
  message: "Want to know more about this",
};
const initialFormValue = {
  searchBy: "",
  sala: "", //subscription amount
  noOfInstallments: "", //duration
  chit_no: "",
  branch_id: "",
  branch_name: "",
  district: "",
  pattern: "",
  denomination: "",
  message: "Want to know more about this",
};

const DropdownList = [
  { value: "district", key: "Branch" },
  { value: "sala", key: "Sala" },
  { value: "duration", key: "Duration" },
  { value: "denomination", key: "Subscription Amount" },
];
function NewChits() {
  const [visible, setVisible] = useState(false);
  const [Message, setMessage] = useState("");
  const [Errors, setErrors] = useState({});
  const { token } = useContext(ContextAPI);
  const [list, setList] = useState([]);
  const [loader, setloader] = useState(false);
  const [filter, setfilter] = useState({ branch_id: "" });
  const [form, setform] = useState(initialValue);
  const [searchForm, setSearchform] = useState(initialFormValue);
  const [buttonDisable, setButtonDisable] = useState(false);
  // useEffect(() => {
  //   getNewChitsDetails();
  //   setMessage("");
  // }, [filter.branch_id]);

  const handleChangeTable = useCallback(() => {
    // getNewChitsDetails();
  }, []);

  const handleErrors = (name, message) => {
    setErrors((prev) => ({ ...prev, name: name, message }));
  };

  // get chit list
  const getNewChitsDetails = async () => {
    try {
      setButtonDisable(true);
      setloader(true);
      let param;

      switch (searchForm.searchBy) {
        case "district":
          param = {
            filter: "district",
            district: searchForm.district,
            branch_ids: `${searchForm.branch_id}`,
          };
          break;

        case "sala":
          param = {
            filter: "sala",
            district: searchForm.district,
            sala: searchForm.sala,
          };

          break;
        case "denomination":
          param = {
            filter: "denomination",
            district: searchForm.district,
            denomination: searchForm.denomination,
          };

          break;

        case "duration":
          param = {
            filter: "duration",
            district: searchForm.district,
            duration: searchForm.noOfInstallments,
          };

          break;

        default:
          param = {
            branch_ids: searchForm.branch_id,
          };
          break;
      }

      const reqstBody = param;

      let response = await ManageApis(
        "post",
        global.getNewChit,
        reqstBody,
        token
      );
      if (response.message == "Success") {
        customLogger("successfull///", response);
        // setloader(false);
        if (response.response_data.status) {
          let data = [];
          data = response.response_data.data.docs;
          setList(data);
          customLogger("new arr--------", data);
        }
      } else {
        // setloader(false);
        customLogger(response);
        setList([]);
        FailedPopup(response.message);
      }
    } catch (error) {
      FailedPopup(error?.message);
    } finally {
      setloader(false);
      setButtonDisable(false);
    }
  }

  const TableColumn = useMemo(() => {
    const columns = [
      
      {
        title: "Branch",
        dataIndex: "",
        width: 160,
        render: (v, rec) => rec.branchName,
      },
      { title: "Sala", dataIndex: "", render: (v, rec) => rec.sala },
      {
        title: "Durations",
        dataIndex: "",
        render: (v, rec) => rec.noOfInstallments,
      },
      {
        title: "Multidivision",
        dataIndex: "",
        render: (v, rec) => rec.isMultiDivision ? "Yes" : "No",
      },
      {
        title: "Chit No",
        dataIndex: "",
        width: 160,
        render: (v, rec) => rec.temporaryChittyNo,
      },
      {
        title: "Chit Frequency",
        dataIndex: "",
        render: (v, rec) => rec.chittyFrequency,
      },
      {
        title: "Date Of Announcement",
        dataIndex: "",
        render: (v, rec) => rec.dateOfAnnouncement,
      },
      {
        title: "Proposed Date Of Commencement",
        dataIndex: "",
        render: (v, rec) => rec.proposedDateofCommencement,
      },
      
      { title: "Class", dataIndex: "", render: (v, rec) => rec.chittyClass },
      {
        title: "Division",
        dataIndex: "",
        render: (v, rec) => rec.noOfDivisions,
      },
      {
        title: "Status",
        dataIndex: "",
        render: (v, rec) => rec.status.replace("_", " "),
        headerCellStyle: {
          minWidth: "180px",
        },
      },
      {
        title: "Subscription",
        dataIndex: "",
        render: (v, rec) => rec.subscriptionAmount,
      },
      {
        title: "GO Date",
        dataIndex: "",
        render: (v, rec) => rec.goDate ? rec.goDate : "N/A",
      },
      {
        title: "GO No",
        dataIndex: "",
        render: (v, rec) => rec.goNo ? rec.goNo : "N/A",
      },
      {
        title: "Action",
        dataIndex: "action",
        fixed: "right",
        width: 150,
        render: (v, rec) =>
          loader ? (
            <Skeleton text={{ rows: 1, width: 100 }} />
          ) : (
            <div className="d-flex justify-content-start">
              {rec.requestSubmittedStatus ? (
                <div className="badge badge_success_soft font_12 fw_500 py-2">
                  Interest Sent
                </div>
              ) : (
                <button
                  className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 text-nowrap px-1"
                  onClick={() => handleOpenModal(rec)}
                >
                  Express Interest
                </button>
              )}
            </div>
          ),
      },
    ];
  
    if (list.some((rec) => rec.isMultiDivision)) {
      columns.splice(12, 0,  // Insert at the desired position
        {
          title: "No of Lots",
          dataIndex: "",
          render: (v, rec) => rec.isMultiDivision ? rec.noOfLots : "N/A",
        },
        {
          title: "No of Auctions",
          dataIndex: "",
          render: (v, rec) => rec.isMultiDivision ? rec.noOfAuctions : "N/A",
        },
      );
    }
  
    return columns;
  }, [loader, list]);
  

  const handleChangeBranch = (value) => {
    setfilter((prev) => ({ ...prev, branch_id: value }));
  };

  const handleExpressIntrest = async () => {
    //if (form.message) {
    setloader(true);
    const reqstBody = form;
    customLogger("reqstBody--------", reqstBody);
    //debugger
    let response = await ManageApis(
      "post",
      global.expressUserInterest,
      reqstBody,
      token
    )
      .then((res) => {
        customLogger("express interest api resp", res);
        const { status, data, message } = res.response_data;

        if (status) {
          // getNewChitsDetails();
          // setform(initialValue);
          setVisible(false);
          setloader(false);
          setMessage("");
          if (data?.newRequest) {
            successPopup(message);
          } else {
            FailedPopup(message);
          }
        } else {
          FailedPopup(message);
          setloader(false);
          setVisible(true);
        }
      })
      .catch((err) => {
        setloader(false);
        FailedPopup(response);
      });

    // } else {
    //   if (!form.message) {
    //     FailedPopup('Please enter message')
    //     return
    //   }
    // }
  };
  const handleOpenModal = (item) => {
    setVisible(true);
    //setform(prev => ({ ...prev, branch_id: value.branchId, chit_no: value.temporaryChittyNo }))
    setform((prev) => ({
      ...prev,
      branch_id: item.branchId,
      chit_no: item.temporaryChittyNo,
      branch_name: item.branchName,
      pattern: `${item.subscriptionAmount}X${item.noOfInstallments}`,
    }));
  };
  const handleClose = () => {
    setVisible(false);
    setform(initialValue);
  };
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null); // Changed to null for better handling

  const districts = useMemo(() => {
    return districtList.map((itm) => ({
      value: itm.name,
      key: itm.name,
    }));
  }, [districtList]);

  const getDistrictsList = useCallback(async () => {
    try {
      const res = await ManageApis("get", global.listdistricts, "", token);
      if (res.response_data) {
        const { status, data } = res.response_data;
        if (status) {
          setDistrictList(data.docs);
          console.log("Fetched districts:", data.docs);
        }
      }
    } catch (error) {
      // simpleErrorAlert('Failed!', error.message);
    }
  }, [token]);
  // Prevent the first letter from being "0" if it's not followed by a decimal point

  // Replace NaN values with a default value (e.g., empty string or 0)
  function replaceNAN(input) {
    return isNaN(input) ? "" : input;
  }

  useEffect(() => {
    getDistrictsList();
  }, [getDistrictsList]);

  const handleChange = useCallback(
    (value, name) => {
      switch (name) {
        case "sala":
        case "denomination":
          // remove 0 as first letter and remove any special characters
          let newValue = preventFirstLetterZero(replaceNAN(value));
            if(newValue.length<10){
              setSearchform((prev) => ({ ...prev, [name]: newValue }));
          }        
          if (name === "sala") {
            handleErrors(
              name,
              newValue >= 50000 ? "" : "Please enter minimum 50000"
            );
          } else if (name === "denomination") {
            handleErrors(
              name,
              newValue.length > 3 ? "" : "Please enter minimum 1000"
            );
          }
          break;

        case "district":
          if(value === ""){
            setSearchform((prev) => ({
              ...prev,
              [name]: value,
              // branch_id: "",
              // branch_name: "",
              // searchBy:""
            }));
          }else{
            setSearchform((prev) => ({
              ...prev,
              [name]: value,
              // branch_id: "",
              // branch_name: "",
              // searchBy:""
            }));
          }
          //handleErrors(name, value ? "" : "Please select district");
          break;

        default:
          // setSearchform((prev) => ({ ...prev, [name]: value }));
          setSearchform((prev) => ({
            ...prev,
            denomination: "",
            sala: "",
            noOfInstallments:"",
            [name]: value,
          }));
          // handleErrors(
          //   'district',
          //   searchForm.district ? "" : "Please select district"
          // );
          handleErrors('sala',"");
          handleErrors('denomination',"");
          break;
      }
    },
    [searchForm]
  );

  const renderSearchBy = useCallback(() => {
    switch (searchForm.searchBy) {
      case "district":
        return (
          <div className="mb-3">
            <BranchList
              onChange={(value) => handleChange(value, "branch_id")}
              selectedItemString={(value) => handleChange(value, "branch_name")}
              value={filter.branch_id}
              apiParam={`district=${searchForm.district}`}
              className
            />
          </div>
        );
      case "sala":
      case "denomination":
        return (
          <InputField
            label={
              searchForm.searchBy === "sala"
                ? "Sala Amount"
                : "Subscription Amount"
            }
            name={searchForm.searchBy === "sala"?"sala":"denomination"}
            placeholder={`Enter Amount`}
            className={"px-3 w-100"}
            type={"number"}
            onchange={(text) => handleChange(text, searchForm.searchBy)}
            value={searchForm[searchForm.searchBy]}
            maxLength={9}
            errorMessage={Errors.message}
          />
        );
      case "duration":
        return (
          <InputField
            type={"select"}
            required={false}
            label="Duration"
            options={[25, 30, 40, 50, 60, 80, 100, 120].map((value) => ({
              key: value,
              value: value,
            }))}
            placeholder="Choose no. of installments"
            onchange={(value) => handleChange(value, "noOfInstallments")}
            value={searchForm.noOfInstallments}
          />
        );
      default:
        return null;
    }
  }, [searchForm]);

  const searchButtonDisabled = useMemo(() => {
    switch (searchForm.searchBy) {
      case "district":
        return searchForm.branch_id === "" || searchForm.district === "";
        break;

      case "sala":
        return (
          searchForm.sala === "" ||
          searchForm.district === "" ||
          searchForm.sala < 50000
        );
        break;

      case "denomination":
        return (
          searchForm.denomination === "" ||
          searchForm.district === "" ||
          searchForm.denomination.length < 4
        );
        break;

      case "duration":
        return searchForm.noOfInstallments === "" || searchForm.district === "";
        break;

      default:
        return true;
        break;
    }
  }, [searchForm]);

  const handleClear = () => {
    setList([])
    setSearchform(initialFormValue)
  }

  return (
    <Layout loading={loader}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div>
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <div className="col-12 col-sm-5 d-flex pt-2">
                <AlbumsOutline
                  height="23px"
                  width="23px"
                  color={""}
                  className="text_master"
                />
                <h3 className="fw_600 font_18 text_master ms-2 mb-0">
                  New Chits
                </h3>
              </div>
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm">
            <div>
              <div className="row">
                <GridColumn>
                  <InputField
                    type={"select"}
                    required={false}
                    label="District"
                    options={districts}
                    placeholder={"Choose district"}
                    onchange={(value) => handleChange(value, "district")}
                    value={searchForm.district}
                    errorMessage={Errors.district}
                  />
                </GridColumn>
                <GridColumn
                 isSecondCol
                 list={list}
                 handleClear={handleClear}
                >
                  <InputField
                    type={"select"}
                    // required={true}
                    label={"Search By"}
                    options={DropdownList}
                    placeholder={"Choose Search by"}
                    onchange={(value) => handleChange(value, "searchBy")}
                    value={searchForm.searchBy}
                  />
                </GridColumn>

                <GridColumn
                  isLastCol
                  isButtondisabled={searchButtonDisabled || buttonDisable}
                  handleSearch={getNewChitsDetails}
                >
                  {renderSearchBy()}
                </GridColumn>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
        {list.length > 0 ? (
          <div className="arco_table1 table-responsive custom_scroll1">
            <Table
              columns={TableColumn}
              data={list}
              pagination={false}
              onChange={handleChangeTable}
              borderCell
              // rowSelection={{}}
              scroll={{
                x: 2500,
                y: 400,
              }}
            />
          </div>
        ) : (
          // <NoDataScreen message={'Select a branch to list the chit details'} />
          <div className="p-3 bg-white br_10 shadow_sm">
            <div className="d-flex">
              <div className="m-auto">
                <div className="text-center d-grid py-5 text_gray7">
                  <i className="far fa-file-alt font_18"></i>
                  <p className="mb-0 mt-2">No Chits Found</p>
                  {/* <p className="mb-0 mt-1 font_12">please choose the branch to view chits</p> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
      <div className="d-none">
        <button className="btn btn-master-line flex-fill flex-md-grow-0 font_12 fw_500 px-3 px_btn rounded">
          Clear
        </button>
      </div>

   
      {visible && (
        <PopupModal
          title="Express Interest"
          size="md"
          handleClose={handleClose}
          visible={visible}
        >
          <div>
            <div className="mb-3">
              {/* <label className="mb-0 pb_2x text_master fw_500 font_14">
                Message<span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control rounded font_12" rows={6}
                placeholder="Enter your message here.."></textarea> */}
              <InputField
                //required
                //errorMessage={Errors?.message}
                //seterrorMessage={handleErrors}
                label={"Enter your message"}
                placeholder={"Enter Here"}
                type={"textarea"}
                onchange={(text) =>
                  setform((prev) => ({ ...prev, message: text.slice(0, 250) }))
                }
                // onchange={(text) => setform(text)}
                value={form.message}
                rows={6}
              />
            </div>
            <div>
              <button
                className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100"
                onClick={handleExpressIntrest}
              >
                Submit
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </Layout>
  );
}
export default NewChits;
const GridColumn = ({
  children,
  isLastCol,
  isButtondisabled,
  handleSearch,
  isSecondCol,
  handleClear,
  list,
}) => (
  <div className="col-12 col-md-6 col-lg-4 mb-3">
    <div className="d-flex align-items-end w-100">
      <div className="flex-fill">{children}</div>
    </div>
    {isLastCol && children && (
      <div className="mt-3">
        <GeneralButton
          disabled={isButtondisabled}
          onClick={handleSearch}
          title={"Search"}
        />
      </div>
    )}
    {isSecondCol && list.length > 0 && (
      <div className="mt-3">
        <Button
          className="btn px_btn btn-master-line px-4 rounded fw_500 font_12 w-100"
          disabled={isButtondisabled}
          onClick={handleClear}
        >Clear</Button>
      </div>
    )}
  </div>
);
