import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { ManageApis } from "../../common/APIS/ManageApis";
import { FailedPopup } from "../../common/Utils/Utils";
import { ContextAPI } from "../../common/Context/ContextApi";
import { CustomTable } from "../../Components/CustomTable";

function ViewStandingInstructions() {
  const Navigate = useNavigate();
   const { state } = useLocation();
   const { accountId } = state || {};
   const {token}=useContext(ContextAPI)
  const [isLoading, setisLoading] = useState(false);
  const [standingDetails,setStandingDetails]=useState()

  useEffect(()=>{
    getSugamaStandingInstruction()
  },[])


  const handleBackBtn = () => {
    Navigate("/mySugamaAccountDetails", { state: { accountId: accountId } })
  };

  const tableData = [
    { label: "Sl.No", getValue: (item, index) => index + 1 },
    { label: "Start Date", getValue: (item) => item.startDate || "-" },
    { label: "End Date", getValue: (item) => item.endDate || "-" },
    { label: "To Account", getValue: (item) => item.toAccount || "-" },
    { label: "Account Type", getValue: (item) => item.accountType || "-" },
    { label: "Branch Name", getValue: (item) => item.branchName || "-" },
    { label: "Status", getValue: (item) => item.status || "-" },
    { label: "Amount Varying", getValue: (item) => (item.isAmtVarying ? "Yes" : "No") },
    { 
      label: "Amount", 
      getValue: (item) => item.isAmtVarying ? "" : (item.amount || "-") 
    },
  ];
  


  const getSugamaStandingInstruction=async(nextPage)=>{
     try {
      if(!accountId)return;
        setisLoading(true)
        const reqstBody = {
          account_no: accountId
         }
        let response = await ManageApis(
          "post",
          global.getSugamaStandingInst,
          reqstBody,
          token
        );
        if (response?.response_data?.status) {
          const accountList = response?.response_data?.data?.standing_instructions;
          setStandingDetails(
            accountList.map((itm,index) => ({
              ...itm,
              branchName: response?.response_data?.data?.sugama?.branch_name || "",
              slno: index + 1,
            }))
          );
        } else {
          FailedPopup(response);
        }
      } catch (error) {
        FailedPopup(error?.message);
      } finally {
        setisLoading(false)
      }
  }

  return (
    <Layout loading={isLoading}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div className="mb-3">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <BackBtn
              title={"View Standing Instructions"}
              handleBackBtn={handleBackBtn}
            />
          </div>
        </div>
        <div>
        <CustomTable tableData={tableData} data={standingDetails}/>
        </div>
      </div>
    </Layout>
  );
}

export default ViewStandingInstructions;
